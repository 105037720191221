import React from "react";
import FooterComponent from "./footer";

const Footer = (props) => {
  const {signOption, footerSignUpKey} = props;
  return (
    <FooterComponent
      signOption={signOption}
      footerSignUpKey={footerSignUpKey}
    />
  );
};

export default Footer;
