import React from "react";
import { Route } from "react-router-dom";
import AdminDashBoard from "./pages/dashboard/dashboard";
import EmailForm from "./pages/emailForm/emailForm";

const AdminRoutes = () => {
  return (
    <>
      {/*
        IMPORTANT: Always prefix admin routes with "/admin".
        Because We check location.pathname.split("/")[3] to identify admin routes.
      */}
      <Route path="/:lang/admin/dashboard" element={<AdminDashBoard />}></Route>
      <Route path="/:lang/admin/email-form" element={<EmailForm />}></Route>
    </>
  );
};

export default AdminRoutes;
