import "./skeleton.scss";

export const SubscriptionListSkeleton = () => {
    return  <div className="subscriptions_table_skeleton">
    <table>
        <thead>
            <tr className="subscriptions-skeleton-row">
            	<th></th>
                <th>Product</th>
                <th>Frequency</th>
                <th>Next Invoice</th>
                <th>Status</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr class="subscriptions-skeleton-row">
                <td><div className="subscriptions-skeleton skeleton-icon"></div></td>
                <td><div className="subscriptions-skeleton skeleton-text"></div></td>
                <td><div className="subscriptions-skeleton skeleton-text short"></div></td>
                <td><div className="subscriptions-skeleton skeleton-text"></div></td>
                <td><div className="subscriptions-skeleton skeleton-text short"></div></td>
                <td><div className="subscriptions-skeleton skeleton-button"></div></td>
            </tr>
            <tr class="subscriptions-skeleton-row">
                <td><div className="subscriptions-skeleton skeleton-icon"></div></td>
                <td><div className="subscriptions-skeleton skeleton-text"></div></td>
                <td><div className="subscriptions-skeleton skeleton-text short"></div></td>
                <td><div className="subscriptions-skeleton skeleton-text"></div></td>
                <td><div className="subscriptions-skeleton skeleton-text short"></div></td>
                <td><div className="subscriptions-skeleton skeleton-button"></div></td>
            </tr>
        </tbody>
    </table>
</div>;
};

export const LocationListSkeleton = () => {
    return <div className="wpb_report_wrapper white-bg-wrap">
    {/* <div className="dashboard__title">
      <h2>My Reports</h2>
    </div> */}
    <div class="dashboard__skeleton_wrapper">
        <div className="wpb__skeleton__photos"><div className="dashboard__skeleton_img skeleton_animation_squares"></div></div>
        <div className="wpb__skeleton__number"><div className="dashboard__skeleton_number skeleton_animation_squares"></div></div>
        <div class="dashboard__skeleton_date">
            <div className="date__text skeleton_animation_squares"></div>
            <div className="date__content skeleton_animation_squares"></div>
        </div>
        <div class="dashboard__skeleton_location">
            <div className="location__text skeleton_animation_squares"></div>
            <div className="location__content skeleton_animation_squares"></div>
        </div>
        <div className="dashboard__skeleton_button skeleton_animation_squares"></div>
    </div>
    <div className="dashboard__skeleton_wrapper">
        <div className="wpb__skeleton__photos"><div className="dashboard__skeleton_img skeleton_animation_squares"></div></div>
        <div className="wpb__skeleton__number"><div className="dashboard__skeleton_number skeleton_animation_squares"></div></div>
        <div class="dashboard__skeleton_date">
            <div className="date__text skeleton_animation_squares"></div>
            <div className="date__content skeleton_animation_squares"></div>
        </div>
        <div className="dashboard__skeleton_location">
            <div className="location__text skeleton_animation_squares"></div>
            <div className="location__content skeleton_animation_squares"></div>
        </div>
        <div className="dashboard__skeleton_button skeleton_animation_squares"></div>
    </div>
    <div className="dashboard__skeleton_wrapper">
        <div className="wpb__skeleton__photos"><div className="dashboard__skeleton_img skeleton_animation_squares"></div></div>
        <div className="wpb__skeleton__number"><div className="dashboard__skeleton_number skeleton_animation_squares"></div></div>
        <div className="dashboard__skeleton_date">
            <div className="date__text skeleton_animation_squares"></div>
            <div className="date__content skeleton_animation_squares"></div>
        </div>
        <div className="dashboard__skeleton_location">
            <div className="location__text skeleton_animation_squares"></div>
            <div className="location__content skeleton_animation_squares"></div>
        </div>
        <div className="dashboard__skeleton_button skeleton_animation_squares"></div>
    </div>
  </div>;
};
