/* global gtag */
const page_version = window.location.pathname.includes("track") ? "b" : "w";
import { Cookies } from "react-cookie";
import { conversion_id, conversion_label } from "../environment";

export const trackEvent = (eventName, params) => {
  try {
    let eventParams = { ...params };
    const cookies = new Cookies();

    const userData = params?.userData
      ? {
          user_id: params.userData.user_id || "",
          email: params.userData.email || "",
        }
      : {};

    const locationData = {
      postal_code: cookies.get("postalCode") || "",
      country: cookies.get("country_name") || "",
    };

    const transactionData = {
      transaction_id: params?.transaction_id || "",
      value: params?.value || "",
      currency: params?.currency || "",
    };

    // Event-specific parameter adjustments
    switch (eventName) {
      case "track_phone_number":
        eventParams = {
          phone_number: params,
          page_version: page_version,
        };
        break;

      case "sign_up_email":
        eventParams = {
          page_version: page_version,
          ...params,
        };
        break;

      case "zaraz_sign_up_email":
        if (window.zaraz) {
          window.zaraz.track("sign_up_email", params);
        }
        return;

      case "tracking_trial":
        eventParams = {
          ...transactionData,
          ...userData,
          ...locationData,
        };
        break;

      case "zaraz_tracking_trial":
        eventParams = {
          ...transactionData,
          ...userData,
          ...locationData,
        };
        if (window.zaraz) {
          window.zaraz.track("tracking_trial", eventParams);
        }
        return;

      case "tracking_trial_conversion":
        eventParams = {
          ...transactionData,
          ...userData,
          ...locationData,
          send_to: `${conversion_id}/${conversion_label}`,
        };
        break;

      case "report_trial":
      case "single_premium_section":
      case "single_premium_report":
      case "premium_activation":
      case "single_pdf_download_report":
      case "pdf_activation":
        eventParams = {
          ...locationData,
          ...params,
        };
        break;

      case "zaraz_report_trial":
      case "zaraz_single_premium_section":
      case "zaraz_single_premium_report":
      case "zaraz_premium_activation":
      case "zaraz_single_pdf_download_report":
      case "zaraz_pdf_activation":
        eventParams = {
          ...locationData,
          ...params,
        };
        if (window.zaraz) {
          window.zaraz.track(eventName.replace("zaraz_", ""), eventParams);
        }
        return;

      default:
        console.warn(`Unhandled event name: ${eventName}`);
        return;
    }

    // Log event parameters for debugging
    console.log("Tracking Event:", eventName, eventParams);

    // Send the event using gtag
    if (typeof gtag === "function") {
      gtag("event", eventName, eventParams);
    } else {
      console.warn("gtag function is not defined");
    }
  } catch (error) {
    console.error(`Error tracking event "${eventName}":`, error);
  }
};
