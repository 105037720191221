import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../../utils/axios";
import { getUserId } from "../../utils/commonUtils";

export const additionalInfoStatus = createAsyncThunk(
  "additionalInfoStatus",
  async (data) => {
    try {
      // Check if Admin Login with User Then include userId in query string
      const userId = getUserId();
      const url = userId
        ? `/phone_report/addition_info_popup_status?userId=${userId}`
        : `/phone_report/addition_info_popup_status`;
      const response = await AxiosInstance.post(url, data);
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const additionalInfoStatusSlice = createSlice({
  name: "additionalInfoStatus",
  initialState: {
    isLoading: false,
    additionalInfoStatus: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [additionalInfoStatus.pending]: (state) => {
      state.isLoading = true;
    },
    [additionalInfoStatus.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.additionalInfoStatus = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [additionalInfoStatus.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },
  },
});

export default additionalInfoStatusSlice.reducer;
