import { useEffect, useState } from "react";
import { getFinalValue } from "../../../utils/commonUtils";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import { locatePhoneNumberIPQS } from "../../../redux/slice/locatePhoneNumberIPQSlice";

const TrackPage = ({ sawAnimation, formateNumber, setStep }) => {
  const [searchparams] = useSearchParams();
  const disPatch = useDispatch();
  const searchNumber = Object.fromEntries([...searchparams]);
  const ipQsData =
    useSelector((state) => state?.locatePhoneNumberIPQS?.result?.data?.data) ||
    {};
  const [visibleRows, setVisibleRows] = useState([
    false,
    false,
    false,
    false,
    false,
    false
  ]);
  const [loadingComplete, setLoadingComplete] = useState([
    false,
    false,
    false,
    false,
    false,
    false
  ]);
  const [displayText, setDisplayText] = useState(
    t("CONNECTING_CELLULAR_TOWERS")
  );

  useEffect(() => {
    const { D, n } = searchNumber || {};
    if (D && n) {
      const cleanNumber = (number) => number.replace(/\D/g, "");
      const data = {
        phone_number_prefix: cleanNumber(D),
        phone_number: cleanNumber(n)
      };
      disPatch(locatePhoneNumberIPQS(data)).catch((error) =>
        console.error("Invalid phone number:", error)
      );
    }
  }, [searchNumber?.D, searchNumber?.n]);

  const titlesWithIndices = [
    { title: t("TIMEZONE"), index: 0 },
    { title: t("CARRIER"), index: 1 },
    { title: t("COUNTRY"), index: 2 },
    { title: t("CITY"), index: 3 },
    ipQsData?.name !== "N/A" ? { title: t("NAME"), index: 4 } : undefined,
    { title: t("LOCATION"), index: 5 }
  ].filter((item) => item !== undefined);

  useEffect(() => {
    if (sawAnimation) {
      visibleRows.forEach((_, index) => {
        setTimeout(() => {
          setVisibleRows((prevState) => {
            const newState = [...prevState];
            newState[index] = true;
            return newState;
          });
          setTimeout(() => {
            setDisplayText(t("CONNECTING_TO_THE_PHONE"));
          }, 5000);
          if (index === visibleRows.length - 1) {
            setTimeout(() => {
              setLoadingComplete((prevState) => {
                const newState = [...prevState];
                newState[index] = true;
                return newState;
              });

              setTimeout(() => {
                setStep(1);
              }, 2000);
            }, 2000);
          } else {
            setTimeout(() => {
              setLoadingComplete((prevState) => {
                const newState = [...prevState];
                newState[index] = true;
                return newState;
              });
            }, 2000);
          }
        }, index * 2000);
      });
    }
  }, [sawAnimation]);
  return (
    <div className="animation__main_wrapper">
      <div className="animation_wrapper">
        <div className="animation__title">
          <div className="animation__text">
            <p>{displayText}</p>
          </div>
          <div className="locate__number display-inputs-rtl">
            <span>
              <a href="tel:+91 8140-081827">
                {searchNumber.D ? formateNumber : `00 00 000 0000`}
              </a>
            </span>
          </div>
        </div>
        <div className="animated__table-list">
          {titlesWithIndices.map((item, renderIndex) => (
            <div
              key={renderIndex}
              className={`animated__table-row ${visibleRows[renderIndex] ? "visible" : "hidden"
                }`}
            >
              <div className="animated__table-title">{item.title}</div>
              <div
                className={`animated__table-value ${loadingComplete[renderIndex] ? "" : "loading"
                  }`}
              >
                {loadingComplete[renderIndex]
                  ? getFinalValue(item.index, t, ipQsData)
                  : ""}
                {!loadingComplete[renderIndex] && (
                  <svg className="rotating-border" viewBox="0 0 100 100">
                    <circle className="circle" cx="50" cy="50" r="45" />
                  </svg>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TrackPage;
