import ModalComponent from "../../../common/Modal/modal";
import React, { useContext, useEffect, useState } from "react";
import "./phoneReportPayModal.scss";
import PhoneNumberAttributeDetails from "../phoneNumberAttributeDetails/phoneNumberAttributeDetails";
import { useTranslation } from "react-i18next";
import UnlockAnimation from "../../../../lottieAnimations/UnlockLottieAnimation";
import Notify from "../../../../../components/common/Notify/notify";
import { connect } from "react-redux";
import { getTranslatedSectionName, is3Ds } from "../../../../utils/commonUtils";
import { getPhoneReportDetails } from "../../../../redux/slice";
import TransactionDeclinedModal from "../../../../pages/transactionDeclined/transactionDeclined";
import { ProcessModalContext } from "../../../../context/processModalProvider";
import { Cookies } from "react-cookie";
import { additionalInfoStatus } from "../../../../redux/slice/additionalInfoStatusSlice";
import { CONSTANTS } from "../../../../utils/constant";
import { updateMonitoring } from "../../../../redux/slice/updateMonitoringSlice";
import { premiumSubscriptionCreate } from "../../../../redux/slice/solidGate/premiumSubscriptionCreateSlice";
import { unlockPremiumReport } from "../../../../redux/slice/solidGate/unlockPremiumReportSlice";
import { unlockPremiumSection } from "../../../../redux/slice/solidGate/unlockPremiumSectionSlice";
import { useUserIp } from "../../../../../utils/commonUtils";
import { formatNumberByCountry } from "../../../../../utils/commonUtils";

const PhoneReportPayModal = ({
  // Props from component
  isOpen,
  onClose,
  subscriptionData,
  sectionName,
  mode,
  // API and state
  phoneReportDetails,
  callGetPhoneReportDetails,
  callAdditionalInfoStatus,
  callUpdateMonitoring,
  callPremiumSubscriptionCreate,
  callUnlockPremiumReport,
  callUnlockPremiumSection,
  // Dynamic Title
  title,
  // Pricing Data
  pricingData,
  monitoring,
}) => {
  const label1 =
    mode === "section"
      ? "unlock_this_section"
      : mode === "full_report" || mode === "monitoring_report"
      ? "unlock_all_section"
      : "unlock_this_section";
  const label2 =
    mode === "section"
      ? "unlock_all_section"
      : mode === "full_report" || mode === "monitoring_report"
      ? "premium_subscription_create"
      : "unlock_all_section";

  const { phoneReport, subscription, unlocked } = phoneReportDetails || {};
  const isSubscription = subscription?.includes(
    CONSTANTS.SUBSCRIPTIONS.PREMIUM_REPORT
  );
  const { phone_number, phone_number_prefix } = phoneReport || {};
  const mobileNumber = formatNumberByCountry(
    phone_number_prefix + " " + phone_number
  );
  const { t } = useTranslation();
  const cookies = new Cookies();
  const lang = cookies.get("lang");

  const signUpTerms = t("PR_UNLOCK_POPUP_TERM");
  const modifiedLink = signUpTerms.replaceAll(
    '<a href="https://reversly.com/',
    '<a href="https://reversly.com/' + lang + "/"
  );
  const userIp = useUserIp();

  const [retryModal, setRetryModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [termStatus, setTermStatus] = useState(false);
  const [termError, setTermError] = useState("");
  const [amount, setAmount] = useState(isSubscription ? 75 : 1785);
  const [selectedOption, setSelectedOption] = useState(
    isSubscription ? label1 : label2
  );
  const [infoModal, setInfoModal] = useState(false);
  const { openProcessModal } = useContext(ProcessModalContext);

  const [orderId, setOrderId] = useState(null);
  const [orderType, setOrderType] = useState(null);
  const [cardLoadIntent, setCardLoadIntent] = useState(null);

  const isFullReportSelected = selectedOption === "unlock_all_section";
  const isPremiumSelected = selectedOption === "premium_subscription_create";
  const isSingleSectionSelected = selectedOption === "unlock_this_section";

  useEffect(() => {
    if (pricingData && pricingData.label_1) {
      setAmount(
        selectedOption === label1 ? pricingData.label_1 : pricingData.label_2
      );
    }
    // eslint-disable-next-line
  }, [pricingData, isSubscription, isOpen]);

  const handleClose = () => {
    onClose();
    setTermError("");
    setTermStatus(false);
  };
  const handleChange = (event, amount) => {
    const value = event.target.value;
    setAmount(amount);
    setSelectedOption(value);
  };
  const handleChangeTerm = (event) => {
    setTermStatus(event.target.checked);
    setTermError("");
  };

  // #1 function
  const onClickOfPayButton = async () => {
    try {
      if (!termStatus && mode !== "section") {
        setTermError(t("PR_ACCEPT_TERM"));
        return;
      }
      handleClose();
      setIsLoading(true);
      if (
        isFullReportSelected &&
        selectedOption !== "premium_subscription_create" &&
        phoneReport?.status === "unlocked"
      ) {
        Notify("error", t("PR_NOTIFY_ALREADY_OPENED"), "");
        return;
      }
      if (mode === "section" && unlocked.includes(sectionName)) {
        Notify("error", t("PR_NOTIFY_SECTION_UNLOCKED"), "");
        return;
      }
      // START FROM HERE
      const payload = {
        phone_report_id: phoneReport?._id,
        type: "create",
        ip_address: userIp,
        section_name: isSingleSectionSelected ? sectionName : undefined,
        force3ds: is3Ds(), // For check 3ds scenarios
      };
      const apiResponse =
        selectedOption == "premium_subscription_create"
          ? await callPremiumSubscriptionCreate(payload)
          : isFullReportSelected
          ? await callUnlockPremiumReport(payload)
          : await callUnlockPremiumSection(payload);
      if (apiResponse.type.includes("fulfilled")) {
        if (apiResponse.payload.data.required_3ds) {
          const verifyUrl = apiResponse.payload.data.verify_url;
          window.location.href = verifyUrl;
        } else if (apiResponse.payload.status === false) {
          setCardLoadIntent(apiResponse.payload.data.intent);
          handleClose();
          setRetryModal(true);
        } else {
          const finalOrderId = isPremiumSelected
            ? apiResponse?.payload?.data?.subscriptionInfo?.first_order_id
            : isFullReportSelected || isSingleSectionSelected
            ? apiResponse?.payload?.data?.orderInfo?.order_id
            : null;
          setOrderId(finalOrderId);
          setOrderType("confirm");
          const isAnyFieldEmpty = Object.values(
            phoneReport?.additional_details || {}
          ).some((value) => !value?.trim());
          if (isAnyFieldEmpty) {
            let payload = {
              phone_report_id: phoneReport?._id,
              apiPayload: {
                phone_report_id: phoneReport?._id,
                ip_address: userIp,
                type: "confirm",
                order_id: finalOrderId,
              },
              apiName: null,
            };
            if (isPremiumSelected) {
              payload.apiName = "premium_report";
            } else {
              if (selectedOption !== "unlock_all_section" && sectionName) {
                payload.apiPayload.section_name = sectionName;
                payload.apiName = "single_premium_section";
              } else if (isFullReportSelected) {
                payload.apiName = "single_premium_report";
              }
            }
            callAdditionalInfoStatus(payload);
            setInfoModal(true);
          } else {
            await onSubmitSubmitInfoAction(finalOrderId, "confirm");
          }
        }
      } else {
        Notify("error", apiResponse.error.message, "");
      }
    } catch (error) {
      console.error("Error from onClickOfPayButton function => ", error);
    } finally {
      setIsLoading(false);
    }
  };

  // #2 Handle Submit Info Modal
  const onSubmitSubmitInfoAction = async (resOrderId, type) => {
    const finalOrderId = resOrderId || orderId;
    const finalOrderType = type || orderType;
    try {
      const newPayload = {
        phone_report_id: phoneReport?._id,
        ip_address: userIp,
        type: finalOrderType,
        order_id: finalOrderId,
        section_name: isSingleSectionSelected ? sectionName : undefined,
      };
      const apiResponse =
        selectedOption == "premium_subscription_create"
          ? await callPremiumSubscriptionCreate(newPayload)
          : isFullReportSelected
          ? await callUnlockPremiumReport(newPayload)
          : await callUnlockPremiumSection(newPayload);
      if (apiResponse.type.includes("fulfilled")) {
        const payload = {
          phone_report_id: phoneReport?._id,
          is_monitor: true,
        };
        await callUpdateMonitoring(payload);
        if (apiResponse?.payload?.data?.eventObj?.events) {
          openProcessModal(phoneReport._id);
        } else {
          Notify("success", apiResponse.payload.message, "");
          await callGetPhoneReportDetails(phoneReport?._id);
        }
      } else {
        Notify("error", apiResponse.error.message, "");
      }
    } catch (error) {
      console.log("error from onSubmitSubmitInfoAction function => ", error);
    }
  };

  // #3 Handle retry
  const onRetry = async (orderId) => {
    try {
      setIsLoading(true);
      setRetryModal(false);
      const isAnyFieldEmpty = Object.values(
        phoneReport?.additional_details || {}
      ).some((value) => !value?.trim());
      if (isAnyFieldEmpty) {
        let payload = {
          phone_report_id: phoneReport?._id,
          apiPayload: {
            phone_report_id: phoneReport?._id,
            ip_address: userIp,
            type: "transaction_decline",
            order_id: orderId,
          },
          apiName: null,
        };
        if (isPremiumSelected) {
          payload.apiName = "premium_report";
        } else {
          if (selectedOption !== "unlock_all_section" && sectionName) {
            payload.apiPayload.section_name = sectionName;
            payload.apiName = "single_premium_section";
          } else if (isFullReportSelected) {
            payload.apiName = "single_premium_report";
          }
        }
        setOrderId(orderId);
        setOrderType("transaction_decline");
        callAdditionalInfoStatus(payload);
        setInfoModal(true);
      } else {
        await onSubmitSubmitInfoAction(orderId, "transaction_decline");
      }
    } catch (error) {
      console.error("Retry payment error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const orderDetails = {
    orderName: isSingleSectionSelected
      ? getTranslatedSectionName(sectionName)
      : t("PR_PRICING_PREMIUM_REPORTS"),
    orderPrice: pricingData?.symbol + amount,
    orderType: isPremiumSelected ? "subscription" : "one_time_payment",
    orderTypeName: isPremiumSelected
      ? CONSTANTS.SUBSCRIPTIONS.PREMIUM_REPORT
      : isSingleSectionSelected
      ? CONSTANTS.ONETIME.SINGLE_SECTION
      : isFullReportSelected
      ? CONSTANTS.ONETIME.SINGLE_REPORT
      : null,
  };
  return (
    <>
      {isLoading && <UnlockAnimation />}
      {isOpen && (
        <ModalComponent isOpen={isOpen} onClose={handleClose} closable={false}>
          <div className="multiple_popup__wrap">
            <div className="wpb_report_popup fixed-size">
              {subscriptionData?.image ? (
                <img
                  src={subscriptionData.image}
                  alt=""
                  height={100}
                  width={100}
                />
              ) : (
                ""
              )}

              <div className="mlt_popup__text">
                <h2>
                  {subscriptionData
                    ? subscriptionData.title
                    : `${t("PR_UNLOCK_SECTION_LABEL_1", {
                        section_name: title,
                      })}`}
                </h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: subscriptionData
                      ? monitoring
                        ? selectedOption === "unlock_all_section"
                          ? t("PR_MONITOR_UNLOCK_LABEL_1")
                          : t("PR_MONITOR_UNLOCK_LABEL_2")
                        : selectedOption === "unlock_all_section"
                        ? t("PR_UNLOCK_POPUP_DESCRIPTION_LABEL_1", {
                            phone_number: mobileNumber,
                          })
                        : t("PR_UNLOCK_POPUP_DESCRIPTION_LABEL_2", {
                            phone_number: mobileNumber,
                          })
                      : selectedOption === "unlock_this_section"
                      ? t("PR_UNLOCK_SECTION_DESCRIPTION", {
                          phone_number: mobileNumber,
                        })
                      : t("PR_UNLOCK_POPUP_DESCRIPTION_LABEL_1", {
                          phone_number: mobileNumber,
                        }),
                  }}
                ></p>
              </div>
              <div className="mlt_popup__payment">
                <form action="/">
                  <label htmlFor="unlock_report" className="dsl_report">
                    <div className="dsl_report_field">
                      <input
                        type="radio"
                        id="unlock_report"
                        name="report_option"
                        value={label1}
                        checked={selectedOption === label1}
                        onChange={(e) =>
                          handleChange(
                            e,
                            isOpen && pricingData.label_1 && pricingData.label_1
                          )
                        }
                      />
                      <span
                        className={`report_radio_wrap ${
                          monitoring ? "report_radio_wrap_monitoring" : ""
                        }`}
                      >
                        {subscriptionData
                          ? t(subscriptionData.label1)
                          : t("PR_UNLOCK_SECTION_LABEL_1", {
                              section_name: title,
                            })}
                        {monitoring && (
                          <small className="desc__monitor__text">
                            {t("PR_PLUS_UPDATE_ALEART")}
                          </small>
                        )}
                      </span>
                    </div>
                    <div className="dsl_report_payment">
                      <span>
                        {isOpen &&
                          pricingData.label_1 &&
                          pricingData.symbol + pricingData.label_1}
                      </span>
                    </div>
                  </label>
                  {!isSubscription && (
                    <label htmlFor="due_now" className="dsl_report">
                      <div className="dsl_report_field">
                        <input
                          type="radio"
                          id="due_now"
                          name="report_option"
                          value={label2}
                          checked={selectedOption === label2}
                          onChange={(e) =>
                            handleChange(
                              e,
                              isOpen &&
                                pricingData.label_2 &&
                                pricingData.label_2
                            )
                          }
                        />
                        <span className="report_radio_wrap">
                          {subscriptionData
                            ? t(subscriptionData.label2)
                            : t("PR_STEP_3_TITLE_1")}
                        </span>
                      </div>
                      <div className="dsl_report_payment">
                        <span>
                          {isOpen &&
                            pricingData.label_2 &&
                            pricingData.symbol + pricingData.label_2}
                          <span>
                            {subscriptionData && t("PR_UNLOCK_POPUP_TEXT_3")}
                          </span>
                        </span>
                        {subscriptionData && (
                          <small>
                            {10 + " "}
                            {subscriptionData
                              ? t(subscriptionData.reports)
                              : t("PR_UNLOCK_POPUP_REPORTS")}
                          </small>
                        )}
                      </div>
                    </label>
                  )}
                </form>
              </div>
              <div className="mlt_popup__subscribe">
                {mode !== "section" && (
                  <>
                    <div className="policy__content_wrap">
                      <input
                        type="checkbox"
                        id="tracelo_Privacy"
                        name="Privacy Content"
                        onChange={handleChangeTerm}
                      />
                      <label
                        htmlFor="tracelo_Privacy"
                        dangerouslySetInnerHTML={{
                          __html: modifiedLink,
                        }}
                      ></label>
                    </div>
                    <div className="error">
                      <p>{termError}</p>
                    </div>
                  </>
                )}
                <div className="report__popup_pay_btn">
                  <button type="submit" onClick={() => onClickOfPayButton()}>
                    {t("PR_PAY")} {pricingData.label_1 && pricingData.symbol}{" "}
                    {amount}
                  </button>
                  <p>
                    <span onClick={handleClose}>{t("PR_CANCEL")}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </ModalComponent>
      )}
      {/* Transaction retry Modal */}
      {retryModal && (
        <TransactionDeclinedModal
          isOpen={retryModal}
          onClose={() => setRetryModal(false)}
          closable={false}
          handleSubmit={onRetry}
          orderDetails={orderDetails}
          cardLoadIntent={cardLoadIntent}
          setCardLoadIntent={setCardLoadIntent}
        />
      )}
      {infoModal && (
        <PhoneNumberAttributeDetails
          isOpen={infoModal}
          onClose={() => {
            setInfoModal(false);
          }}
          handlePayment={onSubmitSubmitInfoAction}
          setIsLoading={setIsLoading}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    phoneReportDetails:
      state.getPhoneReportDetails?.getPhoneReportDetailsData?.data,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    callGetPhoneReportDetails: (data) => dispatch(getPhoneReportDetails(data)),
    callAdditionalInfoStatus: (data) => dispatch(additionalInfoStatus(data)),
    callUpdateMonitoring: (data) => dispatch(updateMonitoring(data)),
    callPremiumSubscriptionCreate: (data) =>
      dispatch(premiumSubscriptionCreate(data)),
    callUnlockPremiumReport: (data) => dispatch(unlockPremiumReport(data)),
    callUnlockPremiumSection: (data) => dispatch(unlockPremiumSection(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PhoneReportPayModal);
