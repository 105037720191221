import sendLocationSlice from '../slice/sendLocationSlice';
import geoLocationSlice from '../slice/geoLocationSlice';
import validLocationKeySlice from '../slice/validLocationKeySlice';
import checkIsPhoneNmberExistsSlice from '../slice/checkIsPhoneNmberExistsSlice';
import createLocationSlice from '../slice/createLocationSlice';
import getPhoneReportDetailsSlice from '../slice/getPhoneReportDetailsSlice';
import createFirstReportSlice from "../slice/createFirstReportSlice";
import getPricingDetailsBasedOnSectionSlice from "../slice/getPricingDetailsBasedOnSectionSlice";
import getPricingTitleListSlice from "../slice/getPricingTitleListSlice";
import generatePaymentSlice from "../slice/generatePaymentSlice";
import retryPaymentSlice from "../slice/retryPaymentSlice";
import updatePaymentMethodSlice from "../slice/updatePaymentMethodSlice";
import createSubscriptionSlice from "../slice/createSubscriptionSlice";
import createPdfSubscriptionSlice from "../slice/createPdfSubscriptionSlice";
import downloadReportSlice from "../slice/downloadReportSlice";
import subscriptionStatusSlice from "../slice/subscriptionStatusSlice";
import openReportSlice from "../slice/openReportSlice";
import renewSubscriptionSlice from "../slice/renewSubscriptionSlice";
import pdfSubscriptionStatusSlice from "../slice/pdfSubscriptionStatusSlice";
import uploadPdfSlice from "../slice/uploadPdfSlice";
import getPdfUrlSlice from "../slice/getPdfUrlSlice";
import addAdditionalInfoSlice from "../slice/addAdditionalInfoSlice";
import cancelSubscriptionSlice from "../slice/cancelSubscriptionSlice";
import pendingCancelSlice from "../slice/pendingCancelSlice";
import sendPdfEmailSlice from "../slice/sendPdfEmailSlice";
import notificationListSlice from "../slice/notificationListSlice";
import readNotificationListSlice from "../slice/readNotificationSlice";
import updateMonitoringSlice from "../slice/updateMonitoringSlice";
import checkMonitorSocketSlice from "../slice/checkMonitorSocketSlice";
import notificationStatisticsSlice from "../slice/notificationStatisticsSlice";
import additionalInfoStatusSlice from "../slice/additionalInfoStatusSlice";
import readAllNotificationSlice from "../slice/readAllNotificationSlice";
import updateUserLangSlice from "../slice/updateUserLangSlice";
import checkAllSubscriptionCancelSlice from "../slice/checkAllSubscriptionCancelSlice";
import userListSlice from "../slice/admin/getUserListSlice";
import sendEmailSlices from "../slice/admin/sendEmailSlice";
import getAdminConfigSlice from "../slice/admin/getAdminConfig";
import reportSubscriptionCreateSlice from "../slice/solidGate/reportSubscriptionCreateSlice";
import premiumSubscriptionCreateSlice from "../slice/solidGate/premiumSubscriptionCreateSlice";
import unlockPremiumReportSlice from "../slice/solidGate/unlockPremiumReportSlice";
import unlockPremiumSectionSlice from "../slice/solidGate/unlockPremiumSectionSlice";
import pdfSubscriptionCreateSlice from "../slice/solidGate/pdfSubscriptionCreateSlice";
import downLoadSinglePdfSlice from "../slice/solidGate/downLoadSinglePdfSlice";
import subscriptionListSlice from "../slice/solidGate/subscriptionsListSlice";
import solidGateUnsubscribeSlice from "../slice/solidGate/solidGateUnsubscribeSlice";
import createIntentSlice from "../slice/solidGate/createIntentSlice";
import reactivateSubscriptionSlice from "../slice/solidGate/reactivateSubscriptionSlice";
import addMoreReportsSlice from "../slice/solidGate/addMoreReportSlice";

const phoneReportStore = {
  sendLocation: sendLocationSlice,
  geoLocation: geoLocationSlice,
  validLocationKeyData: validLocationKeySlice,
  checkIsPhoneNmberExists: checkIsPhoneNmberExistsSlice,
  createLocation: createLocationSlice,
  getPhoneReportDetails: getPhoneReportDetailsSlice,
  createFirstReport: createFirstReportSlice,
  getPricingDetailsBasedOnSection: getPricingDetailsBasedOnSectionSlice,
  pricingTitleList: getPricingTitleListSlice,
  generatePayment: generatePaymentSlice,
  retryPayment: retryPaymentSlice,
  updatedPaymentMethod: updatePaymentMethodSlice,
  createSubscription: createSubscriptionSlice,
  createPdfSubscription: createPdfSubscriptionSlice,
  downloadReport: downloadReportSlice,
  subscriptionStatus: subscriptionStatusSlice,
  openReport: openReportSlice,
  renewSubscription: renewSubscriptionSlice,
  pdfSubscriptionStatus: pdfSubscriptionStatusSlice,
  uploadPdf: uploadPdfSlice,
  getPdfUrl: getPdfUrlSlice,
  additionalInfo: addAdditionalInfoSlice,
  subscriptionList: subscriptionListSlice,
  cancelSubscription: cancelSubscriptionSlice,
  pendingCancel: pendingCancelSlice,
  sendPdfEmail: sendPdfEmailSlice,
  notificationList: notificationListSlice,
  readNotification: readNotificationListSlice,
  updateMonitoring: updateMonitoringSlice,
  monitorSocket: checkMonitorSocketSlice,
  notificationStatistics: notificationStatisticsSlice,
  additionalInfoStatus: additionalInfoStatusSlice,
  readAllNotification: readAllNotificationSlice,
  updatedUserLang: updateUserLangSlice,
  allSubscriptionCancel: checkAllSubscriptionCancelSlice,
  getUserList: userListSlice,
  sendEmail: sendEmailSlices,
  adminConfig: getAdminConfigSlice,
  reportSubscription: reportSubscriptionCreateSlice,
  premiumSubscription: premiumSubscriptionCreateSlice,
  unlockPremiumReport: unlockPremiumReportSlice,
  unlockPremiumSection: unlockPremiumSectionSlice,
  pdfSubscription: pdfSubscriptionCreateSlice,
  downLoadSinglePdf: downLoadSinglePdfSlice,
  solidGateUnsubscribe: solidGateUnsubscribeSlice,
  createIntent: createIntentSlice,
  reactivateSubscription: reactivateSubscriptionSlice,
  addMoreReports: addMoreReportsSlice
};

export default phoneReportStore;
