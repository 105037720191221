import React, { useEffect, useState } from "react";
import "./stepTutorial.scss";
import {
  informationIcon,
  mapMarkerImage,
  messagesBoxIcon,
  reportTrackPhoneIcon,
  searchIcon,
  stepReverslyLogo,
  tableEllipse,
  toggleBarIcon,
  verifyPaymentIcon,
  stepTutorialGoogleMap,
  lockIcon,
  dashboardIcon,
  tutorialImage1,
  tutorialImage4,
  tutorialImage3,
  tutorialImage2,
} from "../../../../assets/images";
import ModalComponent from "../../../common/Modal/modal";
import { t } from "i18next";

const StepTutorialModal = ({ isOpen, onClose }) => {
  const [currentImage, setCurrentImage] = useState(1);
  const totalStep = 3;

  const handleNext = () => {
    if (currentImage < totalStep) {
      setCurrentImage(currentImage + 1);
    }
  };

  const renderContent = () => {
    switch (currentImage) {
      case 1:
        return (
          <div>
            {/* Content for image 1 */}
            <div className="step1_tutorial_section lp-tutorial-section">
              <div className="tutorial_bg_wrap">
                <div className="tutorial__img">
                  <div className="ln_tutorial_mockup">
                    <div className="ln_tutorial_wrapper">
                      <div className="ln_tutorial_date">
                        <p>{t("MONDAY")}</p>
                      </div>
                      <div className="ln_tutorial_box">
                        <div className="tutorial_message_box">
                          <div className="info_title_wrap">
                            <div className="info_heading_title">
                              <img src={messagesBoxIcon} alt="" />
                              {t("PR_MESSAGES")}
                            </div>
                            <div className="info__text">
                              {t("PR_STEP_1_NOW")}
                            </div>
                          </div>
                          <div className="info_box_wrap">
                            {t("PR_STEP_1_INFO")}
                          </div>
                          <div className="info_link_wrap">
                            {t("PR_STEP_1_DESCRIPTION")}
                            https://reversly.com/en/?i=crGvis6t75G
                          </div>
                        </div>
                      </div>
                      <div className="ln_tutorial_swip">
                        <p>{t("PR_STEP_1_SWIPE_MESSAGE")}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="count__number">
                  <p className="count__listing">
                    {currentImage} {t("PR_STEP_1_OF")} {totalStep}
                  </p>
                </div>
                <div className="tutorial__cmt">
                  <p>{t("PR_STEP_1_TEXT")}</p>
                </div>
                <button className="btn btn-primary" onClick={handleNext}>
                  {t("PR_NEXT")}
                </button>
                <div className="progress__wrapper">
                  <span className="progress__bar__line active"></span>
                  <span
                    className="progress__bar__line"
                    onClick={() => setCurrentImage(2)}
                  ></span>
                  <span
                    className="progress__bar__line"
                    onClick={() => setCurrentImage(3)}
                  ></span>
                </div>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div>
            {/* Content for image 2 */}
            <div className="step2_tutorial_section lp-tutorial-section">
              <div className="tutorial_bg_wrap">
                <div className="tutorial__img">
                  <div className="ln_tutorial_mockup">
                    <div className="ln_tutorial_wrapper">
                      <div className="step2_tutorial_wrap">
                        <div className="step2_tutorial_title">
                          <div className="step2_tutorial_heading">
                            <h2>{t("PR_STEP_2_TITLE")}</h2>
                            <p>{t("PR_STEP_2_DESCRIPTION")}</p>
                          </div>
                          <img src={mapMarkerImage} alt="" />
                          <a className="tutorial_defult_btn" href="#">
                            {" "}
                            {t("PR_SEE_LOCATION")}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="count__number">
                  <p className="count__listing">
                    {currentImage} {t("PR_STEP_1_OF")} {totalStep}
                  </p>
                </div>
                <div className="tutorial__cmt">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t("PR_STEP_2_TEXT"),
                    }}
                  ></p>
                </div>
                <button className="btn btn-primary" onClick={handleNext}>
                  {t("PR_NEXT")}
                </button>
                <div className="progress__wrapper">
                  <span
                    className="progress__bar__line"
                    onClick={() => setCurrentImage(1)}
                  ></span>
                  <span className="progress__bar__line active"></span>
                  <span
                    className="progress__bar__line"
                    onClick={() => setCurrentImage(3)}
                  ></span>
                </div>
              </div>
            </div>
          </div>
        );
      case 3:
        return (
          <div>
            {/* Content for image 3 */}
            <div className="step3_tutorial_section lp-tutorial-section">
              <div className="tutorial_bg_wrap">
                <div className="tutorial__img">
                  <div className="ln_tutorial_mockup">
                    <div className="ln_tutorial_wrapper">
                      <div className="step3_inner_wrap">
                        <div className="step3_tutorial_header">
                          <div className="tutorial_logo_wrapper">
                            <img src={stepReverslyLogo} alt="" />
                          </div>
                          <div className="tutorial_header_trigger">
                            <div className="tutorial_header_icon">
                              <img src={searchIcon} alt="" />
                            </div>
                            <div className="tutorial_header_icon">
                              <img src={toggleBarIcon} alt="" />
                            </div>
                          </div>
                        </div>
                        <div className="tutorial_report_wrapper">
                          <div className="tutorial_report_menu">
                            <p>
                              <img src={tutorialImage1} width={20} height={20} alt="Lock Icon" />{" "}
                              {t("PR_FULL_REPORT_HEADER")}
                            </p>
                            <p>
                              <img src={tutorialImage2} width={20} height={20} alt="PDF Icon" />{" "}
                              {t("PR_PDF")}
                            </p>
                            <p>
                              <img src={tutorialImage3} width={20} height={20} alt="Monitor Icon" />{" "}
                              {t("PR_MONITOR")}
                            </p>
                            {/* <p>
                              <img src={tutorialImage4} width={20} height={20} alt="Search Icon" />{" "}
                              {t("PR_NEW_SEARCH_HEADER")}
                            </p> */}
                          </div>
                          <div className="reversly__boxWrapper white-bg-wrap">
                            <div className="wpl__reportBox_wrapper">
                              <div className="wpl__reportBox__image">
                                <img src={reportTrackPhoneIcon} alt="" />
                              </div>
                              <div className="wpl__reportBox__content">
                                <div className="wpl__reportBox__title">
                                  <h2>{t("PR_PHONE_REPORT")}</h2>
                                  <div className="wpl__reportBox__status">
                                    <img src={verifyPaymentIcon} alt="" />
                                    <span> {t("PR_STEP_3_MOBILE")}</span>
                                  </div>
                                </div>
                                <div className="wpl__reportBox__numberTrack">
                                  <p>
                                    <a>+1 (819) 674-0000</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div className="tutorial_report_header">
                            <div className="tutorial_report_img">
                              <img src={phoneReportVarifiedIcon} alt="" />
                              <div className="tutorial_report_word">
                                {t("PR_STEP_3_TITLE_2")}
                              </div>
                            </div>
                            <div className="tutorial_report_info">
                              <ul>
                                <li>
                                  <a href="">+1 (819) 674-0000</a>
                                </li>
                                <li>
                                  <img src={listingEllipse} alt="" />
                                  <b>{t("PR_STEP_3_PHONE")}</b>{" "}
                                  {t("PR_STEP_3_MOBILE")}
                                </li>
                              </ul>
                            </div>
                          </div> */}

                          <div className="tutorial_location_info">
                            <div className="tutorial_gps_title">
                              <h2>
                                {t("PR_STEP_3_TITLE_3")}{" "}
                                <img src={informationIcon} alt="" />
                              </h2>
                            </div>
                            <div className="tutorial_table_info">
                              <table>
                                <thead>
                                  <tr>
                                    <th className="border-right">
                                      {t("PR_STEP_3_DATE")}
                                    </th>
                                    <th>{t("PR_STEP_3_STATUS")}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td className="border-right">23/08/2023</td>
                                    <td>
                                      <span>
                                        {t("PR_STEP_3_PENDING")}...{" "}
                                        <img
                                          src={tableEllipse}
                                          alt="Table Ellipse"
                                        />
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="border-right">23/08/2023</td>
                                    <td>
                                      <span>
                                        {t("PR_STEP_3_PENDING")}...{" "}
                                        <img
                                          src={tableEllipse}
                                          alt="Table Ellipse"
                                        />
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td className="border-right">23/08/2023</td>
                                    <td>
                                      <span>
                                        {t("PR_STEP_3_PENDING")}...{" "}
                                        <img
                                          src={tableEllipse}
                                          alt="Table Ellipse"
                                        />
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div className="tutorial_map_info">
                              <img src={stepTutorialGoogleMap} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="count__number">
                  <p className="count__listing">
                    {currentImage} {t("PR_STEP_1_OF")} {totalStep}
                  </p>
                </div>
                <div className="tutorial__cmt">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t("PR_STEP_3_TEXT"),
                    }}
                  ></p>
                </div>
                <button className="btn btn-primary" onClick={onClose}>
                  {t("PR_START_NOW")}
                </button>
                <div className="progress__wrapper">
                  <span
                    className="progress__bar__line"
                    onClick={() => setCurrentImage(1)}
                  ></span>
                  <span
                    className="progress__bar__line"
                    onClick={() => setCurrentImage(2)}
                  ></span>
                  <span className="progress__bar__line active"></span>
                </div>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };
  useEffect(() => {
    if (isOpen) {
      window.history.pushState(null, null, window.location.href);
      window.onpopstate = function () {
        window.history.go(1);
      };
    }
    // eslint-disable-next-line
  }, [window.history, isOpen]);
  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={onClose}
      closable={false}
      className="setp_tutorial_modal"
      maskClosable={false}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 text-center">{renderContent()}</div>
        </div>
      </div>
    </ModalComponent>
  );
};

export default StepTutorialModal;
