import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import AxiosInstance from "../../../../utils/axios";
export const sendEmail = createAsyncThunk("sendEmail", async (data) => {
  try {
    const response = await AxiosInstance.post(`/admin/send_test_email`, data);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw error;
    }
  }
});

const sendEmailSlice = createSlice({
  name: "sendEmail",
  initialState: {
    isLoading: false,
    sendEmail: null,
    isError: false,
    errorMessage: ""
  },
  extraReducers: {
    [sendEmail.pending]: (state) => {
      state.isLoading = true;
    },
    [sendEmail.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.sendEmail = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [sendEmail.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    }
  }
});

export default sendEmailSlice.reducer;
