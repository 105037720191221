import React, { useEffect } from "react";
import { Pagination } from "antd";
import "./pagiNation.scss"

const CustomPagination = ({
  current,
  pageSize,
  total,
  onPageChange,
  onPageSizeChange,
  pageSizeOptions = ["10", "20", "50", "100"],
  showLessItems = true,
  hideOnSinglePage = false,
  style = { display: "flex", justifyContent: "center"},
}) => {
  useEffect(() => {
    // Disable typing when open Page Size DropDown
    const sizeChangerInput = document.querySelector(
      '.custom-pagination .ant-pagination-options-size-changer input'
    );

    if (sizeChangerInput) {
      sizeChangerInput.addEventListener('keydown', (e) => {
        e.preventDefault();
      });
    }

    return () => {
      if (sizeChangerInput) {
        sizeChangerInput.removeEventListener('keydown', (e) => e.preventDefault());
      }
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [current, pageSize])
  
  return (
    <Pagination
      className="custom-pagination"
      showLessItems={showLessItems}
      hideOnSinglePage={hideOnSinglePage}
      current={current}
      pageSize={pageSize}
      total={total}
      onChange={onPageChange}
      showSizeChanger
      onShowSizeChange={onPageSizeChange}
      pageSizeOptions={pageSizeOptions}
      style={style}
    />
  );
};

export default CustomPagination;
