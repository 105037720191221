import React, { useState } from "react";
import "./pricing.scss";
import { useTranslation } from "react-i18next";
import pricingPremiumTockenIcon from "../../components/home/pricingPremiumTockenIcon.svg";
import pdfIcon from "../../components/home/pdfIcon.svg";
import downloadIcon from "../../components/home/downloadIcon.svg";
import reportIcon from "../../components/home/reportIcon.svg";
import boxIcon from "../../components/home/boxIcon.svg";
import { Collapse, Skeleton } from "antd";
import useScrollToTop from "../customHook/useScrollToTop";
import {
  accordionCollapseIcon,
  accordionExpandIcon,
} from "../../phoneReport/assets/images";
import { useProductPrices } from "../signUpSolidGate/module/helpers";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";

const PricingComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const cookie = new Cookies();

  const lang = cookie.get("lang");
  const [activeKey, setActiveKey] = useState(null);

  const {
    trialProduct,
    subscriptionProduct,
    phoneReportProduct,
    premiumReportProduct,
    singlePdfProduct,
    singlePremiumReportProduct,
    singlePremiumSectionProduct,
    pdfDownloadProduct,
    currencySymbol,
    isLoading,
  } = useProductPrices();

  useScrollToTop();

  const handleCollapseChange = (key) => {
    setActiveKey((prevKey) => (prevKey === key ? null : key));
  };

  const handleNavigateInput = () => {
    navigate(`/${lang}/`, {
      state: { inputFocus: true },
    });
  };

  return (
    <div className="pricing-main-section pricing_page__wrapper">
      <div className="back-grad-title">
        <div className="pricing-main-title">{t("PR_PRICING_EXPLORE")}</div>
      </div>

      <div className="pricing-table-wrapper">
        <div className="container">
          {/* <div className="pricing-table-title">
            <h2>{t("PR_PRICING_EXPLORE")}</h2>
          </div> */}
          <div className="pricing-table-row">
            <div className="pricing-table-col">
              <div className="pricing-inner-box">
                <p>
                  {t("PR_PRICING_TRIAL_AVAILABLE")}{" "}
                  {isLoading ? (
                    <Skeleton.Input active={true} size={"small"} />
                  ) : (
                    currencySymbol + trialProduct?.amount
                  )}
                </p>
                <div className="pricing-table-box">
                  <h3>{t("PR_REPORT_PHONE_TRACKING")}</h3>
                  <p>{t("PR_SUBSCRIPTION_TOOLTIP_PHONE_TRACKING")}</p>
                  <div className="pricing-value">
                    {isLoading ? (
                      <Skeleton.Input active={true} size="large" />
                    ) : (
                      <>
                        {subscriptionProduct?.amount}{" "}
                        <sub>
                          {subscriptionProduct?.parameter
                            ?.toUpperCase()
                            .slice(0, 3)}{" "}
                          <span>{t("PR_FOR_MONTH")}</span>
                        </sub>
                      </>
                    )}
                  </div>
                  <button className="pricind-btn" onClick={handleNavigateInput}>
                    {t("PR_START_TRIAL_NOW")}
                  </button>
                </div>
              </div>
            </div>
            <div className="pricing-table-col">
              <div className="pricing-inner-box">
                <p>{t("PR_FREE_TRAL_AVAILABLE")}</p>
                <div className="pricing-table-box">
                  <h3>{t("PR_PHONE_REPORT")}</h3>
                  <p>{t("PR_SUBSCRIPTION_TOOLTIP_PHONE_REPORT_1")}</p>
                  <div className="pricing-value">
                    {isLoading ? (
                      <Skeleton.Input active={true} size="large" />
                    ) : (
                      <>
                        {phoneReportProduct?.amount}{" "}
                        <sub>
                          {phoneReportProduct?.parameter
                            .toUpperCase()
                            .slice(0, 3)}{" "}
                          <span>{t("PR_FOR_MONTH")}</span>
                        </sub>
                      </>
                    )}
                  </div>
                  <button className="pricind-btn" onClick={handleNavigateInput}>
                    {t("PR_PRICING_GET_STARTED")}
                  </button>
                </div>
              </div>
            </div>
            <div className="pricing-table-col">
              <div className="pricing-inner-box">
                <p>{t("PR_PRICING_RECOMMENDED")}</p>
                <div className="pricing-table-box">
                  <h3>
                    <img
                      src={pricingPremiumTockenIcon}
                      alt="pricingPremiumTockenIcon"
                    />
                    {t("PR_PRICING_PREMIUM_REPORTS")}
                  </h3>
                  <p>{t("PR_PRICING_MOTHLY_ACCESS_REPORTS")}</p>
                  <div className="pricing-value">
                    {isLoading ? (
                      <Skeleton.Input active={true} size="large" />
                    ) : (
                      <>
                        {premiumReportProduct?.amount}{" "}
                        <sub>
                          {premiumReportProduct?.parameter
                            .toUpperCase()
                            .slice(0, 3)}{" "}
                          <span>{t("PR_FOR_MONTH")}</span>
                        </sub>
                      </>
                    )}
                  </div>
                  <button className="pricind-btn" onClick={handleNavigateInput}>
                    {t("PR_PRICING_GET_STARTED")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="additional-wrapper">
        <div className="container">
          <div className="additional-wrapper-title">
            <h2>{t("PR_PRICING_ADDITIONAL_ADD_ONS")}</h2>
          </div>
          <div className="additional-row">
            <div className="additional-col">
              <div className="additional-box">
                <div className="additional-icon">
                  <img src={pdfIcon} alt="pdfIcon" />
                </div>
                <h3>{t("PR_PDF_DOWNLOAD")}</h3>
                <p>{t("PR_SUBSCRIPTION_TOOLTIP_PDF")}</p>
                <button>
                  {isLoading ? (
                    <Skeleton.Input active={true} size={"small"} />
                  ) : (
                    <>
                      {currencySymbol + singlePdfProduct?.amount}{" "}
                      {t("PR_PRICING_FOR_REPORTS")}
                    </>
                  )}
                </button>
              </div>
            </div>
            <div className="additional-col">
              <div className="additional-box">
                <div className="additional-icon">
                  <img src={downloadIcon} alt="downloadIcon" />
                </div>
                <h3>{t("PR_PRICING_UNLIMITED_PDF_DOWNLOAD")}</h3>
                <p>{t("PR_PRICING_PDF_DOWNLOAD_DESC")}</p>
                <button>
                  {isLoading ? (
                    <Skeleton.Input active={true} size={"small"} />
                  ) : (
                    <>
                      {currencySymbol + pdfDownloadProduct?.amount}{" "}
                      {t("PR_FOR_MONTH")}
                    </>
                  )}
                </button>
              </div>
            </div>
            <div className="additional-col">
              <div className="additional-box">
                <div className="additional-icon">
                  <img src={reportIcon} alt="reportIcon" />
                </div>
                <h3>{t("PR_PRICING_PREMIUM_REPORT")}</h3>
                <p>{t("PR_PRICING_PDF_PREMIUM_REPORT_DESC")} </p>
                <button>
                  {isLoading ? (
                    <Skeleton.Input active={true} size={"small"} />
                  ) : (
                    <>
                      {currencySymbol + singlePremiumReportProduct?.amount}{" "}
                      {t("PR_PRICING_FOR_REPORTS")}
                    </>
                  )}
                </button>
              </div>
            </div>
            <div className="additional-col">
              <div className="additional-box">
                <div className="additional-icon">
                  <img src={boxIcon} alt="boxIcon" />
                </div>
                <h3>{t("PR_PRICING_PREMIUM_SECTION")}</h3>
                <p>{t("PR_PRICING_PDF_PREMIUM_SECTION_DESC")}</p>
                <button>
                  {isLoading ? (
                    <Skeleton.Input active={true} size={"small"} />
                  ) : (
                    <>
                      {currencySymbol + singlePremiumSectionProduct?.amount}{" "}
                      {t("PR_PRICING_FOR_REPORTS")}
                    </>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pricing__faws_wrapper">
        <div class="container">
          <div class="pricing-wrapper-title">
            <h2>Frequently Asked Questions</h2>
          </div>
          {/* FAQS */}
          <div className="faqs__accordion_wrapper">
            <div className="inner__collapse_accordion">
              <div class="wpb__accordion_warp">
                <div
                  className={`faqs__collapse_accordion ${
                    activeKey === "15" ? "faqs__accordion_border" : ""
                  }`}
                >
                  <Collapse
                    activeKey={activeKey === "15" ? ["15"] : []}
                    onChange={() => handleCollapseChange("15")}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => (
                      <img
                        src={
                          isActive ? accordionExpandIcon : accordionCollapseIcon
                        }
                        width="12px"
                        height="12px"
                        alt=""
                        // onClick={() => handleCollapseChange("15")}
                      />
                    )}
                  >
                    <Collapse.Panel
                      header={t("PR_FAQ_ACCOUNT_TITLE_1")}
                      key="15"
                    >
                      <p>{t("PR_FAQ_ACCOUNT_DESCRIPTION_1_1")}</p>
                      <div>
                        <ul className="faqs__list_content">
                          <li>{t("PR_FAQ_ACCOUNT_DESCRIPTION_1_2")}</li>
                          <li>{t("PR_FAQ_ACCOUNT_DESCRIPTION_1_3")}</li>
                          <li>{t("PR_FAQ_ACCOUNT_DESCRIPTION_1_4")}</li>
                        </ul>
                      </div>
                      <p>{t("PR_FAQ_ACCOUNT_DESCRIPTION_1_5")}</p>
                    </Collapse.Panel>
                  </Collapse>
                </div>
                <div
                  className={`faqs__collapse_accordion ${
                    activeKey === "6" ? "faqs__accordion_border" : ""
                  }`}
                >
                  <Collapse
                    activeKey={activeKey === "6" ? ["6"] : []}
                    onChange={() => handleCollapseChange("6")}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => (
                      <img
                        src={
                          isActive ? accordionExpandIcon : accordionCollapseIcon
                        }
                        width="12px"
                        height="12px"
                        alt=""
                        // onClick={() => handleCollapseChange("6")}
                      />
                    )}
                  >
                    <Collapse.Panel header={t("PR_FAQ_ABOUT_TITLE_6")} key="6">
                      <p>{t("PR_FAQ_ABOUT_DESCRIPTION_6")}</p>
                    </Collapse.Panel>
                  </Collapse>
                </div>
                <div
                  className={`faqs__collapse_accordion ${
                    activeKey === "13" ? "faqs__accordion_border" : ""
                  }`}
                >
                  <Collapse
                    activeKey={activeKey === "13" ? ["13"] : []}
                    onChange={() => handleCollapseChange("13")}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => (
                      <img
                        src={
                          isActive ? accordionExpandIcon : accordionCollapseIcon
                        }
                        width="12px"
                        height="12px"
                        alt=""
                        // onClick={() => handleCollapseChange("13")}
                      />
                    )}
                  >
                    <Collapse.Panel
                      header={t("PR_FAQ_SUBSCRIPTION_TITLE_2")}
                      key="13"
                    >
                      <p>{t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_2_1")}</p>
                      <p>{t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_2_2")}</p>
                      <div>
                        <ul className="faqs__list_content dot">
                          <li>{t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_2_3")}</li>
                          <li>{t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_2_3")}</li>
                          <li>{t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_2_3")}</li>
                          <li>{t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_2_3")}</li>
                        </ul>
                      </div>
                    </Collapse.Panel>
                  </Collapse>
                </div>
                <div
                  className={`faqs__collapse_accordion ${
                    activeKey === "14" ? "faqs__accordion_border" : ""
                  }`}
                >
                  <Collapse
                    activeKey={activeKey === "14" ? ["14"] : []}
                    onChange={() => handleCollapseChange("14")}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => (
                      <img
                        src={
                          isActive ? accordionExpandIcon : accordionCollapseIcon
                        }
                        width="12px"
                        height="12px"
                        alt=""
                        // onClick={() => handleCollapseChange("14")}
                      />
                    )}
                  >
                    <Collapse.Panel
                      header={t("PR_FAQ_SUBSCRIPTION_TITLE_3")}
                      key="14"
                    >
                      <p>{t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_3_1")}</p>
                      <p>
                        {t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_3_2")}{" "}
                        <a href="https://www.reversly.com/en">Reversly.com.</a>
                      </p>
                    </Collapse.Panel>
                  </Collapse>
                </div>
                <div
                  className={`faqs__collapse_accordion ${
                    activeKey === "16" ? "faqs__accordion_border" : ""
                  }`}
                >
                  <Collapse
                    activeKey={activeKey === "16" ? ["16"] : []}
                    onChange={() => handleCollapseChange("16")}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => (
                      <img
                        src={
                          isActive ? accordionExpandIcon : accordionCollapseIcon
                        }
                        width="12px"
                        height="12px"
                        alt=""
                        // onClick={() => handleCollapseChange("16")}
                      />
                    )}
                  >
                    <Collapse.Panel
                      header={t("PR_FAQ_SUBSCRIPTION_TITLE_5")}
                      key="16"
                    >
                      <p>{t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_5")}</p>
                    </Collapse.Panel>
                  </Collapse>
                </div>
                <div
                  className={`faqs__collapse_accordion ${
                    activeKey === "17" ? "faqs__accordion_border" : ""
                  }`}
                >
                  <Collapse
                    activeKey={activeKey === "17" ? ["17"] : []}
                    onChange={() => handleCollapseChange("17")}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => (
                      <img
                        src={
                          isActive ? accordionExpandIcon : accordionCollapseIcon
                        }
                        width="12px"
                        height="12px"
                        alt=""
                        // onClick={() => handleCollapseChange("17")}
                      />
                    )}
                  >
                    <Collapse.Panel
                      header={t("PR_FAQ_SUBSCRIPTION_TITLE_6")}
                      key="17"
                    >
                      <p>{t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_6_1")}</p>
                      <p>{t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_6_2")}</p>
                      <div>
                        <ul className="faqs__list_content dot">
                          <li>{t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_6_3")}</li>
                        </ul>
                      </div>
                      <p>
                        <b>{t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_6_4")}</b>
                      </p>
                      <p>{t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_6_5")}</p>

                      <p>
                        <b>{t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_6_6")}</b>
                      </p>
                      <p>{t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_6_7")}</p>
                      <p>{t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_6_8")}</p>
                      <p>{t("PR_FAQ_SUBSCRIPTION_DESCRIPTION_6_9")}</p>
                    </Collapse.Panel>
                  </Collapse>
                </div>
                <div
                  className={`faqs__collapse_accordion ${
                    activeKey === "18" ? "faqs__accordion_border" : ""
                  }`}
                >
                  <Collapse
                    activeKey={activeKey === "18" ? ["18"] : []}
                    onChange={() => handleCollapseChange("18")}
                    expandIconPosition="end"
                    expandIcon={({ isActive }) => (
                      <img
                        src={
                          isActive ? accordionExpandIcon : accordionCollapseIcon
                        }
                        width="12px"
                        height="12px"
                        alt=""
                        // onClick={() => handleCollapseChange("18")}
                      />
                    )}
                  >
                    <Collapse.Panel
                      header={t("PR_PRICING_FAQ_TITLE_1")}
                      key="18"
                    >
                      <p>{t("PR_PRICING_FAQ_DESCRIPTION_1")}</p>
                    </Collapse.Panel>
                  </Collapse>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingComponent;
