import React from "react";
import { Modal } from "antd";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next";
import { Cookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { languages, renderRTLClass } from "../../../../../utils/commonUtils";
import "./languageChangeModal.scss";
import { updateUserLang } from "../../../../redux/slice/updateUserLangSlice";
import { connect } from "react-redux";

const LanguageChangeModal = ({
  isModalOpen,
  setIsModalOpen,
  currentLang,
  setLang,
  closeLanguage,
  callUpdateUserLanguage,
}) => {
  const { t, i18n } = useTranslation();
  const cookies = new Cookies();
  const navigate = useNavigate();

  const handleChangeLanguage = async (lang, logo) => {
    i18n.changeLanguage(lang);
    const currentPath = window.location.pathname;
    const searchParams = window.location.search;
    await callUpdateUserLanguage({
      language: lang,
    });
    if (currentPath.split("/")[2]) {
      navigate(`/${lang}/${currentPath.split("/")[2]}${searchParams}`);
    } else {
      navigate(`/${lang}${searchParams}`);
    }
    setLang(lang);
    setIsModalOpen(false);
    closeLanguage();
    cookies.set("lang", lang, {
      path: "/",
      sameSite: true,
    });
    cookies.set("langlogo", logo, {
      path: "/",
      sameSite: true,
    });
  };

  return (
    <Modal
      centered
      open={isModalOpen}
      onCancel={closeLanguage}
      okButtonProps={{ style: { display: "none" } }}
      cancelButtonProps={{ style: { display: "none" } }}
      className={`${renderRTLClass()} footer_langpopup__wrapper`}
    >
      <div className="modal-container">
        <div className="modal-title">
          <p>{t("LANGUAGE")}</p>
        </div>
        <div className="modal-languages">
          <div className="df-modal-language">
            {languages.map((item, index) => (
              <div
                className={`lang ${currentLang === item.code && "active"}`}
                onClick={() => handleChangeLanguage(item.code, item.flagCode)}
                key={index}
              >
                <div className="flag">
                  <ReactCountryFlag
                    countryCode={item.flagCode}
                    svg
                    style={{
                      width: "22px",
                      height: "22px",
                    }}
                  />
                </div>
                <span>{item.name}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    callUpdateUserLanguage: (data) => dispatch(updateUserLang(data)),
  };
};

export default connect(null, mapDispatchToProps)(LanguageChangeModal);
