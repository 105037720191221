import React, { useState } from "react";
import { t } from "i18next";
import TransactionDeclinedModal from "../../transactionDeclined/transactionDeclined";
import ModalComponent from "../../../components/common/Modal/modal";
import {
  formatToLocalTime,
  getSubscriptionStatus,
  getSubscriptionText,
  getTooltipContent,
} from "../helper";
import { myAccountSubscriptionsIcon } from "../../../assets/images";
import { Tooltip } from "antd";
import ReactivateModal from "../modal/reactivateModal";
import { checkAdminLogin, getSubscriptionName } from "../../../utils/commonUtils";
import AddMoreReport from "../../../components/partials/Modals/multiActionModal/addMoreReport";
import { useSelector } from "react-redux";
import { CONSTANTS } from "../../../utils/constant";
import { SubscriptionListSkeleton } from "../../../components/partials/skeleton/skeleton";

const SubscriptionListPage = ({
  subscriptionList,
  handleCancelSubscription,
  handlePendingCancel,
  handleSubscription,
  onRetry,
  retryModal,
  handleRetryModalClose,
  isLoading,
  orderDetails,
  subscriptionLoading,
  cardLoadIntent,
  setCardLoadIntent
}) => {
  const {
    amount: price = null,
    symbol = "$",
  } = useSelector(
    (state) =>
      state.pricingTitleList?.priceTitleList?.data?.subscriptions?.[
        CONSTANTS.SUBSCRIPTIONS.PREMIUM_REPORT
      ] || {}
  );
  const [confirmModal, setConfirmModal] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState({});
  const [reactiveModal, setReactiveModal] = useState(false);
  const [addMoreReportModal, setAddMoreReportModal] = useState(false);

  const subscriptionMap = {
    phone_report: t("PR_PHONE_REPORT"),
    premium_report: t("PR_PRICING_PREMIUM_REPORTS"),
    pdf_download: t("PR_PDF_DOWNLOAD"),
    phone_tracking: t("PR_REPORT_PHONE_TRACKING"),
  };

  return (
    <>
      <div
        className="wpb_content_subs account_bg_wrap"
        id="subscription_list_account"
      >
        <div className="broder__area account_title">
          <h2>
            {subscriptionList?.length > 1
              ? t("PR_SUBSCRIPTIONS")
              : t("PRICING_SUBSCRIPTION_TAG")}
          </h2>
        </div>
        {subscriptionLoading ? (
          <SubscriptionListSkeleton />
        ) : (
          <div className="subscriptions_table">
            {subscriptionList?.length > 0 ? (
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>{t("PR_PRODUCT")}</th>
                    <th>{t("PR_FREQUENCY")}</th>
                    <th>{t("PR_NEXT_INVOICE")}</th>
                    <th>{t("STATUS")}</th>
                    <th>{t("PR_USES")}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {subscriptionList?.map((subscription, index) => {
                    const isReportLimitOver =
                      subscription.name === "Premium Reports" &&
                      subscription.remaining_report === 0;
                    const className =
                      [1, 3, 5].includes(subscriptionList?.length) &&
                      index === subscriptionList?.length - 1
                        ? "fullwidth__subscription_werapper"
                        : "";
                    return (
                      <tr key={index} className={className}>
                        <td>
                          <Tooltip
                            placement="top"
                            title={getTooltipContent(subscription.name)}
                            overlayStyle={{ borderRadius: 2 }}
                            color="#6C6C6C"
                          >
                            <img
                              src={myAccountSubscriptionsIcon}
                              width={20}
                              height={20}
                              alt="My Account Subscriptions Icon"
                            />
                          </Tooltip>
                        </td>
                        <td
                          className="subscriptions__title_wrap"
                          data-title={t("PR_PRODUCT")}
                        >
                          <span>
                            <Tooltip
                              placement="top"
                              title={getTooltipContent(subscription.name)}
                              overlayStyle={{ borderRadius: 2 }}
                              color="#6C6C6C"
                            >
                              <img
                                src={myAccountSubscriptionsIcon}
                                width={20}
                                height={20}
                                alt="My Account Subscriptions Icon"
                                className="mobile_tooltip_about_subscription"
                              />
                            </Tooltip>
                            {subscription.name}

                            <span>
                              {subscription?.remaining_report ||
                              subscription?.total_report
                                ? `${t("PR_USES")}:  ${
                                    subscription?.total_report -
                                    subscription?.remaining_report
                                  } / ${subscription?.total_report}  ${t(
                                    "REPORTS"
                                  )}`
                                : ""}
                            </span>
                          </span>

                          {subscription.status !== "cancelled" && (
                            <button
                              className={`tracking_btn ${
                                isReportLimitOver && "more_report_btn"
                              }`}
                            >
                              {isReportLimitOver
                                ? t("PR_BUT_MORE_REPORTS")
                                : subscription.status === "trialing"
                                ? `${t("PR_TRIAL_ENDS")} ${
                                  formatToLocalTime(subscription.trial_end_date)
                                  }`
                                  : `${t("PR_ENDS")} ${formatToLocalTime(subscription.end_date)}`}
                            </button>
                          )}
                        </td>
                        <td data-title={t("PR_FREQUENCY")}>
                          {subscription.is_cancelled || subscription.is_expired
                            ? "N/A"
                            : t("PR_BILLING_MONTHLY")}
                        </td>
                        <td data-title={t("PR_NEXT_INVOICE")}>
                          {subscription.is_cancelled || subscription.is_expired
                            ? "N/A"
                            : `${formatToLocalTime(subscription.next_invoice)} for ${subscription.symbol} ${subscription.amount}`}
                        </td>
                        <td data-title={t("STATUS")}>
                          <span
                            className={
                              getSubscriptionStatus(subscription).statusClass
                            }
                          >
                            {getSubscriptionStatus(subscription).statusText}
                          </span>
                        </td>

                        <td data-title={t("PR_USES")}>
                          {subscription?.remaining_report ||
                            (subscription?.total_report &&
                              `${
                                subscription?.total_report -
                                subscription?.remaining_report
                              } / ${subscription?.total_report}  ${t(
                                "REPORTS"
                              )}`)}

                          {subscription.status !== "cancelled" &&
                            (subscription?.remaining_report ||
                              (subscription?.total_report && (
                                <button
                                  className={`tracking_btn ${
                                    isReportLimitOver && "more_report_btn"
                                  }`}
                                >
                                  {isReportLimitOver &&
                                    t("PR_BUT_MORE_REPORTS")}
                                </button>
                              )))}
                        </td>

                        <td className="cancel_subscription">
                          {getSubscriptionText(subscription) !== null && (
                            <button
                              className="cancel_btn cancel_grey_btn"
                              onClick={() => {
                                if (subscription.status === "cancelled") {
                                  setReactiveModal(true);
                                } else if (isReportLimitOver) {
                                  setAddMoreReportModal(true);
                                } else if (
                                  (subscription.status === "active" ||
                                    subscription.status === "trialing") &&
                                  !subscription.sub_status
                                ) {
                                  setConfirmModal(true);
                                } else if (
                                  (subscription.status === "active" &&
                                    subscription.sub_status ===
                                      "pending_unsubscribe") ||
                                  (subscription.status === "trialing" &&
                                    subscription.sub_status ===
                                      "pending_unsubscribe")
                                ) {
                                  handlePendingCancel(subscription?.payment_gateway_id);
                                }
                                setSubscriptionData(subscription);
                              }}
                              disabled={isLoading || checkAdminLogin()}
                            >
                              {getSubscriptionText(subscription)}
                            </button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <p className="text-center">{t("PR_RECORD_NOT_FOUND")}</p>
            )}
          </div>
        )}
      </div>

      {/* Confirmation Modal for Cancel Subscription */}

      <ModalComponent
        isOpen={confirmModal}
        onClose={() => setConfirmModal(false)}
        closable={false}
        className="tracelo_unsubscribe-modal reactivate__subscription__wrap"
      >
        <div className="login-modal-section">
          <div className="unsubscribe_title">
            <h2>
              {t("PR_CANCEL")}{" "}
              {subscriptionMap[getSubscriptionName(subscriptionData.name)]}{" "}
            </h2>
          </div>
          <div className="unsubscribe-modal-body">
            <div
              className="unsubscribe-text"
              dangerouslySetInnerHTML={{
                __html: t("PR_CANCEL_CONFIRMATION_TEXT", {
                  subscription_name:
                    subscriptionMap[getSubscriptionName(subscriptionData.name)],
                }),
              }}
            ></div>
            <div className="unsubscribe_ButtonWrapper">
              <button className="unsubscribe_ClrButton unsubscribe_bluebutton"
                onClick={() => {
                  setConfirmModal(false);
                  handleCancelSubscription(subscriptionData?.payment_gateway_id);
                }}
              >
                {t("PR_SETTING_UNSUBSCRIBE_CONFIRM")}
              </button>
            </div>
            <div className="unsubscribe_ButtonWrapper">
              <button
                className="unsubscribe_ClrButton"
                onClick={() => setConfirmModal(false)}
              >
                {t("PR_SETTING_UNSUBSCRIBE_CANCEL")}
              </button>
            </div>
          </div>
        </div>
      </ModalComponent>

      {/* Confirmation modal for add more reports */}

      <ModalComponent
        isOpen={addMoreReportModal}
        onClose={() => setAddMoreReportModal(false)}
        closable={true}
        className="wpb_buy__reports_wrapper"
      >
        <AddMoreReport
          price={`${symbol}${price}`}
          handleFlow={() => {
            setAddMoreReportModal(false);
            handleSubscription(subscriptionData);
          }}
        />
      </ModalComponent>

      {/* Confirmation modal for Reactivate subscription */}

      <ReactivateModal
        isOpen={reactiveModal}
        onClose={() => setReactiveModal(false)}
        handleSubscription={() => handleSubscription(subscriptionData, true)}
        subscriptionData={subscriptionData}
      />

      {/* Modal for transaction Declined */}

      <TransactionDeclinedModal
        isOpen={retryModal}
        onClose={handleRetryModalClose}
        closable={false}
        handleSubmit={onRetry}
        orderDetails={orderDetails}
        cardLoadIntent={cardLoadIntent}
        setCardLoadIntent={setCardLoadIntent}
      />
    </>
  );
};

export default SubscriptionListPage;
