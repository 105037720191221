import {useSelector} from "react-redux";
import {renderRTLClass} from "../../../utils/commonUtils";
import {Cookies} from "react-cookie";
import {CONSTANTS} from "../../../phoneReport/utils/constant";

export const useProductPrices = () => {
  const cookie = new Cookies();
  const currency = cookie.get("currency");
  const {productListData, isLoading} = useSelector(
    (state) => state.productList || {}
  );
  const getProductPrice = (title) => {
    const product = productListData?.data?.find((item) => item.title === title);
    return (
      product?.currency_options.find((item) => item.parameter === currency) ||
      null
    );
  };
  return {
    trialProduct: getProductPrice("trial_double_intent"),
    subscriptionProduct: getProductPrice(CONSTANTS.SUBSCRIPTIONS.TRACKING),
    phoneReportProduct: getProductPrice(CONSTANTS.SUBSCRIPTIONS.PHONE_REPORT),
    premiumReportProduct: getProductPrice(
      CONSTANTS.SUBSCRIPTIONS.PREMIUM_REPORT
    ),
    singlePdfProduct: getProductPrice(CONSTANTS.ONETIME.SINGLE_PDF),
    singlePremiumReportProduct: getProductPrice(
      CONSTANTS.ONETIME.SINGLE_REPORT
    ),
    singlePremiumSectionProduct: getProductPrice(
      CONSTANTS.ONETIME.SINGLE_SECTION
    ),
    pdfDownloadProduct: getProductPrice(CONSTANTS.SUBSCRIPTIONS.PDF_DOWNLOAD),
    currencySymbol: getProductPrice(CONSTANTS.SUBSCRIPTIONS.TRACKING)?.symbol,
    isLoading: isLoading
  };
};

// Function for get perticualr product price
// API Name : price_title_list
export const useProductRates = () => {
  const pricingData = useSelector(
    (state) => state.pricingTitleList?.priceTitleList?.data || {}
  );
  const {subscriptions = {}, sections = {}} = pricingData;
  const getProductRate = (key) => {
    return subscriptions[key]?.amount || sections[key]?.amount || null;
  };
  return {
    pdfSubscriptionPrice: getProductRate(CONSTANTS.SUBSCRIPTIONS.PDF_DOWNLOAD),
    reportSubscriptionPrice: getProductRate(
      CONSTANTS.SUBSCRIPTIONS.PHONE_REPORT
    ),
    trackingSubscriptionPrice: getProductRate(CONSTANTS.SUBSCRIPTIONS.TRACKING),
    premiumSubscriptionPrice: getProductRate(
      CONSTANTS.SUBSCRIPTIONS.PREMIUM_REPORT
    ),
    singlePdfPrice: getProductRate(CONSTANTS.ONETIME.SINGLE_PDF),
    singleReportPrice: getProductRate(CONSTANTS.ONETIME.SINGLE_REPORT),
    singleSectionPrice: getProductRate(CONSTANTS.ONETIME.SINGLE_SECTION),
    currencySymbol:
      subscriptions[Object.keys(subscriptions)[0]]?.symbol ||
      sections[Object.keys(sections)[0]]?.symbol ||
      "$"
  };
};

export const useProductPriceByKey = (keyName) => {
  const data = useProductRates();
  const currencySymbol = data?.currencySymbol;
  const productMap = {
    [CONSTANTS.SUBSCRIPTIONS.TRACKING]: data?.trackingSubscriptionPrice,
    [CONSTANTS.SUBSCRIPTIONS.PHONE_REPORT]: data?.reportSubscriptionPrice,
    [CONSTANTS.SUBSCRIPTIONS.PREMIUM_REPORT]: data?.premiumSubscriptionPrice,
    [CONSTANTS.ONETIME.SINGLE_PDF]: data?.singlePdfPrice,
    [CONSTANTS.ONETIME.SINGLE_REPORT]: data?.singleReportPrice,
    [CONSTANTS.ONETIME.SINGLE_SECTION]: data?.singleSectionPrice,
    [CONSTANTS.SUBSCRIPTIONS.PDF_DOWNLOAD]: data?.pdfSubscriptionPrice
  };
  const productPrice = productMap[keyName];
  if (productPrice == null) {
    return null;
  }
  return `${currencySymbol} ${productPrice}`;
};


export const isMobileDevice = () => {
  const userAgent = navigator.userAgent || window.opera;
  return /iPhone|iPad|iPod|Android/i.test(userAgent);
};

export const getClassName = (step) => {
  const baseClasses = ["login_btn__wrapper", renderRTLClass()];

  if (step === 0) baseClasses.push("animation-model");
  if (step === 1) {
    baseClasses.push("signup__locating_wrap", "signup-info-wrap");
  }
  if (step === 2) baseClasses.push("checkout_payment_modal");
  if (step !== 0) baseClasses.push("locating-login-modal");

  return baseClasses.join(" ");
};
