import React from "react";
import "./sessionExpiredModal.scss";
import { AiOutlineExclamation } from "react-icons/ai";
import { Cookies } from "react-cookie";
import { Modal } from "antd";
import { t } from "i18next";
const SessionExpiredModal = ({ merchantNotExist }) => {
  const cookies = new Cookies();
  const lang = cookies.get("lang");
  const handleCancel = () => {
    cookies.remove("token", { path: "/" });
    cookies.remove("userEmail", { path: "/" });
    cookies.remove("userName", { path: "/" });
    cookies.remove("role", { path: "/" });
    cookies.remove("step", { path: "/" });
    cookies.remove("subscriptionInfoModal", { path: "/" });
    cookies.remove("userId", { path: "/" });
    window.location.reload();
    Modal.destroyAll();
    if (window.location.pathname.split("/")[2]) {
      window.location.href = `/${lang}/login?returnUrl=${window.location.pathname.split("/")[2]
        }`;
    } else {
      window.location.href = `/${lang}/login`;
    }
  };
  return (
    <div className="session-Model">
      <div className="session-modal-logo">
        <div className="bd-logo">
          <AiOutlineExclamation />
        </div>
      </div>
      <div className="session-modal-text">
        {merchantNotExist ? (
          <h3>{t("PR_USER_DOESNOT_EXIST")}.</h3>
        ) : (
          <>
            <h3>{t("PR_SESSION_EXPIRED")}</h3>
            <p>{t("PR_SESSION_EXPIRED_WARNING")}</p>
          </>
        )}
      </div>
      <div className="session-modal-btn">
        <button className="primary_btn btn hl_cta_wrap" onClick={handleCancel}>
          {t("PR_GO_BACK_LOGIN")}
        </button>
      </div>
    </div>
  );
};

export default SessionExpiredModal;
