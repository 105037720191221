import React, { useEffect, useState } from "react";
import PhoneReportDashboardComponent from "./phoneReportDashboard";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import parsePhoneNumberFromString, {
  isValidPhoneNumber,
} from "libphonenumber-js";
import Notify from "../../../components/common/Notify/notify";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Cookies } from "react-cookie";
import {
  locationList,
  sendLocation,
  checkIsPhoneNmberExists,
  createLocation
} from "../../redux/slice";
import {checkAllSubscriptionCancel} from "../../redux/slice/checkAllSubscriptionCancelSlice";
import SubscriptionInfoModal from "./subscriptionInfoModal/subscriptionInfoModal";
import { checkAdminLogin } from "../../utils/commonUtils";

const PhoneReportDashboard = ({
  callsendLocation,
  getLocationList,
  locationListData,
  sendDetailsData,
  callCheckIsPhoneNumberExists,
  callCreateLocation,
  checkAllSubscriptionCancel
}) => {
  const {t} = useTranslation();
  const [searchparams] = useSearchParams();
  const searchNumber = Object.fromEntries([...searchparams]);
  const navigate = useNavigate();
  const cookies = new Cookies();
  const lang = cookies.get("lang");
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = async (page) => {
    setCurrentPage(page);
    await getLocationList(page);
  };

  const [receiverNumber, setReceiverNumber] = useState("");
  const [message, setMessage] = useState(t("SMS_DEFAULT_TEXT"));
  const [countryCode, setCountryCode] = useState(+1);
  const [countryCodeinput, setCountryCodeinput] = useState("ca");
  const [messageError, setMessageError] = useState("");
  const [number, setNumber] = useState("");
  const [receiverCountryCode, setReceiverCountryCode] = useState(+1);
  const [formatedNumber, setFormatedNumber] = useState(0);

  const [countryShortName, setCountryShortName] = useState("");
  const [popNumberError, setPopNumberError] = useState("");
  const [loading, isLoading] = useState(false);
  const [openLocateModal, setOpenLocateModal] = useState(false);
  const [resultError, setResultError] = useState("");
  const [numberError, setNumberError] = useState("");
  const [numberValidate, setNumberValidate] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [receiverError, setReceiverError] = useState("");
  const [receiverDialCode, setReceiverDialCode] = useState(0);
  const [showSubscriptionInfoModal, setShowSubscriptionInfoModal] =
    useState(false);
  useEffect(() => {
    setNumber("");
    window.onpopstate = null;
  }, []);

  // Code for show subscription cancel info modal
  useEffect(() => {
    checkAllSubscriptionCancel()
      .then((res) => {
        const hasSeenPopup = cookies.get("subscriptionInfoModal");
        const isShowPopup = res?.payload?.data?.is_all_canceled;
        if (isShowPopup && !hasSeenPopup) {
          setShowSubscriptionInfoModal(true);
        } else {
          setShowSubscriptionInfoModal(false);
        }
      })
      .catch((err) => {
        console.error("error", err);
      });
      // eslint-disable-next-line
  }, []);

  const handleCloseSubscriptionInfoModal = () => {
    setShowSubscriptionInfoModal(false);
    cookies.set("subscriptionInfoModal", true, {
      path: "/",
      sameSite: true
    });
  };

  useEffect(() => {
    const data = sendDetailsData?.getSendDetailsData?.data;
    if (data) {
      setCountryShortName(data?.country);
      setReceiverDialCode(data?.country_calling_code.replace("+", ""));
    }
  }, [sendDetailsData]);

  useEffect(() => {
    if (searchNumber.D && searchNumber.n) {
      setReceiverCountryCode("+" + searchNumber.D);
      if (!receiverNumber) {
        setReceiverNumber(searchNumber.D);
      }
      setNumber(searchNumber.D + searchNumber.n);
      setCountryCode("+" + searchNumber.D);
    }
    // eslint-disable-next-line
  }, [searchNumber.D, searchNumber.n]);

  useEffect(() => {
    if (location.state?.isResetPage) {
      setCurrentPage(1);
    }
  }, [location.state]);

  useEffect(() => {
    getLocationList(currentPage);
    // eslint-disable-next-line
  }, [currentPage]);

  const handleOnReceiverNumberChange = (value, country) => {
    setReceiverDialCode(country.dialCode);
    setReceiverNumber(value);
    setPopNumberError("");
    setReceiverCountryCode(`+${country.dialCode}`);
    setReceiverError("");
  };

  const handleChangeMessage = (event) => {
    setMessage(event.target.value);
    setMessageError(event.target.value === "" ? t("SMS_MESSAGE_ERROR") : "");
  };

  const openLocatingModal = () => {
    setOpenLocateModal(true);
  };

  const closeLocatingModal = () => {
    setOpenLocateModal(false);
    setMessage(t("SMS_DEFAULT_TEXT"));
    setReceiverNumber("");
    setFormatedNumber("");
  };

  const handleSendMessage = async (
    isCreateLocation,
    senderNumber,
    dialCode
  ) => {
    const data = {
      sender_country_code: "+" + dialCode,
      sender_mobile_number: senderNumber
    };
    isLoading(true);
    setIsButtonDisabled(true);
    let result;
    if (isCreateLocation) {
      isLoading(true);
      result = await callCreateLocation(data);
      const {payload} = result || {};
      const {data: responseData} = payload || {};
      const {_id} = responseData ?? {};
      isLoading(false);
      navigate(
        `/${lang}/access-report?D=${responseData.phone_number_prefix}&n=${responseData.phone_number}&id=${_id}`
      );

      Notify("success", t("NOTIFY_SEND_LOCATION"), "");
      setReceiverNumber(receiverCountryCode);
      setNumber(countryCode);
    } else {
      isLoading(true);
      result = await callsendLocation(data);
      isLoading(false);
    }
    if (result.type === "sendLocation/fulfilled") {
      Notify("success", t("NOTIFY_SEND_LOCATION"), "");
      closeLocatingModal();
      document.body.style.overflow = "unset";
      getLocationList(currentPage);
      setIsButtonDisabled(false);
      isLoading(false);
      setReceiverNumber(receiverCountryCode);
      setNumber(countryCode);
      navigate(`/${lang}/access-report`);
    } else if (result.type === "sendLocation/rejected") {
      setResultError(result.error.message);
      closeLocatingModal();
      document.body.style.overflow = "unset";
      isLoading(false);
      setIsButtonDisabled(false);
    }
  };

  const handlePreviewNavigate = () => {
    if (
      receiverNumber.replace(receiverDialCode, "") !== "" &&
      receiverNumber.replace(receiverDialCode, "") !== " "
    ) {
      navigate(`/${lang}/location-preview-popup`, {
        state: {receiverNumber, message}
      });
    } else {
      setReceiverError("valid phone number required");
    }
  };

  const handleOnNumberChange = (value, country) => {
    setCountryCode(`+${country.dialCode}`);
    setNumber(value);
    setCountryShortName(country.countryCode.toUpperCase());
    setNumberError("");
  };
  const handleLocateNewNumber = async (
    dialCode,
    passedPhoneNumber,
    finalFormattedNumber
  ) => {
    if (checkAdminLogin()) {
      return;
    }
    setNumberError("");
    const parsedNumber = parsePhoneNumberFromString(
      passedPhoneNumber,
      countryShortName.replace("+", "").toUpperCase()
    );
    let trimmedNumber = finalFormattedNumber.trim();
    let formatRemove = trimmedNumber;
    if (trimmedNumber.startsWith(dialCode)) {
      trimmedNumber = trimmedNumber.substring(dialCode.length).trim();
      if (trimmedNumber.length < 10) {
        if (dialCode.length <= 2) {
          trimmedNumber = dialCode + trimmedNumber;
          formatRemove = dialCode + formatRemove;
        }
      }
    }
    if (trimmedNumber.startsWith(0)) {
      trimmedNumber = trimmedNumber.substring(1).trim();
    }
    formatRemove = trimmedNumber
      .replaceAll(" ", "")
      .replace("-", "")
      .replace("(", "")
      .replace(")", "");
    setFormatedNumber(`+${dialCode} ${trimmedNumber}`);
    if (passedPhoneNumber && passedPhoneNumber !== "0") {
      if (
        parsedNumber &&
        parsedNumber.isValid() &&
        parsedNumber.country === countryShortName.replace("+", "").toUpperCase()
      ) {
        setNumberValidate("");
        const data = {
          phone_number_prefix: "+" + dialCode,
          phone_number: formatRemove
        };
        isLoading(true);
        const result = await callCheckIsPhoneNumberExists(data);
        if (result.type === "checkIsPhoneNmberExists/fulfilled") {
          const {success, info} = result.payload.data || {};
          const {_id} = info || {};
          if (!success && passedPhoneNumber) {
            handleSendMessage(!success, formatRemove, dialCode);
          } else {
            if (passedPhoneNumber && passedPhoneNumber !== 0) {
              if (
                isValidPhoneNumber(
                  passedPhoneNumber,
                  `${
                    countryShortName
                      ? countryShortName.toUpperCase()
                      : countryCodeinput.toUpperCase()
                  }`
                )
              ) {
                navigate(
                  `/${lang}/access-report?D=${info.phone_number_prefix}&n=${info.phone_number}&id=${_id}`
                );
                setNumberError("");
              } else {
                setNumberValidate(t("ERROR_VALID_NUMBER_DASHBOARD"));
              }
            } else {
              setNumberError(t("ERROR_ENTER_NUMBER_DASHBOARD"));
            }
          }
        } else {
          Notify("error", result.error.message, "");
        }
        isLoading(false);
      } else {
        setNumberValidate(t("ERROR_VALID_NUMBER_DASHBOARD"));
      }
    } else {
      setNumberError(t("ERROR_ENTER_NUMBER_DASHBOARD"));
    }
  };
  return (
    <>
      <PhoneReportDashboardComponent
        countryShortName={countryShortName}
        senderNumber={number}
        loading={loading}
        number={number}
        numberError={numberError}
        handleLocateNewNumber={handleLocateNewNumber}
        handleOnNumberChange={handleOnNumberChange}
        locationListData={locationListData}
        numberValidate={numberValidate}
        setFormatedNumber={setFormatedNumber}
        setCountryShortName={setCountryShortName}
        setNumberValidate={setNumberValidate}
        currentPage={currentPage}
        handlePageChange={handlePageChange}
        ///
        receiverNumber={receiverNumber}
        handleOnReceiverNumberChange={handleOnReceiverNumberChange}
        popNumberError={popNumberError}
        handleChangeMessage={handleChangeMessage}
        messageError={messageError}
        handleSendMessage={handleSendMessage}
        sendLocation={sendLocation}
        locatingModalOpen={openLocateModal}
        setCountryCodeinput={setCountryCodeinput}
        resultError={resultError}
        openLocatingModal={openLocatingModal}
        closeLocatingModal={closeLocatingModal}
        handlePreviewNavigate={handlePreviewNavigate}
        isButtonDisabled={isButtonDisabled}
        receiverError={receiverError}
        formatedNumber={formatedNumber}
        message={message}
      />
      {showSubscriptionInfoModal && !checkAdminLogin() &&(
        <SubscriptionInfoModal
          visible={showSubscriptionInfoModal}
          onClose={handleCloseSubscriptionInfoModal}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    sendLocation: state.sendLocation,
    locationListData: state.locationList,
    sendDetailsData: state.sendDetailsData,
    checkIsPhoneNmberExists: state.checkIsPhoneNmberExists,
    checkIsPhoneNmberExistsData: state.checkIsPhoneNmberExistsData,
    callCreateLocation: state.callCreateLocation
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    callsendLocation: (data) => dispatch(sendLocation(data)),
    getLocationList: (data) => dispatch(locationList(data)),
    callCheckIsPhoneNumberExists: (data) =>
      dispatch(checkIsPhoneNmberExists(data)),
    callCreateLocation: (data) => dispatch(createLocation(data)),
    checkAllSubscriptionCancel: () => dispatch(checkAllSubscriptionCancel())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PhoneReportDashboard);
