import React, { useEffect, useState } from "react";
import "./UnlockReportBackgroundProcess.scss";
import ModalComponent from "../../../common/Modal/modal";
import Lottie from "react-lottie";
import newLoader from "../../../../lottieAnimations/animationJson/newLoader.json";
import { Progress } from "antd";
import { t } from "i18next";

const UnlockReportBackgroundProcess = (props) => {
  const { isOpen, onClose, formateNumber } = props;
  const [percent, setPercent] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (isOpen) {
      setPercent(0);
    }
    // eslint-disable-next-line
  }, []);

  const backgroundProcessText = [
    t("FETCHING_PHONE_REPORT"),
    t("LOOKING_UP_CALLER_ID"),
    t("ANALYZING_NUMBER"),
    t("GATHERING_INFORMATION"),
    t("SEARCHING_DATABASES"),
    t("COMPILING_RESULTS"),
    t("CHECKING_NUMBER_HISTORY"),
    t("SCANNING_RECORDS"),
    t("TRACING_NUMBER"),
    t("COLLECTING_DATA"),
    t("RETRIEVING_DETAILS"),
    t("PROCESSING_REQUEST"),
    t("UNCOVERING_INFORMATION"),
    t("BUILDING_REPORT"),
    t("VERIFYING_DATA"),
    t("PREPARING_REPORT"),
    t("FINALIZING_REPORT"),
    t("JUST_A_MOMENT"),
  ];

  useEffect(() => {
    const updateProgress = () => {
      setPercent((prevPercent) => {
        if (prevPercent < 85) return prevPercent + 1;
        if (prevPercent < 95) return prevPercent + 1;
        return 95;
      });
    };
    let interval;
    if (percent < 85) {
      interval = setInterval(updateProgress, 50);
    } else {
      interval = setInterval(updateProgress, 5000);
    }
    return () => clearInterval(interval);
  }, [isOpen, percent]);

  useEffect(() => {
    let interval;
    if (isOpen) {
      interval = setInterval(() => {
        setCurrentIndex((prevIndex) => {
          return prevIndex < backgroundProcessText.length - 1
            ? prevIndex + 1
            : prevIndex;
        });
      }, 5000); // 5 Second
    }
    return () => clearInterval(interval);
  }, [isOpen, backgroundProcessText.length]);

  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={onClose}
      closable={false}
      maskClosable={false}
    >
      <div className="unlock__report_process">
        <div className="unlock__report__info mb-mrg">
          <h2 className="modal__popup_title">{t("PR_WE_PREPARING_REPORT")}</h2>
          <p className="modal__popup_desc">
            {t("PR_UPDATING_REPORT")}{" "}
            <b>
              <a href={`tel:${formateNumber}`}>
                <p className="mobile_number">{formateNumber}</p>
              </a>
            </b>
          </p>
        </div>
        <div className="reportr__img mb_mrg__02">
          <Lottie
            options={{ animationData: newLoader }}
            animation
            height={253}
            width={250}
          />
          <div className="AnimationTextWrap">
            <span> {backgroundProcessText[currentIndex]}...</span>
          </div>
        </div>
        <div className="reportr__loader mb_mrg__02">
          <h1 className="progress_percentage">{percent}%</h1>
          <Progress
            percent={percent}
            status="active"
            strokeColor={{ from: "#245279", to: "#75afde" }}
            trailColor="#eaeaea"
            showInfo={false}
          />
        </div>
        <div className="unlock__report__info">
          <p className="modal__popup_desc">{t("PR_WAITING_MESSAGE")}</p>
        </div>
      </div>
    </ModalComponent>
  );
};
export default UnlockReportBackgroundProcess;
