export const isTrackPage = () => {
  return window.location.pathname.includes("track");
};

export const cleanUpUrl = (currentUrl) => {
  const paramsToRemove = [
    "type",
    "aname",
    "sname",
    "status",
    "expire_at",
    "order_id",
    "error_code",
    "recommended_message_for_user",
    "section"
  ];
  try {
    const url = new URL(currentUrl);
    paramsToRemove.forEach((param) => {
      url.searchParams.delete(param);
    });
    return url.toString();
  } catch (error) {
    console.error("Error cleaning up URL:", error);
    return currentUrl; // Return the original URL in case of an error
  }
};


export const cleanUrl = (currentUrl) => {
  try {
    const url = new URL(currentUrl);
    url.search = "";
    return url.toString();
  } catch (error) {
    console.error("Error cleaning URL:", error);
    return currentUrl;
  }
};
