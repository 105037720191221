import React, {useState} from "react";
import {Cookies} from "react-cookie";
import SignUpSolidGateComponent from "./signUpSolidGate";

const SignUpSolidGate = () => {
  const cookie = new Cookies();
  const lang = cookie.get("lang");
  const cur = cookie.get("currency");
  const [userInfo, setUserInfo] = useState({
    email: "",
    currency: cur?.substr(0, 3) || "usd",
    language: lang ? lang : "en",
    symbol: ""
  });

  return <SignUpSolidGateComponent data={userInfo} setData={setUserInfo} />;
};

export default SignUpSolidGate;
