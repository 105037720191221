import React from "react";
import { Button, Row, Col } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import { ReactComponent as LeftArrow } from "../../../../phoneReport/assets/images/left-arrow.svg";
import "./adminBar.scss";

const AdminUserBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pagesWithBorder = ["privacy-policy", "terms"];
  const showBorderBottom = pagesWithBorder.includes(
    location.pathname.split("/")[2]
  );
  const cookie = new Cookies();
  const lang = cookie.get("lang");

  const onGoToAdminSpace = () => {
    cookie.remove("userId", { path: "/" });
    setTimeout(() => {
      if (!cookie.get("userId")) {
        navigate(`/${lang}/admin/dashboard`);
      }
    }, 100);
  };

  return (
    <div className={`adminbar-wrap ${showBorderBottom ? "with-border" : ""}`}>
      <Row
        justify="space-between"
        align="middle"
        className="adminbar-container main_container"
      >
        <Col>
          <span className="adminbar-text">
            You are now on the user portal, where you can manage all your
            actions seamlessly.
          </span>
        </Col>
        <Col>
          <Button
            icon={<LeftArrow />}
            onClick={onGoToAdminSpace}
            className="adminbar-button"
          >
            Go to Admin Space
          </Button>
        </Col>
      </Row>
    </div>
  );
};
export default AdminUserBar;
