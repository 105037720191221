import { t } from "i18next";
import ModalComponent from "../../../components/common/Modal/modal";
import { getSubscriptionName } from "../../../utils/commonUtils";

const ReactivateModal = ({
  isOpen,
  onClose,
  handleSubscription,
  subscriptionData,
}) => {
  const subscriptionMap = {
    phone_report: t("PR_PHONE_REPORT"),
    premium_report: t("PR_PRICING_PREMIUM_REPORTS"),
    pdf_download: t("PR_PDF_DOWNLOAD"),
    phone_tracking: t("PR_REPORT_PHONE_TRACKING"),
  };
  const price = subscriptionData?.symbol + subscriptionData?.amount;
  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={onClose}
      closable={false}
      className="tracelo_unsubscribe-modal reactivate__subscription__wrap"
    >
      <div className="login-modal-section">
        <div className="unsubscribe_title">
          <h2>{t("REACTIVATE_SUBSCRIPTION")}</h2>
        </div>
        <div className="unsubscribe-modal-body">
          <div
            className="unsubscribe-text"
            dangerouslySetInnerHTML={{
              __html: t("PR_ACCOUNT_REACTIVATE_SUBSCRIPTION", {
                subscriptionName:
                  subscriptionMap[getSubscriptionName(subscriptionData.name)],
              }),
            }}
          ></div>
          <div className="submit-btn-modal">
            <button
              onClick={() => {
                onClose();
                handleSubscription();
              }}
            >
              {t("REACTIVATE")}
            </button>
          </div>
          <div className="unsubscribe_ButtonWrapper">
            <button className="unsubscribe_ClrButton" onClick={onClose}>
              {t("PR_CANCEL")}
            </button>
          </div>
          <div className="reactive_modal_desc">
            {t("PR_REACTIVE_MODAL_DISC", {
              price: price,
            })}
          </div>
        </div>
      </div>
    </ModalComponent>
  );
};

export default ReactivateModal;
