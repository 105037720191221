import React from "react";
import { t } from "i18next";
import {
  profileLinkIcon,
  possiblePhotosImg,
  nodataIcon,
} from "../../../assets/images";
import UnlockReport from "../unlockReport/unlockReport";
import {
  renderValue,
  socialIcons,
  toCamelCase,
} from "../../../utils/commonUtils";
import SectionAction from "../sectionAction/sectionAction";
import NoInfoFound from "../noInfoFound/noInfoFound";
import { checkAdminLogin } from "../../../utils/commonUtils";
import { Link } from "react-router-dom";

const SocialProfile = ({ isLocked, sectionName, reportDetail }) => {
  const { social_media_profiles } = reportDetail?.phoneReport || {};
  const { data, status } = social_media_profiles || {};

  const getIcon = (name) => {
    const lowerCaseName = name?.toLowerCase();
    return socialIcons[lowerCaseName || "defaultImage"];
  };
  const { phone_owner_info } = reportDetail?.phoneReport || {};
  const isData = phone_owner_info?.status === "found";
  return isLocked ? (
    <UnlockReport
      title={t("PR_SOCIAL_MEDIA_PROFILES")}
      image={possiblePhotosImg}
      buttonText={t("PR_UNLOCK_THIS_SECTION").toUpperCase()}
      shouldKnowText={t("PR_UNLOCK_TITLE")}
      knownText={t("PR_SOCIAL_PROFILE_MESSAGE")}
      sectionName={sectionName}
      pricingSectionName={"social_media_profiles"}
      id={"Social Profiles"}
    />
  ) : !isLocked && status === "not_found" ? (
    <NoInfoFound
      sectionName={sectionName}
      id={toCamelCase("Social Profiles")}
    />
  ) : (
    <div
      className="ds--social-profile white-bg-wrap"
      id={toCamelCase("Social Profiles")}
    >
      <div className="unlock_title unlock-description toggle_area">
        <h2>{t("PR_SOCIAL_MEDIA_PROFILES")}</h2>
        <SectionAction />
      </div>
      <div className="ds--job-wrapper">
        {" "}
        {status === "found" && (
          <p>
            {isData && phone_owner_info.data.name
              ? t("PR_SOCIAL_PROFILE_SUBTITLE", {
                  phone_owner_full_name: isData
                    ? renderValue(phone_owner_info.data.name)
                    : "USER",
                })
              : t("PR_SOCIAL_PROFILE_TITLE", {
                  phone_number: isData
                    ? reportDetail?.phoneReport.phone_number_prefix +
                      reportDetail?.phoneReport.phone_number
                    : "USER",
                })}
          </p>
        )}
      </div>
      {status === "found" && data.length > 0 ? (
        <div className="social-profiles">
          {data
            ?.filter(
              (item, index, self) =>
                index === self?.findIndex((t) => t?.url === item?.url)
            )
            ?.map((profile) => (
              <div className="social-profiles--boxes" key={profile._id}>
                <div className="social-profiles-icon">
                  <img src={getIcon(profile.name)} width={32} height={32} alt={profile.name} />
                </div>
                <div className="social-profiles-content">
                  <span>{profile.name}</span>
                  <p>{profile.url}</p>
                </div>
                <div className="social-profiles-btn">
                  <p>
                    <Link
                      to={checkAdminLogin() ? "#" : profile.url}
                      target="_blank"
                      onClick={(e) => {
                        if (checkAdminLogin()) {
                          e.preventDefault();
                        }
                      }}
                    >
                      {t("PR_SOCIAL_PROFILE_BUTTON")}{" "}
                      <img src={profileLinkIcon} width={13} height={14} alt="Link Icon" />
                    </Link>
                  </p>
                </div>
              </div>
            ))}
        </div>
      ) : (
        <p className="data_notfound">
          <img src={nodataIcon} alt="" /> {t("PR_RECORD_NOT_FOUND")}
        </p>
      )}
    </div>
  );
};

export default SocialProfile;
