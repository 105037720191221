import React, {useLayoutEffect} from "react";
import TraceComponent from "./trace";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useEffect} from "react";
import {connect} from "react-redux";
import {useState} from "react";
import {Cookies} from "react-cookie";
import {settingBanIp} from "../../redux/slice/settingBanIpSlice";
import {checkSettingBanIp} from "../../redux/slice/checkSettingBanIpSlice";
import {iPQualityScore} from "../../redux/slice/iPQualityScoreSlice";
import platform from "platform";

const Trace = (props) => {
  const {callSettingBanIp, callCheckSettingBanIp, getIPQualityScore, ipData} =
    props;
  const cookies = new Cookies();
  const lang = cookies.get("lang");
  const navigate = useNavigate();
  const [searchparams] = useSearchParams();
  const searchNumber = Object.fromEntries([...searchparams]);
  const [ip, setIp] = useState();
  const [page, setPage] = useState("");
  const keys = Object.keys(searchNumber);
  const banIp = cookies.get("banned");
  const token = cookies.get("token");

  useEffect(() => {
    cookies.set("param", JSON.stringify(searchNumber), {path: "/"});
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (ipData && ipData.ip) {
      setIp(ipData.ip);
    }
  }, [ipData]);

  const handleCheckIpQuality = async () => {
    const data = {
      ip_address: ip,
      user_agent: platform.ua
    };
    const ipResult = await getIPQualityScore(data);
    if (ipResult.type === "iPQualityScore/fulfilled") {
      const {ISP, active_vpn, is_crawler, region} = ipResult.payload.data.data;
      if (ISP === "iCloud Private Relay" && active_vpn) {
        setPage("track");
      } else if (
        active_vpn ||
        is_crawler ||
        ISP.includes("Google") ||
        ISP.includes("google") ||
        (window.location.hostname === "www.reversly.com" &&
          region.toLowerCase().includes("quebec"))
      ) {
        cookies.set("banned", "true");
        const data = {
          ip_address: ip,
          status: false
        };
        callSettingBanIp(data);
        setPage("Home");
      } else {
        setPage("track");
      }
    }
  };

  const handleGetIp = async () => {
    if (token) {
      navigate(`/${lang}/dash`);
    }
    if (ip) {
      const resData = {
        ip_address: ip
      };
      if (keys.includes("off")) {
        setPage("track");
      } else if (keys.includes("clk")) {
        if (searchNumber.clk === "ae4h3") {
          const result = await callCheckSettingBanIp(resData);
          if (banIp) {
            setPage("Home");
          } else if (!result?.payload?.data) {
            setPage("Home");
          } else {
            handleCheckIpQuality();
          }
        } else {
          setPage("Home");
        }
      } else {
        setPage("Home");
      }
    }
  };

  useLayoutEffect(() => {
    handleGetIp();
  }, [ip]);

  return <TraceComponent page={page} />;
};
const mapStateToProps = (state) => {
  return {
    iPQualityScore: state.iPQualityScore,
    checkSettingBanIp: state.checkSettingBanIp,
    ipData: state.getIPIfy.getIPIfyData
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    callSettingBanIp: (data) => dispatch(settingBanIp(data)),
    callCheckSettingBanIp: (data) => dispatch(checkSettingBanIp(data)),
    getIPQualityScore: (data) => dispatch(iPQualityScore(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Trace);
