import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import Notify from "../../common/Notify/notify";
import SolidGate from "../../common/solidGate/solidGate";
import { settingBanIp } from "../../../redux/slice/settingBanIpSlice";
import { savegclid } from "../../../redux/slice/savegclidSlice";
import { solidGateIntentCreate } from "../../../redux/slice/solidGate/solidGateIntentCreateSlice";
import { solidGateSubscriptionCreate } from "../../../redux/slice/solidGate/solidGateSubscriptionCreateSlice";
import { getGclidDetails } from "../../../redux/slice/getGclidDetailsSlice";
import { isSupportedLang, useUserData } from "../../../utils/commonUtils";
import DummyLayout from "./dummyLayout/dummyLayout";
import { isTrackPage } from "../../../utils/helpers";
import { applePay_merchantId } from "../../../environment";
import { trackEvent } from "../../../utils/eventTracker";

const CheckoutComponent = (props) => {
  const {
    isLoading,
    setIsLoading,
    data,
    trialProduct,
    subscriptionProduct,
    senderNumber,
    senderNumberCountry,
    merchantData,
    intentCreateData,
    setMerchantData,
    oncloseCheckout,
  } = props;

  const gcLid = useSelector(
    (state) => state?.getGclidDetails?.getGclidDetailsData?.data
  );
  const ip = useSelector((state) => state?.getIPIfy?.getIPIfyData?.ip);

  const disPatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userData = useUserData();
  const cookie = new Cookies();
  const lang = cookie.get("lang");
  const params = cookie.get("param");

  const [errorMessage, setErrorMessage] = useState("");
  const [cardType, setCardType] = useState("");
  const [isGooglePayAvailable, setIsGooglePayAvailable] = useState(false);
  const [isApplePayAvailable, setIsApplePayAvailable] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [form, setForm] = useState(null);

  useEffect(() => {
    if (ip) {
      disPatch(getGclidDetails(ip));
    }
  }, [ip]);

  useEffect(() => {
    if (window.ApplePaySession) {
      var merchantIdentifier = applePay_merchantId;
      var promise =
        window.ApplePaySession.canMakePaymentsWithActiveCard(
          merchantIdentifier
        );
      promise.then(function (canMakePayments) {
        if (canMakePayments) {
          console.log("canMakePayments", canMakePayments);
        } else {
          console.log("else canMakePayments");
        }
      });
    }
  }, []);

  const ErrorMessage = {
    0.01: t("GENERAL_DECLINE_0.01"),
    0.02: t("ORDER_EXPIRED_0.02"),
    0.03: t("ILLEGAL_OPERATION_0.03"),
    2.06: t("INVALID_CVV2_CODE_2.06"),
    2.08: t("INVALID_CARD_NUMBER_2.08"),
    2.09: t("INVALID_EXPIRATION_DATE_2.09"),
    2.1: t("INVALID_3DS_FLOW_ON_MERCHANT_SIDE_2.1"),
    3.01: t("CARD_BLOCKED_3.01"),
    3.02: t("INSUFFICIENT_FUNDS_3.02"),
    3.03: t("PAYMENT_AMOUNT_LIMIT_EXCESS_3.03"),
    3.04: t("TRANSACTION_DECLINED_BY_ISSUER_3.04"),
    3.05: t("CALL_YOUR_BANK_3.05"),
    3.06: t("DEBIT_CARD_NOT_SUPPORTED_3.06"),
    3.07: t("CARD_BRAND_NOT_SUPPORTED_3.07"),
    3.08: t("DO_NOT_HONOR_3.08"),
    3.1: t("SUSPECTED_FRAUD_3.1"),
    4.02: t("STOLEN_CARD_4.02"),
    4.04: t("LOST_CARD_4.04"),
    4.05: t("PSP_ANTIFRAUD_4.05"),
    4.07: t("TRUSTED_ANTIFRAUD_SYSTEM_4.07"),
    5.01: t("THREE_D_SECURE_VERIFICATION_FAILED_5.01"),
    5.02: t("INVALID_CARD_TOKEN_5.02"),
    5.03: t("APPLICATION_ERROR_5.03"),
    5.04: t("MERCHANT_NOT_CONFIGURED_CORRECTLY_5.04"),
    5.08: t("INVALID_TRANSACTION_5.08"),
    6.02: t("CONNECTION_ERROR_6.02"),
    7.01: t("CARD_TOKEN_NOT_FOUND_7.01"),
  };

  const handleSubscriptionCreate = async (result) => {
    try {
      setIsLoading(true);
      const apiPayload = {
        email: data.email.toLowerCase(),
        country: userData.country,
        card_token: result?.firstTransaction?.card_token?.token,
        ipAddress: ip,
        // secondTrialSentAmount: trialProduct?.sent_amount,
      };
      const subscriptionResult = await disPatch(
        solidGateSubscriptionCreate(apiPayload)
      );
      if (subscriptionResult.type === "solidGateSubscriptionCreate/fulfilled") {
        const { role, email, token } = subscriptionResult.payload.data || {};
        Notify("success", t("NOTIFY_STANDARD_SUBSCRIPTION"), "");
        if (isTrackPage()) {
          if (
            params &&
            typeof params === "object" &&
            !Object.entries(params)
              .map(([key]) => {
                if (key === "off") {
                  return "off";
                }
              })
              .includes("off")
          ) {
            cookie.set("banned", "true");
            const data = {
              ip_address: ip,
              status: false,
            };
            await disPatch(settingBanIp(data));
          }
        }
        if (gcLid && gcLid.length > 0 && gcLid[0].key && gcLid[0].value) {
          const gcLidData = {
            email: data.email,
            key: gcLid[0].key,
            value: gcLid[0].value,
            signup: "on",
            ip_address: ip,
          };
          disPatch(savegclid(gcLidData));
        }
        cookie.set("token", token, { path: "/" });
        cookie.set("role", role);
        cookie.set("email", email);
        if (senderNumberCountry && senderNumber) {
          navigate(
            `/${lang}/access-report?D=+${senderNumberCountry.replaceAll(
              " ",
              ""
            )}&n=${senderNumber}`
          );
        } else {
          navigate(`/${lang}/dashboard`);
        }
      } else {
        oncloseCheckout();
        Notify("error", subscriptionResult.error.message, "");
      }
    } catch (error) {
      console.log("error on create subscription", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePaymentFail = async (e) => {
    try {
      console.log("handlePaymentFail", e);
      setIsLoading(true);
      const intentResponse = await disPatch(
        solidGateIntentCreate(intentCreateData)
      );
      if (intentResponse.type === "solidGateIntentCreate/fulfilled") {
        const { paymentIntent, merchant, signature } =
          intentResponse.payload.data || {};
        setMerchantData({
          merchant: merchant || "",
          paymentIntent: paymentIntent || "",
          signature: signature || "",
        });
      }
      const error = isSupportedLang(lang)
        ? ErrorMessage[e.code] || e.message
        : e.message;
      setErrorMessage(error || t("PR_SOMETHING_WENT_WRONG"));
    } catch (error) {
      console.error("Error in handleOnFail:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePaymentStatus = async (e) => {
    console.log("handlePaymentStatus", e);
    if (e.response?.order?.status === "auth_ok") {
      const { order, transactions } = e.response;
      const firstTransaction = Object.values(transactions)?.[0] || {};
      const { id: transactionId, card } = firstTransaction || {};
      const { order_id, currency } = order || {};
      // FE Events
      const commonEvent = {
        transaction_id: order_id,
        value: trialProduct?.amount,
        currency: currency?.toUpperCase(),
        userData,
      };
      trackEvent("tracking_trial", commonEvent);
      trackEvent("zaraz_tracking_trial", commonEvent);
      trackEvent("tracking_trial_conversion", commonEvent);

      await handleSubscriptionCreate({
        transactionId,
        card,
        firstTransaction,
        order,
        transactions,
      });
    } else {
      console.log("handlePaymentStatus", e);
    }
  };

  const handleFormMounted = useCallback((event) => {
    setIsMounted(true);
    setIsGooglePayAvailable(event?.entity === "googlebtn");
    setIsApplePayAvailable(event?.entity === "applebtn");
  }, []);

  const handleOnReadyPaymentInstance = useCallback((form) => {
    setForm(form);
  }, []);

  return (
    <div className="body-modal">
      <div className="payment-details payment_modal_area">
        <div className="df-details">
          <div className="left-payment">
            <p>{t("SIGNUP_PAYMENT_TITLE")}</p>
          </div>
          <div className="right-payment pay">
            <p>
              {subscriptionProduct.symbol + subscriptionProduct.amount} /{" "}
              {t("PR_MONTH")}
            </p>
          </div>
        </div>
        {isTrackPage() && (
          <div className="time-span">
            <span>
              {isTrackPage()
                ? t("TRIAL_TIME.B")
                : t("TRIAL_TIME", {
                    amount:
                      subscriptionProduct.symbol + subscriptionProduct.amount,
                  })}
            </span>
          </div>
        )}
      </div>
      <div style={{ display: isLoading && "none" }}>
        <SolidGate
          merchantData={merchantData}
          showApplePay={true}
          showGooglePay={true}
          onPaymentFail={handlePaymentFail}
          onPaymentSuccess={(e) => {
            setIsLoading(true);
            console.log("onPaymentSuccess", e);
          }}
          onSubmitForm={() => {
            setErrorMessage("");
            setIsLoading(true);
          }}
          onGetCardDetails={(details) => setCardType(details?.card?.brand)}
          handlePaymentStatus={handlePaymentStatus}
          onFormMounted={handleFormMounted}
          onReadyPaymentInstance={handleOnReadyPaymentInstance}
        />
      </div>
      {isLoading && (
        <DummyLayout
          isGooglePayAvailable={isGooglePayAvailable}
          isApplePayAvailable={isApplePayAvailable}
          cardType={cardType}
        />
      )}
      {errorMessage && (
        <div id="payment-message" style={{ color: "red" }}>
          {errorMessage}
        </div>
      )}
      {isMounted &&
        <button
          id="submit"
          className="hl_cta_wrap mt-4"
          type="submit"
          style={{ width: "100%" }}
          onClick={() => {
            form?.submit();
          }}
        >
          <span id="button-text">{t("SIGNUP_SUBMIT")}</span>
        </button>
      }
      {!isTrackPage() && (
        <span id="sign-up-term">{t("PR_CHECKOUT_DESCLAIMER")}.</span>
      )}
    </div>
  );
};

export default CheckoutComponent;
