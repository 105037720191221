import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import AxiosInstance from "../../../../utils/axios";
export const solidGateUnsubscribe = createAsyncThunk(
  "solidGateUnsubscribe",
  async (data) => {
    try {
      const response = await AxiosInstance.post(
        `/subscription/solidgate/unsubscribe`,
        data
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const solidGatUnsubscribeSlice = createSlice({
  name: "solidGateUnsubscribe",
  initialState: {
    isLoading: false,
    solidGateUnsubscribeData: null,
    isError: false,
    errorMessage: ""
  },
  extraReducers: {
    [solidGateUnsubscribe.pending]: (state) => {
      state.isLoading = true;
    },
    [solidGateUnsubscribe.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.solidGateUnsubscribeData = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [solidGateUnsubscribe.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    }
  }
});

export default solidGatUnsubscribeSlice.reducer;
