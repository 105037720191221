import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUserId } from "../../../utils/commonUtils";
import AxiosInstance from "../../../../utils/axios";
import { trackEvent } from "../../../../utils/eventTracker";

export const reportSubscriptionCreate = createAsyncThunk(
  "reportSubscriptionCreate",
  async (data) => {
    try {
      // Check if Admin Login with User Then include userId in query string
      const userId = getUserId();
      const url = userId
        ? `/report_subscription/solidgate/phone_report/create?userId=${userId}`
        : `/report_subscription/solidgate/phone_report/create`;
      const response = await AxiosInstance.post(url, data);
      console.log("response", response);
      /*  event parameters start */
      const { currency, email, user_id } =
        response?.data?.data?.orderInfo || {};

      const commonEvent = {
        transaction_id: "",
        value: "0",
        currency: currency?.toUpperCase(),
        email: email,
        user_id: user_id,
      };
      if (response.data.data.orderInfo) {
        trackEvent("report_trial", commonEvent);
        trackEvent("zaraz_report_trial", commonEvent);
        trackEvent("report_trial_conversion", commonEvent);
      }
      /*  event parameters end */
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const reportSubscriptionCreateSlice = createSlice({
  name: "reportSubscriptionCreate",
  initialState: {
    isLoading: false,
    reportSubscription: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [reportSubscriptionCreate.pending]: (state) => {
      state.isLoading = true;
    },
    [reportSubscriptionCreate.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.reportSubscription = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [reportSubscriptionCreate.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },
  },
});

export default reportSubscriptionCreateSlice.reducer;
