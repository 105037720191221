import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import paymentDeclineIcon from "../../../../../assets/home/payment_decline_icon.svg";
import { useTranslation } from "react-i18next";
import ModalComponent from "../../../common/Modal/modal";
import "./pastDue.scss";
import PaymentModalComponent from "./paymentModal";
import { uppdatePaymentMethod } from "../../../../redux/slice/solidGate/uppdatePaymentMethodSlice";
import Notify from "../../../../../components/common/Notify/notify";
import { cancelSubscription } from "../../../../redux/slice/cancelSubscriptionSlice";
import { Spin } from "antd";
import { subscriptionList } from "../../../../redux/slice/solidGate/subscriptionsListSlice";
import {is3Ds} from "../../../../utils/commonUtils";

const PastDueModal = (props) => {
  const {
    isOpen,
    onClose,
    subscriptionName,
    callCancelSubscription,
    callSubscriptionList,
    subscriptionData,
    subscriptionListStatus
  } = props;
  const { t } = useTranslation();
  const disPatch = useDispatch();

  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [merchantData, setMerchantData] = useState({
    merchant: "",
    paymentIntent: "",
    signature: "",
  });

  const handleReactive = async () => {
    const intentData = {
      type: "create_intent",
      sub_name: subscriptionName,
      force3ds: is3Ds()
    };
    const intentResult = await disPatch(uppdatePaymentMethod(intentData));
    if (intentResult.type === "uppdatePaymentMethod/fulfilled") {
      // setIntentCreateData(intentData);
      const { paymentIntent, merchant, signature } =
      intentResult.payload.data || {};
      setMerchantData({
        merchant: merchant || "",
        paymentIntent: paymentIntent || "",
        signature: signature || "",
      });
      setStep(2);
    }else {
      console.error(intentResult.error.message)
    }
    
  };
  const [cardType, setCardType] = useState("");
  const handlePaymentFail = async () => {
    try {
      setIsLoading(true);
      const intentData = {
        type: "create_intent",
        sub_name: subscriptionName,
      };
      const intentResponse = await disPatch(uppdatePaymentMethod(intentData));
      if (intentResponse.type === "uppdatePaymentMethod/fulfilled") {
        const { paymentIntent, merchant, signature } =
          intentResponse.payload.data || {};
        setMerchantData({
          merchant: merchant || "",
          paymentIntent: paymentIntent || "",
          signature: signature || "",
        });
      }
    } catch (error) {
      console.error("Error in handleOnFail:", error);
    } finally {
      setIsLoading(false);
    }
  };
  const handlePaymentStatus = async (e) => {
    console.log("handlePaymentStatus", e);
    if (e.response?.order?.status === "auth_ok") {
      console.log(e.response);
      const { order } = e.response;
      const updatePaymentPayload = {
        type: "update_payment_method",
        sub_name: subscriptionName,
        order_id: order.order_id,
      };
      try {
        const result = await disPatch(uppdatePaymentMethod(updatePaymentPayload));
        if (result.type === "uppdatePaymentMethod/fulfilled") {
          console.log(result,"Result of last API")
          Notify('success', result.payload.message, "")
          await callSubscriptionList();
          onClose();
        }
      } catch (error) {
        console.log("Error", error);
      } finally {
        setIsLoading(false);
      }
      
    } else {
      console.log("handlePaymentStatus", e);
    }
  };
  // Cancel Subscription Flow
  const handleCancelSubscription = async () => {
    setIsLoading(true);
    try {
      const res = await callCancelSubscription(subscriptionData?.payment_gateway_id);
      if (res.type === "cancelSubscription/fulfilled") {
        Notify("success", res.payload.message, "");
        await callSubscriptionList();
        onClose();
      } else {
        Notify("error", res.error.message, "");
      }
    } catch (error) {
      console.warn("Error occurred on cancel subscription", error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <ModalComponent
        isOpen={isOpen}
        closable={false}
        maskClosable={false}
        onClose={() => onClose()}
        className={`${
          step === 1 ? "update__payment_wrap" : "reactivate__payment_wrap"
        } `}
      >
        <div className="past_due_modal">
          {step === 1 ? (
            <div className="upc__payment_update">
              <div className="upc__payment_icon">
                <img src={paymentDeclineIcon} alt="" />
              </div>
              <div className="upc__payment_content">
                <h4>{t("REACTIVATE_ACCOUNT")}</h4>
                <p>{t("REACTIVATE_ACCOUNT_DESC")}</p>
                <div
                  className="upc__update_btn"
                  onClick={() => handleReactive()}
                >
                  <button>{t("UPDATE_PAYMENT_METHOD")}</button>
                </div>
              </div>
              <div className="upc__cancel_info">
                <div className="upc__cancel_content">
                  <p>{t("CANCEL_SUBSCRIPTION_DESC")}</p>
                  <div
                    className="upc__cancel_btn"
                    onClick={() => setConfirmModal(true)}
                  >
                    <button>{t("CANCEL_SUBSCRIPTION")}</button>
                  </div>
                </div>
              </div>
            </div>
          ) : step === 2 ? (
            <Spin spinning={isLoading || subscriptionListStatus.isLoading}>
              <PaymentModalComponent
                merchantData={merchantData}
                handlePaymentFail={handlePaymentFail}
                setCardType={setCardType}
                handlePaymentStatus={handlePaymentStatus}
                isLoading={isLoading || subscriptionListStatus.isLoading}
                cardType={cardType}
                setIsLoading={setIsLoading}
              />
            </Spin>
          ) : (
            ""
          )}
        </div>
      </ModalComponent>

      {/* Confirmation Modal for Cancel Subscription */}

      <ModalComponent
        isOpen={confirmModal}
        onClose={() => setConfirmModal(false)}
        closable={false}
        className="tracelo_unsubscribe-modal reactivate__subscription__wrap"
      >
        <div className="login-modal-section">
          <div className="unsubscribe_title">
            <h2>
              {t("PR_CANCEL")} {subscriptionData.name}{" "}
            </h2>
          </div>
          <div className="unsubscribe-modal-body">
            <div
              className="unsubscribe-text"
              dangerouslySetInnerHTML={{
                __html: t("PR_CANCEL_CONFIRMATION_TEXT", {
                  subscription_name: subscriptionData.name,
                }),
              }}
            ></div>
            <div className="submit-btn-modal">
              <button
                onClick={() => {
                  setConfirmModal(false);
                  handleCancelSubscription();
                }}
              >
                {t("PR_SETTING_UNSUBSCRIBE_CONFIRM")}
              </button>
            </div>
            <div className="unsubscribe_ButtonWrapper">
              <button
                className="unsubscribe_ClrButton"
                onClick={() => setConfirmModal(false)}
              >
                {t("PR_SETTING_UNSUBSCRIBE_CANCEL")}
              </button>
            </div>
          </div>
        </div>
      </ModalComponent>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    subscriptionListStatus: state.subscriptionList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    callSubscriptionList: (data) => dispatch(subscriptionList(data)),
    callCancelSubscription: (data) => dispatch(cancelSubscription(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PastDueModal);
