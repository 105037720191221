import {
  subExpiredIcon,
  unlockReportsIcon,
} from "../../../../assets/images";
import React, { useContext, useState } from "react";
import { connect } from "react-redux";
import TransactionDeclinedModal from "../../../../pages/transactionDeclined/transactionDeclined";
import ModalComponent from "../../../common/Modal/modal";
import Loader from "../../../common/loader/loader";
import { openReport } from "../../../../redux/slice/openReportSlice";
import { subscriptionStatus } from "../../../../redux/slice/subscriptionStatusSlice";
import {getPhoneReportDetails} from "../../../../redux/slice";
import Notify from "../../../../../components/common/Notify/notify";
import "./multiActionModal.scss";
import {t} from "i18next";
import PhoneNumberAttributeDetails from "../phoneNumberAttributeDetails/phoneNumberAttributeDetails";
import {ProcessModalContext} from "../../../../context/processModalProvider";
import {additionalInfoStatus} from "../../../../redux/slice/additionalInfoStatusSlice";
import moment from "moment-timezone";
import {CONSTANTS} from "../../../../utils/constant";
import AddMoreReport from "./addMoreReport";
import {useUserIp} from "../../../../../utils/commonUtils";
import {addMoreReport} from "../../../../redux/slice/solidGate/addMoreReportSlice";
import {reactivateSubscription} from "../../../../redux/slice/solidGate/reactivateSubscriptionSlice";
import {is3Ds} from "../../../../utils/commonUtils";
import { formatNumberByCountry } from "../../../../../utils/commonUtils";

const MultiActionModal = (props) => {
  const {
    modalType,
    actionModal,
    setActionModal,
    phoneReportDetails,
    isLoading,
    setIsLoading,
    subscription,
    isRedirection = false,
    //API
    callOpenReport,
    callGetPhoneReportDetails,
    pricingTitleList,
    callAdditionalInfoStatus,
    subscriptionStatus,
    callAddMoreReport,
    callReactivateSubscription
  } = props;
  const [retryModal, setRetryModal] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  const [cardLoadIntent, setCardLoadIntent] = useState(null);

  const {phoneReport} = phoneReportDetails || {};
  const userIp = useUserIp();
  const {openProcessModal} = useContext(ProcessModalContext);
  const {phone_number, phone_number_prefix} =
    phoneReportDetails?.phoneReport || {};
  const formatedNumber = phone_number_prefix + phone_number;

  const price =
    pricingTitleList?.subscriptions?.[CONSTANTS.SUBSCRIPTIONS.PREMIUM_REPORT]
      ?.amount || null;
  const priceSymbol =
    pricingTitleList?.subscriptions?.[CONSTANTS.SUBSCRIPTIONS.PREMIUM_REPORT]
      ?.symbol || "$";

  const exdDateFromSubs =
    subscriptionStatus.subscriptionStatus &&
    subscriptionStatus.subscriptionStatus?.data?.endDate
      ? subscriptionStatus.subscriptionStatus?.data?.endDate
      : subscriptionStatus.subscriptionStatus?.data?.trial_end_date;
  const endDate = moment(
    subscriptionStatus.subscriptionStatus && exdDateFromSubs
  ).format("MMMM DD, YYYY");

  const retryPrice =
    subscription === CONSTANTS.SUBSCRIPTIONS.TRACKING ||
    subscription === CONSTANTS.SUBSCRIPTIONS.PHONE_REPORT
      ? subscriptionStatus?.subscriptionStatus?.data?.amount
      : price;

  const handleOpenReport = async () => {
    setActionModal(false);
    setIsLoading(true);
    try {
      const data = {
        phone_report_id: phoneReport?._id
      };
      const res = await callOpenReport(data);
      if (res.type === "openReport/fulfilled") {
        if (res.payload.data?.eventObj?.events) {
          openProcessModal(phoneReport._id);
        } else {
          Notify("success", res.payload.message, "");
          await callGetPhoneReportDetails(phoneReport._id);
        }
      } else {
        Notify("error", res.error.message, "");
      }
    } catch (error) {
      Notify("error", error.message || "An error occurred", "");
    } finally {
      setIsLoading(false);
    }
  };

  const openReport = async () => {
    const isAnyFieldEmpty = Object.values(
      phoneReport?.additional_details || {}
    ).some((value) => !value?.trim());
    if (isAnyFieldEmpty) {
      setActionModal(false);
      let payload = {
        phone_report_id: phoneReport?._id,
        apiPayload: {
          phone_report_id: phoneReport?._id
        },
        apiName: "open_report"
      };
      callAdditionalInfoStatus(payload);
      setInfoModal(true);
    } else {
      await handleOpenReport();
    }
  };
  // handleFlow

  const handleFlow = async () => {
    try {
      setIsLoading(true);
      setActionModal(false);
      const newPayload = {
        phone_report_id: phoneReport?._id,
        ip_address: userIp,
        type: "create",
        subscription_name:
          modalType === "renew_subscription" ? subscription : undefined,
        force3ds: is3Ds(), // For check 3ds scenarios
        redirect_url:
          modalType === "renew_subscription" ||
          modalType === "more_report_subscription"
            ? "access-report"
            : undefined
      };
      const res =
        modalType === "more_report_subscription"
          ? await callAddMoreReport(newPayload)
          : await callReactivateSubscription(newPayload);
      if (res?.type?.endsWith("fulfilled")) {
        if (res.payload.data.required_3ds) {
          const verifyUrl = res.payload.data.verify_url;
          window.location.href = verifyUrl;
        } else if (res.payload.status === false) {
          setCardLoadIntent(res.payload.data.intent);
          setRetryModal(true);
        } else {
          // For redirect user to premium page while they activate their phone report subscription
          if (isRedirection) {
            isRedirection(true);
          }
          Notify("success", res.payload.message, "");
        }
      } else {
        Notify("error", res?.error?.message, "");
      }
    } catch (error) {
      console.error("Error occurred from handleFrom", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Handled retry payment request
  const onRetry = async (orderId) => {
    try {
      setIsLoading(true);
      setActionModal(false);
      setRetryModal(false);
      const newPayload = {
        phone_report_id: phoneReport?._id,
        ip_address: userIp,
        type: "transaction_decline",
        order_id: orderId,
        subscription_name:
          modalType === "renew_subscription" ? subscription : undefined
      };
      const res =
        modalType === "more_report_subscription"
          ? await callAddMoreReport(newPayload)
          : await callReactivateSubscription(newPayload);
      if (res?.type?.endsWith("fulfilled")) {
        // For redirect user to premium page while they activate their phone report subscription
        if (isRedirection) {
          isRedirection(true);
        }
        Notify("success", res.payload.message, "");
      } else {
        Notify("error", res?.error?.message, "");
      }
    } catch (error) {
      console.error("Error occurred from retry", error);
    } finally {
      setIsLoading(false);
    }
  };

  const orderDetails = {
    orderName:
      subscription === CONSTANTS.SUBSCRIPTIONS.TRACKING
        ? t("PR_REPORT_PHONE_TRACKING")
        : subscription === CONSTANTS.SUBSCRIPTIONS.PHONE_REPORT
        ? t("PR_PHONE_REPORT")
        : subscription === CONSTANTS.SUBSCRIPTIONS.PREMIUM_REPORT
        ? t("PR_PRICING_PREMIUM_REPORTS")
        : null,
    orderPrice: priceSymbol + retryPrice,
    orderType: "subscription",
    orderTypeName: subscription || null
  };
  return (
    <>
      {isLoading && <Loader />}
      <ModalComponent
        isOpen={actionModal}
        onClose={() => setActionModal(false)}
        closable={modalType === "more_report_subscription"}
        className={
          modalType === "renew_subscription"
            ? "wpb_expired__wrapper"
            : modalType === "open_report"
            ? "wpb_unlock__one_wrapper"
            : modalType === "more_report_subscription"
            ? "wpb_buy__reports_wrapper"
            : ""
        }
      >
        {modalType === "renew_subscription" ? (
          <div className="wpb_report__wrapper">
            <div className="vc_icon__general">
              <img src={subExpiredIcon} alt="" />
            </div>
            <div className="vc_content__general">
              <h2
                dangerouslySetInnerHTML={{
                  __html: t("PR_PHONE_TRACK_SUB_EXPIRED", {
                    subscription:
                      subscription === CONSTANTS.SUBSCRIPTIONS.TRACKING
                        ? t("PR_PHONE_TRACKING")
                        : subscription ===
                          CONSTANTS.SUBSCRIPTIONS.PREMIUM_REPORT
                        ? t("PR_PRICING_PREMIUM_REPORTS")
                        : t("PR_PHONE_REPORT")
                  })
                }}
              ></h2>
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    t("PR_RENEW_SUBSCRIPTION", {
                      date: endDate,
                      subscription:
                        subscription === CONSTANTS.SUBSCRIPTIONS.TRACKING
                          ? t("PR_PHONE_TRACKING")
                          : subscription ===
                            CONSTANTS.SUBSCRIPTIONS.PREMIUM_REPORT
                          ? t("PR_PRICING_PREMIUM_REPORTS")
                          : t("PR_PHONE_REPORT")
                    }) + "."
                }}
              ></p>
            </div>
            <div className="vc_button__general">
              <button className="vc_btn3-inline" onClick={handleFlow}>
                {t("PR_RENEW_SUB_1")}
              </button>
            </div>
            <div className="vc_info__general">
              <p>
                {t("PR_MEMBERSHIP_RENEW", {
                  price: subscriptionStatus.subscriptionStatus
                    ? priceSymbol +
                      subscriptionStatus.subscriptionStatus?.data?.amount
                    : "$XX.XX"
                })}
              </p>
            </div>
          </div>
        ) : modalType === "more_report_subscription" ? (
          <AddMoreReport
            handleFlow={handleFlow}
            price={`${priceSymbol}${price}`}
          />
        ) : modalType === "open_report" ? (
          <div className="wpb_report__wrapper">
            <div className="vc_icon__general">
              <img src={unlockReportsIcon} alt="" />
            </div>
            <div className="vc_content__general">
              <h2>{t("PR_UNLOCK_REPORT_CONFIRMATION")}</h2>
              <p>
                {t("PR_UNLOCK_CAUTION", {
                  phone_number: formatNumberByCountry(formatedNumber)
                })}
              </p>
            </div>
            <div className="vc_button__general">
              <button className="vc_btn3-inline" onClick={openReport}>
                {t("PR_UNLOCK_REPORT")}
              </button>
            </div>
          </div>
        ) : null}
      </ModalComponent>
      <TransactionDeclinedModal
        isOpen={retryModal}
        // eslint-disable-next-line
        onClose={() => setRetryModal(false)}
        closable={false}
        handleSubmit={onRetry}
        orderDetails={orderDetails}
        cardLoadIntent={cardLoadIntent}
        setCardLoadIntent={setCardLoadIntent}
      />
      {infoModal && (
        <PhoneNumberAttributeDetails
          isOpen={infoModal}
          onClose={() => {
            setInfoModal(false);
          }}
          handlePayment={handleOpenReport}
          setIsLoading={setIsLoading}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    subscriptionStatus: state.subscriptionStatus,
    phoneReportDetails:
      state.getPhoneReportDetails?.getPhoneReportDetailsData?.data,
    openReport: state.openReport,
    renewSubscription: state.renewSubscription,
    generatePayment: state.generatePayment,
    retryPayment: state.retryPayment,
    pricingTitleList: state.pricingTitleList?.priceTitleList?.data
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    callOpenReport: (data) => dispatch(openReport(data)),
    callCheckSubscriptionStatus: (data) => dispatch(subscriptionStatus(data)),
    callGetPhoneReportDetails: (data) => dispatch(getPhoneReportDetails(data)),
    callAdditionalInfoStatus: (data) => dispatch(additionalInfoStatus(data)),
    callAddMoreReport: (data) => dispatch(addMoreReport(data)),
    callReactivateSubscription: (data) => dispatch(reactivateSubscription(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MultiActionModal);
