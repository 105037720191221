import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../../utils/axios";
import { getUserId } from "../../utils/commonUtils";

export const uploadPdf = createAsyncThunk("uploadPdf", async (data) => {
  try {
    // Check if Admin Login with User Then include userId in query string
    const userId = getUserId();
    const url = userId
      ? `/phone_report/upload_pdf?userId=${userId}`
      : `/phone_report/upload_pdf`;
    const response = await AxiosInstance.post(url, data);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw error;
    }
  }
});

const uploadPdfSlice = createSlice({
  name: "uploadPdf",
  initialState: {
    isLoading: false,
    uploadPdf: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [uploadPdf.pending]: (state) => {
      state.isLoading = true;
    },
    [uploadPdf.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.uploadPdf = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [uploadPdf.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },
  },
});

export default uploadPdfSlice.reducer;
