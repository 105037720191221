import React, { useEffect, useRef, useState } from "react";
import { Layout, Input, Dropdown, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import {
  setPage,
  setSearchValue,
  userList,
} from "../../../../phoneReport/redux/slice/admin/getUserListSlice";
import { newHeaderLogo } from "../../../../phoneReport/assets/images";
import "./header.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Cookies} from "react-cookie";
import { ReactComponent as AdminDashBoardIcon } from "../../../../phoneReport/assets/images/menu_dashboard_icon.svg";
// import { ReactComponent as UsersAdminIcon } from "../../../../phoneReport/assets/images/usersAdminIcon.svg";
// import { ReactComponent as AdminIcon } from "../../../../phoneReport/assets/images/adminIcon.svg";
// import { ReactComponent as CurrenciesAdminIcon } from "../../../../phoneReport/assets/images/currenciesAdminIcon.svg";
// import { ReactComponent as ConfigAdminIcon } from "../../../../phoneReport/assets/images/configAdminIcon.svg";
import { ReactComponent as EmailAdminIcon } from "../../../../phoneReport/assets/images/emailAdminIcon.svg";
const { Header } = Layout;

const AdminHeader = ({
  getUserList,
  setPage,
  setSearchValue,
  authenticate,
  pageSize,
  isLoading,
}) => {
  const cookie = new Cookies();
  const lang = cookie.get("lang");
  const location = useLocation();
  const navigate = useNavigate();

  const [localSearchValue, setLocalSearchValue] = useState("");
  const timeoutRef = useRef(null);

  const { first_name, last_name, email } =
    authenticate?.authenticateData?.data || {};
  const userName =
    first_name || last_name ? `${first_name || ""} ${last_name || ""}` : null;
  const items = [
    {
      key: "1",
      label: (
        <div
          className={`mobile_menu_option ${
            isActiveRoute("dashboard") && "active"
          }`}
          onClick={() => navigate(`${lang}/admin/dashboard/`)}
        >
          <span><AdminDashBoardIcon/> Dashboard</span>
        </div>
      ),
    },
    // {
    //   key: "2",
    //   label: (
    //     <div
    //       className={`mobile_menu_option ${
    //         isActiveRoute("ddmins") && "active"
    //       }`}
    //       onClick={() => navigate(`${lang}/admin/admins/`)}
    //     >
    //       <span><AdminIcon/>Admins</span>
    //     </div>
    //   ),
    // },
    // {
    //   key: "3",
    //   label: (
    //     <div
    //       className={`mobile_menu_option ${
    //         isActiveRoute("currencies") && "active"
    //       }`}
    //       onClick={() => navigate(`${lang}/admin/currencies/`)}
    //     >
    //       <span><CurrenciesAdminIcon/>Currencies</span>
    //     </div>
    //   ),
    // },
    // {
    //   key: "4",
    //   label: (
    //     <div
    //       className={`mobile_menu_option ${
    //         isActiveRoute("sms-config") && "active"
    //       }`}
    //       onClick={() => navigate(`${lang}/admin/sms-config/`)}
    //     >
    //       <span><ConfigAdminIcon/>SMS config</span>
    //     </div>
    //   ),
    // },
    {
      key: "5",
      label: (
        <div
          className={`mobile_menu_option ${
            isActiveRoute("email-form") && "active"
          }`}
          onClick={() => navigate(`${lang}/admin/email-form/`)}
        >
          <span><EmailAdminIcon/>Test email</span>
        </div>
      ),
    },
    {
      key: "6",
      label: (
        <div
          className="mobile_menu_option logout-btn"
          onClick={() => {
            handleLogout();
          }}
        >
          <Button type="primary" className="text-center">
            Logout
          </Button>
        </div>
      ),
    },
  ];
  const handleSearchChange = (e) => {
    const value = e?.target?.value;
    setLocalSearchValue(value);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      setSearchValue(value);
      getUserList({ page: 1, recordsPerPage: pageSize, searchValue: value });
      setPage(1);
    }, 300);
  };

  // Cleanup timeout on component unmount
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  function isActiveRoute(route) {
    const currentPath = location.pathname.split("/")[3];
    return currentPath.includes(route);
  }

  const handleLogout = () => {
    cookie.remove("token");
    cookie.remove("role");
    cookie.remove("step");
    cookie.remove("subscriptionInfoModal", { path: "/" });
    const cookieKeys = Array.isArray(cookie.getAll())
      ? cookie.getAll()
      : Object.keys(cookie.getAll());
    cookieKeys.forEach((key) => {
      if (!["currency", "lang", "langlogo", "banned"].includes(key)) {
        cookie.remove(key, { path: "/" });
      }
    });
    window.location.href = "/";
  };

  return (
    <div className="header-wrapper admin__header">
      <div className="main_container wpb__admin_container">
        <Header className="main-header">
          <div className="logo-container">
            <Link to={`/${lang}/admin/dashboard`}>
              <img src={newHeaderLogo} alt="Logo" width={200} height={45} />
            </Link>
          </div>
          <Input
            value={localSearchValue}
            placeholder="User's name / Email address"
            prefix={<SearchOutlined />}
            className="search-input admin__search_wrapper"
            onChange={handleSearchChange}
          />
          <div className="user-container">
            {!isLoading && (
              <div className="user-info">
                <span className="user-name" title={userName}>
                  {userName}
                </span>
                {email && <span className="user-email">{email}</span>}
              </div>
            )}
            <div className="admin-drawer">
              <Dropdown
                menu={{
                  items,
                }}
                placement={"bottomRight"}
                trigger={["click"]}
                overlayClassName={`dropdown-setting header__MenuDropDown admin-menu`}
              >
                <div>
                  <div
                    className="openbtn"
                    onClick={(e) => e.target.classList.toggle("active")}
                  >
                    <div className="openbtn-area">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </div>
              </Dropdown>
            </div>
          </div>
        </Header>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  searchValue: state.getUserList.searchValue,
  pageSize: state.getUserList.pageSize,
  authenticate: state.authenticate,
  isLoading: state.authenticate.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  getUserList: ({ page, recordsPerPage, searchValue }) =>
    dispatch(userList({ page, recordsPerPage, searchValue })),
  setPage: (page) => dispatch(setPage(page)),
  setSearchValue: (value) => dispatch(setSearchValue(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminHeader);
