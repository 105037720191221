import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../../utils/axios";
import { getUserId } from "../../utils/commonUtils";

export const generatePayment = createAsyncThunk(
  "generatePayment",
  async (data) => {
    try {
      // Check if Admin Login with User Then include userId in query string
      const userId = getUserId();
      const url = userId
        ? `/report_subscription/generate_payment?userId=${userId}`
        : `/report_subscription/generate_payment`;
      const response = await AxiosInstance.post(url, data);
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const generatePaymentSlice = createSlice({
  name: "generatePayment",
  initialState: {
    isLoading: false,
    generatePayment: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [generatePayment.pending]: (state) => {
      state.isLoading = true;
    },
    [generatePayment.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.generatePayment = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [generatePayment.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },
  },
});

export default generatePaymentSlice.reducer;
