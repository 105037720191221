import React from "react";
import { PrevArrow } from "../../../assets/images";

const PrevArrowComponent = (props) => {
  const { onClick, icon } = props;
  return (
    // eslint-disable-next-line
    <a className="slick-arrow slick-prev outside" onClick={onClick}>
      {icon || <img src={PrevArrow} width={40} height={40} alt="Prev Arrow" />}
    </a>
  );
};

export default PrevArrowComponent;
