import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import AxiosInstance from "../../../../utils/axios";
export const addMoreReport = createAsyncThunk("addMoreReport", async (data) => {
  try {
    const response = await AxiosInstance.post(
      `/report_subscription/solidgate/premium_report/add_more_report`,
      data
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw error;
    }
  }
});

const addMoreReportSlice = createSlice({
  name: "addMoreReport",
  initialState: {
    isLoading: false,
    addMoreReport: null,
    isError: false,
    errorMessage: ""
  },
  extraReducers: {
    [addMoreReport.pending]: (state) => {
      state.isLoading = true;
    },
    [addMoreReport.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.addMoreReport = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [addMoreReport.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    }
  }
});

export default addMoreReportSlice.reducer;
