import React from "react";
import ModalComponent from "../../../common/Modal/modal";
import "./locatingModal.scss";
import PhoneInput from "react-phone-input-2";
import { Form, Input } from "antd";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { sendLocation } from "../../../../redux/slice";
import { createFirstReport } from "../../../../redux/slice/createFirstReportSlice";
import PreviewPopup from "../../../../pages/previewPopup/previewPopup";
import { formatNumberByCountry } from "../../../../../utils/commonUtils";

const LocatingModalComponent = ({
  className,
  isOpen,
  onClose,
  setIsOpen,
  countryShortName,
  formatedNumber,
  handleOnReceiverNumberChange,
  handleOnReceivercountry,
  popNumberError,
  receiverError,
  handleChangeMessage,
  message,
  messageError,
  handleSendMessage,
  handlePreviewNavigate,
  setIsPreviewOpen,
  isPreviewOpen,
  inputRef,
  finalFormattedNumber,
  countryCodeinput,
  buttonDisabled,
}) => {
  const { t } = useTranslation();
  const modalClassName = `locating_popup__wrap ${className}`;
  return (
    <>
      <ModalComponent
        isOpen={isOpen}
        onClose={onClose}
        closable={true}
        className={modalClassName}
      >
        <div className="login-modal-section send_message_Modal">
          <div
            className="login-modal-title"
            style={{ fontSize: 20, display: "flex", flexDirection: "column" }}
          >
            <span className="span">{t("LOCATING")}</span>
            <p>{formatNumberByCountry(formatedNumber)}</p>
          </div>
          <div className="wrap-top-modal">
            <div className="body-dash-modal">
              <div className="dashboard-find-number dashboard-box">
                <label className="mb-1">
                  <span className="dot"></span>
                  <span>{t("RECEIVE_NUMBER_TEXT")}</span>
                </label>
                <div className="dashboard-locate">
                  <PhoneInput
                    className="dsh_locating_popup"
                    country={
                      countryCodeinput ? countryCodeinput.toLowerCase() : "ca"
                    }
                    onChange={handleOnReceivercountry}
                    countryCodeEditable={false}
                    inputProps={{
                      name: "phone",
                      className: "form-control",
                      id: "receiver_number_input",
                      disabled: true,
                    }}
                    autoFormat={true}
                    enableSearch={true}
                    searchStyle={{
                      width: "100%",
                      height: "35px",
                      borderRadius: "7px",
                      margin: "0px",
                      padding: "0px; !important",
                    }}
                    disableSearchIcon={true}
                    searchPlaceholder={t("SEARCH")}
                    searchNotFound={t("NO_MATCHED")}
                  />
                  <Input
                    ref={inputRef}
                    className={`desktop-input-nd desk form_input_phone_number`}
                    onChange={(event) => {
                      handleOnReceiverNumberChange(event);
                    }}
                    placeholder={t("RECEIVE_NUMBER_TEXT")}
                    maxLength="20"
                    type="text"
                    inputMode="tel"
                    value={finalFormattedNumber}
                    id="phone_input"
                    onPressEnter={handleSendMessage}
                  />
                  {popNumberError && (
                    <p style={{ margin: " 0", color: "red" }}>
                      {popNumberError}
                    </p>
                  )}
                  {receiverError && (
                    <p style={{ margin: " 0", color: "red" }}>
                      {receiverError}
                    </p>
                  )}
                </div>
              </div>
              <Form>
                <div className="dashboard-message dashboard-box">
                  <label className="mb-1">
                    <span className="dot"></span>
                    <span>{t("MESSAGE")}</span>
                  </label>
                  <div className="dashboard-locate">
                    <textarea
                      defaultValue={t("SMS_DEFAULT_TEXT")}
                      placeholder={t("SMS_DEFAULT_TEXT")}
                      onChange={handleChangeMessage}
                      value={message}
                      maxLength={250}
                    />
                    {messageError && (
                      <p style={{ color: "red", margin: 0 }}>{messageError}</p>
                    )}
                  </div>
                </div>
                <div className="submit-btn-modal phone-report-send-message">
                  <button
                    onClick={handleSendMessage}
                    className="hl_cta_wrap"
                    type="submit"
                    disabled={buttonDisabled} // Disable button if isButtonDisabled or API call in progress
                  >
                    {t("SENT_MESSAGE_CONTINUE")}
                  </button>
                  <button
                    onClick={handlePreviewNavigate}
                    className="hl_clr_btn"
                    type="submit"
                    disabled={buttonDisabled}
                  >
                    {t("PR_PREVIEW")}
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </ModalComponent>
      <PreviewPopup
        setIsOpen={setIsPreviewOpen}
        countryShortName={countryShortName}
        sendLocation={sendLocation}
        className={"preview_modal_full_width preview_modal "}
        isOpen={isPreviewOpen}
        handleSendMessage={handleSendMessage}
        message={message}
        formatedNumber={formatedNumber}
        popNumberError={popNumberError}
        setLocatingModalOpen={setIsOpen}
        buttonDisabled={buttonDisabled}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    callSendLocation: (data) => dispatch(sendLocation(data)),
    callCreateFirstReport: (data) => dispatch(createFirstReport(data)),
  };
};

export default connect(null, mapDispatchToProps)(LocatingModalComponent);
