import React, { useState } from "react";
import { connect } from "react-redux";
import TransactionDeclinedModal from "../../../../pages/transactionDeclined/transactionDeclined";
import ModalComponent from "../../../common/Modal/modal";
import Loader from "../../../common/loader/loader";
import { subscriptionStatus } from "../../../../redux/slice/subscriptionStatusSlice";
import Notify from "../../../../../components/common/Notify/notify";
import { generatePdfBlob, is3Ds } from "../../../../utils/commonUtils";
import "./reportMultiactionModal.scss";
import { uploadPdf } from "../../../../redux/slice/uploadPdfSlice";
import { downloadPdfIcon } from "../../../../assets/images";
import { t } from "i18next";
import moment from "moment-timezone";
import DownLoadingAnimation from "../../../../lottieAnimations/DownloadingLottieAnimation";
import { reactivateSubscription } from "../../../../redux/slice/solidGate/reactivateSubscriptionSlice";
import { useUserIp } from "../../../../../utils/commonUtils";

const ReportMultiActionModal = (props) => {
  const {
    pdfModalType,
    pdfActionModal,
    setPdfActionModal,
    isLoading,
    setIsLoading,
    pricingData,

    //API
    phoneReportDetails,
    callUploadPdf,
    callReactivateSubscription
  } = props;
  const [retryModal, setRetryModal] = useState(false);
  const [isDownLoading, setIsDownLoading] = useState(false);
  const [cardLoadIntent, setCardLoadIntent] = useState(null);

  const { phoneReport } = phoneReportDetails || {};
  const userIp = useUserIp();

  const handleRenewSubscription = async () => {
    try {
      setIsLoading(true);
      setPdfActionModal(false);
      const newPayload = {
        phone_report_id: phoneReport?._id,
        ip_address: userIp,
        type: "create",
        subscription_name: "pdf_download",
        force3ds: is3Ds(), // For check 3ds scenarios
        redirect_url: "access-report"
      };
      const res = await callReactivateSubscription(newPayload);
      if (res?.type?.endsWith("fulfilled")) {
        if (res.payload.data.required_3ds) {
          const verifyUrl = res.payload.data.verify_url;
          window.location.href = verifyUrl;
        } else if (res.payload.status === false) {
          setCardLoadIntent(res.payload.data.intent);
          setRetryModal(true);
        } else {
          Notify("success", res.payload.message, "");
        }
      } else {
        Notify("error", res?.error?.message, "");
      }
    } catch (error) {
      console.log("error from handleRenewSubscription", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Handled retry payment request

  const onRetry = async (orderId) => {
    try {
      setRetryModal(false)
      setIsLoading(true);
      setPdfActionModal(false);
      const newPayload = {
        phone_report_id: phoneReport?._id,
        ip_address: userIp,
        type: "transaction_decline",
        order_id: orderId,
        subscription_name: "pdf_download"
      };
      const res = await callReactivateSubscription(newPayload);
      if (res?.type?.endsWith("fulfilled")) {
        Notify("success", res.payload.message, "");
      } else {
        Notify("error", res?.error?.message, "");
      }
    } catch (error) {
      console.log("Error from retry function from", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDownloadPdf = async () => {
    setIsDownLoading(true);
    setPdfActionModal(false);
    const pdfBlob = await generatePdfBlob("main_root");
    const formData = new FormData();
    formData.append("pdfFile", pdfBlob, "report.pdf");
    formData.append("phone_report_id", phoneReport?._id);
    const res = await callUploadPdf(formData);
    if (res.type === "uploadPdf/fulfilled") {
      Notify("success", res.payload.message, "");
      const { url } = res.payload.data || {};
      if (url) {
        window.location.href = url;
      }
    } else {
      Notify("error", res.error.message, "");
    }
    setIsDownLoading(false);
  };

  const orderDetails = {
    orderName: t("PR_PDF_DOWNLOAD"),
    orderPrice: pricingData?.symbol + pricingData.label_2,
    orderType: "subscription",
    orderTypeName: "pdf_download"
  };

  const exdDateFromSubs =
    subscriptionStatus.subscriptionStatus &&
    subscriptionStatus.subscriptionStatus?.data?.endDate
      ? subscriptionStatus.subscriptionStatus?.data?.endDate
      : subscriptionStatus.subscriptionStatus?.data?.trial_end_date;
  const endDate = moment(
    subscriptionStatus.subscriptionStatus && exdDateFromSubs
  ).format("MMMM DD, YYYY");

  return (
    <>
      {isLoading && <Loader />}
      <ModalComponent
        isOpen={pdfActionModal}
        onClose={() => setPdfActionModal(false)}
        closable={false}
        className={
          pdfModalType === "download_pdf"
            ? "wpb_deownload__pdf_wrapper"
            : "pdf__subscription__expired"
        }
      >
        {pdfModalType === "renew_subscription" ? (
          <div className="login-modal-section">
            <div className="unsubscribe_title">
              <h2
                dangerouslySetInnerHTML={{
                  __html: t("PR_PHONE_TRACK_SUB_EXPIRED", {
                    subscription: `<strong>${t("PR_PDF")}</strong>`
                  })
                }}
              ></h2>
            </div>
            <div className="unsubscribe-modal-body">
              <div
                className="unsubscribe-text"
                dangerouslySetInnerHTML={{
                  __html:
                    t("PR_RENEW_SUBSCRIPTION", {
                      date: endDate,
                      subscription: `<strong>${t("PR_PDF")}</strong>`
                    }) + "."
                }}
              ></div>
              <div className="submit-btn-modal">
                <button onClick={() => setPdfActionModal(false)}>
                  {t("PR_CANCEL")}
                </button>
              </div>
              <div className="unsubscribe_ButtonWrapper">
                <button
                  className="unsubscribe_ClrButton"
                  onClick={handleRenewSubscription}
                >
                  {t("PR_RENEW_SUB_1")}
                </button>
              </div>
            </div>
          </div>
        ) : pdfModalType === "download_pdf" ? (
          <div className="wpb_report__wrapper">
            <div className="vc_icon__general">
              <img src={downloadPdfIcon} alt="" />
            </div>
            <div className="vc_content__general">
              <h2>{t("PR_DOWNLOAD_YOUR_PDF")}</h2>
              <p>{t("PR_DOWNLOAD_YOUR_PDF_READY")}</p>
            </div>
            <div className="vc_button__general">
              <button className="vc_btn3-inline" onClick={handleDownloadPdf}>
                {t("PR_DOWNLOAD_PDF")}
              </button>
              <button
                className="vc_back-inline"
                onClick={() => setPdfActionModal(false)}
              >
                {t("PR_BACK")}
              </button>
            </div>
          </div>
        ) : null}
      </ModalComponent>
      <TransactionDeclinedModal
        isOpen={retryModal}
        // eslint-disable-next-line
        onClose={() => setRetryModal(false)}
        closable={false}
        handleSubmit={onRetry}
        orderDetails={orderDetails}
        cardLoadIntent={cardLoadIntent}
        setCardLoadIntent={setCardLoadIntent}
      />
      {isDownLoading && <DownLoadingAnimation />}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    subscriptionStatus: state.subscriptionStatus,
    phoneReportDetails:
      state.getPhoneReportDetails?.getPhoneReportDetailsData?.data,
    openReport: state.openReport,
    renewSubscription: state.renewSubscription,
    generatePayment: state.generatePayment,
    retryPayment: state.retryPayment
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    callCheckSubscriptionStatus: (data) => dispatch(subscriptionStatus(data)),
    callUploadPdf: (data) => dispatch(uploadPdf(data)),
    callReactivateSubscription: (data) => dispatch(reactivateSubscription(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportMultiActionModal);
