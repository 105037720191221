import React, {useEffect, useRef, useState} from "react";
import {connect, useDispatch, useSelector} from "react-redux";
import SubscriptionListPage from "./subscription";

import Loader from "../../../components/common/loader/loader";
import Notify from "../../../../components/common/Notify/notify";
import {pendingCancel} from "../../../redux/slice/pendingCancelSlice";
import {subscriptionList} from "../../../redux/slice/solidGate/subscriptionsListSlice";
import {getSubscriptionName, is3Ds} from "../../../utils/commonUtils";
import {cancelSubscription} from "../../../redux/slice/cancelSubscriptionSlice";
import {t} from "i18next";
import {CONSTANTS} from "../../../utils/constant";
import {addMoreReport} from "../../../redux/slice/solidGate/addMoreReportSlice";
import {reactivateSubscription} from "../../../redux/slice/solidGate/reactivateSubscriptionSlice";
import {useUserIp} from "../../../../utils/commonUtils";
import {createIntent} from "../../../redux/slice/solidGate/createIntentSlice";
import {cleanUrl} from "../../../../utils/helpers";

const SubscriptionList = (props) => {
  const {
    callSubscriptionList,
    subscriptionList,
    callCancelSubscription,
    callPendingCancel,
    callAddMoreReport,
    callReactivateSubscription
  } = props;
  const subscriptionPricing = useSelector(
    (state) => state?.pricingTitleList?.priceTitleList?.data
  );
  const disPatch = useDispatch();
  const userIp = useUserIp();
  const hasExecuted = useRef(false);
  const redirect3dsURL = Object.fromEntries(
    new URLSearchParams(window.location.search)
  );
  const {
    type,
    sname: subscriptionName,
    aname: apiName,
    expire_at,
    order_id,
    status
  } = redirect3dsURL || {};
  const is3dsReactive = apiName === "reactivate-cancel";
  const [isLoading, setIsLoading] = useState(false);
  const [retryModal, setRetryModal] = useState(false);
  const [currentSubscription, setCurrentSubscription] = useState("");
  const [reactive, setReactive] = useState(false);
  const [cardLoadIntent, setCardLoadIntent] = useState(null);

  useEffect(() => {
    callSubscriptionList();
    // eslint-disable-next-line
  }, []);

  const subscriptionMap = {
    phone_report: t("PR_PHONE_REPORT"),
    premium_report: t("PR_PRICING_PREMIUM_REPORTS"),
    pdf_download: t("PR_PDF_DOWNLOAD"),
    phone_tracking: t("PR_REPORT_PHONE_TRACKING")
  };

  const subscriptionSymbol =
    subscriptionPricing?.subscriptions?.[CONSTANTS.SUBSCRIPTIONS.PREMIUM_REPORT]
      ?.symbol;
  const price =
    subscriptionPricing?.subscriptions?.[currentSubscription]?.amount;
  const newPrice =
    subscriptionPricing?.subscriptions?.[subscriptionName]?.amount;

  const orderDetails = {
    orderName:
      subscriptionMap[currentSubscription] ||
      subscriptionMap[subscriptionName] ||
      null,
    orderPrice: `${subscriptionSymbol}${price || newPrice || ""}` || null,
    orderType: "subscription",
    orderTypeName: currentSubscription || subscriptionName || null
  };

  const handleRetryModalClose = () => {
    setRetryModal(false);
    if (Object.keys(redirect3dsURL).length) {
      const cleanedUrl = cleanUrl(window.location.href);
      window.history.pushState(null, "", cleanedUrl);
    }
  };

  const handleCancelSubscription = async (subscriptionId) => {
    setIsLoading(true);
    try {
      const res = await callCancelSubscription(subscriptionId);
      if (res.type === "cancelSubscription/fulfilled") {
        Notify("success", res.payload.message, "");
        callSubscriptionList();
      } else {
        Notify("error", res.error.message, "");
      }
    } catch (error) {
      console.warn("Error occurred on cancel subscription", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePendingCancel = async (subscriptionId) => {
    setIsLoading(true);
    try {
      const res = await callPendingCancel(subscriptionId);
      if (res.type === "pendingCancel/fulfilled") {
        Notify("success", res.payload.message, "");
        callSubscriptionList();
      } else {
        Notify("error", res.error.message, "");
      }
    } catch (error) {
      console.warn("Error occurred on pending cancel subscription", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubscription = async (subscription, isReactive = false) => {
    try {
      const priceTitle = getSubscriptionName(subscription?.name);
      setIsLoading(true);
      const newPayload = {
        ip_address: userIp,
        type: "create",
        subscription_name: isReactive ? priceTitle : undefined,
        force3ds: is3Ds(), // For check 3ds scenarios
        redirect_url: "my-account"
      };
      const firstRes = isReactive
        ? await callReactivateSubscription(newPayload)
        : await callAddMoreReport(newPayload);
      if (firstRes?.type?.endsWith("fulfilled")) {
        if (firstRes.payload.data.required_3ds) {
          const verifyUrl = firstRes.payload.data.verify_url;
          window.location.href = verifyUrl;
        } else if (firstRes.payload.status === false) {
          setCardLoadIntent(firstRes.payload.data.intent);
          setRetryModal(true);
          setReactive(isReactive);
          setCurrentSubscription(priceTitle);
        } else {
          Notify("success", firstRes.payload.message, "");
          callSubscriptionList();
        }
      } else {
        Notify("error", firstRes?.error?.message, "");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onRetry = async (orderId) => {
    try {
      setIsLoading(true);
      handleRetryModalClose();
      const newPayload = {
        ip_address: userIp,
        type: "transaction_decline",
        order_id: orderId,
        subscription_name:
          reactive || is3dsReactive
            ? currentSubscription || subscriptionName
            : undefined
      };
      const res =
        reactive || is3dsReactive
          ? await callReactivateSubscription(newPayload)
          : await callAddMoreReport(newPayload);
      if (res?.type?.endsWith("fulfilled")) {
        Notify("success", res.payload.message, "");
        callSubscriptionList();
      } else {
        Notify("error", res?.error?.message, "");
      }
    } catch (error) {
      console.log("error from retry function", error);
    } finally {
      setIsLoading(false);
      const cleanedUrl = cleanUrl(window.location.href);
      window.history.pushState(null, "", cleanedUrl);
    }
  };

  const handle3ds = async () => {
    try {
      setIsLoading(true);
      const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds (UTC)
      if (currentTime > expire_at) {
        Notify("error", "The URL has expired. Please try again.", "");
        const cleanedUrl = cleanUrl(window.location.href);
        window.history.pushState(null, "", cleanedUrl);
        return; // Exit the function if the URL is expired
      }
      if (type === "fail") {
        const intentResponse = await disPatch(
          createIntent({type: "subscription", name: subscriptionName})
        );
        if (intentResponse.type === "createIntent/fulfilled") {
          setCardLoadIntent(intentResponse.payload?.data);
          setRetryModal(true);
        } else {
          Notify("error", intentResponse?.error?.message, "");
        }
      } else {
        const payload3DS = {
          type: "3ds_confirm",
          order_id: order_id,
          subscription_name: is3dsReactive ? subscriptionName : undefined
        };
        const res = is3dsReactive
          ? await callReactivateSubscription(payload3DS)
          : await callAddMoreReport(payload3DS);
        if (res?.type?.endsWith("fulfilled")) {
          Notify("success", res.payload.message, "");
          callSubscriptionList();
        } else {
          Notify("error", res?.error?.message, "");
        }
        const cleanedUrl = cleanUrl(window.location.href);
        window.history.pushState(null, "", cleanedUrl);
      }
    } catch (err) {
      console.error("Intent creation failed:", err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (Object.keys(redirect3dsURL).length && !hasExecuted.current) {
      hasExecuted.current = true;
      handle3ds();
    }
  }, [redirect3dsURL]);

  return (
    <>
      {isLoading && <Loader />}
      <SubscriptionListPage
        subscriptionList={subscriptionList?.subscriptionList?.data}
        handleCancelSubscription={handleCancelSubscription}
        handlePendingCancel={handlePendingCancel}
        handleSubscription={handleSubscription}
        retryModal={retryModal}
        onRetry={onRetry}
        handleRetryModalClose={handleRetryModalClose}
        isLoading={isLoading}
        orderDetails={orderDetails}
        subscriptionLoading={subscriptionList?.isLoading}
        cardLoadIntent={cardLoadIntent}
        setCardLoadIntent={setCardLoadIntent}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authenticate: state.authenticate,
    subscriptionList: state.subscriptionList
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    callSubscriptionList: (data) => dispatch(subscriptionList(data)),
    callCancelSubscription: (data) => dispatch(cancelSubscription(data)),
    callPendingCancel: (data) => dispatch(pendingCancel(data)),
    callAddMoreReport: (data) => dispatch(addMoreReport(data)),
    callReactivateSubscription: (data) => dispatch(reactivateSubscription(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionList);
