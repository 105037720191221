import React from "react";
import "./locationTrack.scss";
import loadingGif from "../../../assets/location.gif";
import { useTranslation } from "react-i18next";
import { locationPin, newHeaderLogo, prLocationImg } from "../../assets/images";

const LocationTrackComponent = (props) => {
  const { location, address, loading, locationKey, keyMessage } = props;
  const { t } = useTranslation();
  return (
    <div>
      {loading && locationKey ? (
        <img src={loadingGif} className="loading-spin-image" alt="loading..." />
      ) : (
        <div className="location-page">
          <div className="back-grad-title location__bg_wrapper">
            <div className="pricing-main-title">
              <div className="location-main-section">
                <div className="location-main-logo">
                  <img src={newHeaderLogo} alt="" width={200} height={45} />
                </div>
                <div className="location__banner_img">
                  <img src={prLocationImg} alt="" />
                </div>
                <div className="location-your-address">
                  <img src={locationPin} alt="" />
                  {keyMessage ? (
                    <p>{keyMessage}</p>
                  ) : location ? (
                    <p>{address}</p>
                  ) : (
                    <p>{t("LOCATION_DENIED_OR_UNAVAILABLE")}</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LocationTrackComponent;
