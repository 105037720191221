import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../utils/axios";
import { getUserId } from "../../phoneReport/utils/commonUtils";

export const authenticate = createAsyncThunk("authenticate", async () => {
  try {
    // Check if Admin Login with User Then include userId in query string
    const userId = getUserId();
    const url = userId
      ? `/user/authenticate?userId=${userId}`
      : `/user/authenticate`;
    const response = await AxiosInstance.get(url);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw error;
    }
  }
});

const authenticateSlice = createSlice({
  name: "authenticate",
  initialState: {
    isLoading: false,
    authenticateData: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [authenticate.pending]: (state) => {
      state.isLoading = true;
    },
    [authenticate.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.authenticateData = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [authenticate.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },
  },
});

export default authenticateSlice.reducer;
