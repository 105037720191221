import React, { useEffect, useState } from "react";
import UnsubscribeComponent from "./unsubscribe";
import { connect } from "react-redux";
import Notify from "../common/Notify/notify";
import { Cookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { validEmail } from "../../utils/commonUtils";
import { solidGateUnsubscribe } from "../../phoneReport/redux/slice/solidGate/solidGateUnsubscribeSlice";

const Unsubscribe = (props) => {
  const { callSolidGateUnSubscribe, unsubscribeResult } = props;
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const cookies = new Cookies();
  const lang = cookies.get("lang");
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);
  const navigate = useNavigate();

  const handleOpenModal = () => {
    if (email) {
      if (validEmail.test(email)) {
        setOpenModal(true);
      } else {
        setError(t("VALID_EMAIL_RESET_PASSWORD"));
      }
    } else {
      setError(t("ENTER_EMAIL_RESET_PASSWORD"));
    }
  };

  useEffect(() => {
    document.body.classList.toggle("no-scroll", openModal);
    return () => document.body.classList.remove("no-scroll");
  }, [openModal]);

  const handleUnsubscribe = async () => {
    if (email) {
      const data = {
        email: email.toLowerCase(),
      };
      const result = await callSolidGateUnSubscribe(data);
      if (result.type === "solidGateUnsubscribe/fulfilled") {
        setEmail("");
        setSuccessPopup(true);
        setOpenModal(false);
        setTimeout(() => {
          setSuccessPopup(true);
          cookies.remove("role");
          cookies.remove("last_name");
          cookies.remove("first_name");
          cookies.remove("token");
          cookies.remove("email");
          setOpenModal(false);
          setSuccessPopup(false);
          Notify("success", t("NOTIFY_UNSUBSCRIBE_PAGE"), "");
          navigate(`/${lang}/`);
        }, 10000);
      } else {
        setError(result.error.message);
        setOpenModal(false);
      }
    } else {
      setError("Please Enter Email");
    }
  };
  return (
    <UnsubscribeComponent
      handleUnsubscribe={handleUnsubscribe}
      setEmail={setEmail}
      error={error}
      successPopup={successPopup}
      setOpenModal={setOpenModal}
      openModal={openModal}
      handleOpenModal={handleOpenModal}
      unsubscribeResult={unsubscribeResult.solidGateUnsubscribeData}
      setError={setError}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    unsubscribeResult: state.solidGateUnsubscribe,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    callSolidGateUnSubscribe: (data) => dispatch(solidGateUnsubscribe(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Unsubscribe);
