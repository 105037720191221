import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import AxiosInstance from "../../../../utils/axios";
export const reactivateSubscription = createAsyncThunk(
  "reactivateSubscription",
  async (data) => {
    try {
      const response = await AxiosInstance.post(
        `/subscription/solidgate/reactivate_cancel`,
        data
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const reactivateSubscriptionSlice = createSlice({
  name: "reactivateSubscription",
  initialState: {
    isLoading: false,
    reactivateSubscription: null,
    isError: false,
    errorMessage: ""
  },
  extraReducers: {
    [reactivateSubscription.pending]: (state) => {
      state.isLoading = true;
    },
    [reactivateSubscription.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.reactivateSubscription = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [reactivateSubscription.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    }
  }
});

export default reactivateSubscriptionSlice.reducer;
