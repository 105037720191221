import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUserId } from "../../../utils/commonUtils";
import AxiosInstance from "../../../../utils/axios";
import { trackEvent } from "../../../../utils/eventTracker";
// import { useUserData } from "../../../../utils/commonUtils";

export const unlockPremiumReport = createAsyncThunk(
  "unlockPremiumReport",
  async (data) => {
    try {
      // Check if Admin Login with User Then include userId in query string
      const userId = getUserId();
      const url = userId
        ? `/phone_report/solidgate/unlock_report?userId=${userId}`
        : `/phone_report/solidgate/unlock_report`;
      const response = await AxiosInstance.post(url, data);

      /*  event parameters start */
      const { order_id, amount, currency, email, user_id } =
        response?.data?.data?.orderInfo || {};

      const commonEvent = {
        transaction_id: order_id,
        value: amount,
        currency: currency?.toUpperCase(),
        email: email,
        user_id: user_id,
      };
      if (
        (!response.data.data.required_3ds &&
          response?.data?.status &&
          data.type === "create") ||
        data.type === "transaction_decline" ||
        data.type === "3ds_confirm"
      ) {
        trackEvent("single_premium_report", commonEvent);
        trackEvent("zaraz_single_premium_report", commonEvent);
      }
      /*  event parameters end */
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const unlockPremiumReportSlice = createSlice({
  name: "unlockPremiumReport",
  initialState: {
    isLoading: false,
    unlockPremiumReport: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [unlockPremiumReport.pending]: (state) => {
      state.isLoading = true;
    },
    [unlockPremiumReport.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.unlockPremiumReport = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [unlockPremiumReport.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },
  },
});

export default unlockPremiumReportSlice.reducer;
