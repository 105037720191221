import React, { useCallback, useState } from "react";
import SolidGate from "../../../../../components/common/solidGate/solidGate";
import DummyLayout from "../../../../../components/signUpSolidGate/module/dummyLayout/dummyLayout";
import { t } from "i18next";

const PaymentModalComponent = (props) => {
  const {
    merchantData,
    handlePaymentFail,
    setCardType,
    handlePaymentStatus,
    isLoading,
    cardType,
    setIsLoading,
  } = props;

  const [isMounted, setIsMounted] = useState(false);
  const [form, setForm] = useState(null);

  const handleFormMounted = useCallback(() => {
    setIsMounted(true);
  }, []);

  const handleOnReadyPaymentInstance = useCallback((form) => {
    setForm(form);
  }, []);

  return (
    <>
      <div style={{ display: isLoading && "none" }}>
        <SolidGate
          merchantData={merchantData}
          onPaymentFail={handlePaymentFail}
          onPaymentSuccess={(e) => {
            setIsLoading(true);
            console.log("onPaymentSuccess", e);
          }}
          onSubmitForm={() => {
            setIsLoading(true);
          }}
          onGetCardDetails={(details) => setCardType(details?.card?.brand)}
          handlePaymentStatus={handlePaymentStatus}
          onFormMounted={handleFormMounted}
          onReadyPaymentInstance={handleOnReadyPaymentInstance}
        />
      </div>
      {isLoading && <DummyLayout cardType={cardType} />}
      {isMounted && (
        <div className="submit_button_past_due">
          <button
            className="payment__Submit_past_due"
            type="button"
            onClick={() => {
              form?.submit();
            }}
          >
            {t("REACTIVATE")}
          </button>
        </div>
      )}
    </>
  );
};

export default PaymentModalComponent;
