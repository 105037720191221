import React, { useEffect, useState } from "react";
import {connect, useSelector} from "react-redux";
import {createFirstReport} from "../../../../redux/slice/createFirstReportSlice";
import {getPhoneReportDetails, sendLocation} from "../../../../redux/slice";
import LocatingModalComponent from "./locatingModal";
import {useSearchParams} from "react-router-dom";
import {isValidPhoneNumber, parsePhoneNumber} from "libphonenumber-js";
import Notify from "../../../../../components/common/Notify/notify";
import {useTranslation} from "react-i18next";
import {
  handleSetNumber,
  handleSetPhoneNumber
} from "../../../../../utils/commonUtils";
import {useAppContextInput} from "../../../../../App";
import { checkAdminLogin } from "../../../../utils/commonUtils";

const LocatingModal = (props) => {
  const {
    callCreateFirstReport,
    callSendLocation,
    isOpen,
    setIsOpen,
    onClose,
    setNumber,
    setCountryCode,
    setPhoneReportModalShow,
    number,
    countryCode,
    setPhoneReportId,
    sendDetailsData,
    getPhoneReportDetails,
    phoneReportId,
    callGetPhoneReportDetails
  } = props;
  const {t} = useTranslation();
  const [searchparams] = useSearchParams();
  const searchNumber = Object.fromEntries([...searchparams]);
  const {inputRef} = useAppContextInput();
  const sendLocationLoading = useSelector(
    (state) => state?.sendLocation?.isLoading
  );
  const createFirstReportLoading = useSelector(
    (state) => state?.createFirstReport?.isLoading
  );
  const buttonDisabled = sendLocationLoading || createFirstReportLoading;

  // Locating Modal Preview Modal Refactored
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [receiverNumber, setReceiverNumber] = useState("");
  const [message, setMessage] = useState(t("SMS_DEFAULT_TEXT"));
  const [countryCodeinput, setCountryCodeinput] = useState("ca");
  const [messageError, setMessageError] = useState("");
  const [receiverCountryCode, setReceiverCountryCode] = useState(+1);
  const [receiverError, setReceiverError] = useState("");
  const [formatedNumber, setFormatedNumber] = useState(0);
  const [popNumberError, setPopNumberError] = useState("");
  const [receiverDialCode, setReceiverDialCode] = useState(0);
  const [countryShortName, setCountryShortName] = useState("");
  const [dialCode, setDialCode] = useState("+1");
  const [finalFormattedNumber, setFinalFormattedNumber] = useState("");
  useEffect(() => {
    if (getPhoneReportDetails.getPhoneReportDetailsData) {
      setPhoneReportId(
        getPhoneReportDetails.getPhoneReportDetailsData.data.phoneReport._id
      );
      setMessage(t("SMS_DEFAULT_TEXT"));
    }
    // eslint-disable-next-line
  }, [getPhoneReportDetails.getPhoneReportDetailsData]);
  useEffect(() => {
    const data = sendDetailsData?.getSendDetailsData?.data;
    if (data) {
      setCountryShortName(data?.country);
      setCountryCodeinput(data?.country_code);
      if (searchNumber.D && searchNumber.n) {
        setDialCode(searchNumber.D);
      } else {
        setDialCode(data.country_calling_code);
      }
    }
    // eslint-disable-next-line
  }, [sendDetailsData]);

  useEffect(() => {
    if (searchNumber.D || searchNumber.n || searchNumber.id) {
      setReceiverCountryCode("+" + searchNumber.D);
      const sanitizedNumber = searchNumber.n;
      setNumber(searchNumber.D + sanitizedNumber);
      setCountryCode("+" + searchNumber.D);
      setPhoneReportId(searchNumber.id);
      setMessage(t("SMS_DEFAULT_TEXT"));
      setDialCode(searchNumber.D);
    }
    // eslint-disable-next-line
  }, [searchNumber.D, searchNumber.n, searchNumber.id]);
  useEffect(() => {
    if (number && number.length > 5) {
      const phoneNumber = parsePhoneNumber("+" + number.toString());
      if (phoneNumber) {
        setFormatedNumber(phoneNumber.formatInternational());
      }
    }
  }, [receiverNumber, number]);
  const handleOnReceivercountry = (value, country) => {
    setCountryCodeinput(country.countryCode);
    handleSetNumber(
      value,
      country,
      setReceiverDialCode,
      setPopNumberError,
      setReceiverCountryCode,
      setReceiverNumber,
      setFinalFormattedNumber,
      receiverNumber
    );
  };
  const handleOnReceiverNumberChange = (event) => {
    setPopNumberError("");
    handleSetPhoneNumber(
      event,
      setReceiverNumber,
      setPopNumberError,
      setCountryCodeinput,
      countryCodeinput,
      setReceiverDialCode,
      setFinalFormattedNumber,
      inputRef
    );
  };
  const handleChangeMessage = (event) => {
    const value = event.target.value;
    const trimmedValue =
      typeof value === "string" ? value.replace(/^\s+/g, "") : value;
    setMessage(trimmedValue);
    if (trimmedValue === "") {
      setMessageError(t("SMS_MESSAGE_ERROR"));
    } else {
      setMessageError("");
    }
  };
  const handleSendMessage = async () => {
    if (buttonDisabled || checkAdminLogin()) {
      return;
    }
    let checkValue;
    let trimmedNumber;
    if (
      receiverDialCode === "91" &&
      finalFormattedNumber.length === 14 &&
      finalFormattedNumber.startsWith(receiverDialCode)
    ) {
      checkValue = finalFormattedNumber
        .slice(receiverDialCode.length)
        .toString();
    } else {
      trimmedNumber = finalFormattedNumber.trim();

      if (trimmedNumber.startsWith(receiverDialCode)) {
        trimmedNumber = trimmedNumber.substring(receiverDialCode.length).trim();
        if (
          trimmedNumber
            .replace(" ", "")
            .replace("-", "")
            .replace("(", "")
            .replace(")", "").length < 10
        ) {
          if (receiverDialCode.length <= 2) {
            trimmedNumber = receiverDialCode + trimmedNumber;
          }
        }
      }
    }
    if (receiverNumber) {
      if (isValidPhoneNumber(receiverNumber, countryCodeinput?.toUpperCase())) {
        if (!messageError) {
          const receiver_mobile_number = checkValue
            ? checkValue.toString().replace(" ", "")
            : trimmedNumber.toString().replace(receiverCountryCode, "");
          const senderNumber = "+" + dialCode === 1 ? "+1" : "+" + dialCode;
          let data = {
            sender_country_code: senderNumber
              .replace("++", "+")
              .replace(" ", ""),
            sender_mobile_number: searchNumber.n?.replace("+", ""),
            receiver_country_code: receiverDialCode.includes("+")
              ? receiverDialCode
              : `+${receiverDialCode}`,
            receiver_mobile_number: receiver_mobile_number,
            message: message
          };
          let result;
          if (phoneReportId) {
            data = {...data, phone_report_id: phoneReportId};
            result = await callSendLocation(data);
          } else {
            const senderNumber = "+" + dialCode === 1 ? "+1" : "+" + dialCode;
            data = {
              ...data,
              sender_country_code: senderNumber
                .replace("++", "+")
                .replace(" ", ""),
              sender_mobile_number: searchNumber.n
            };
            result = await callCreateFirstReport(data);
          }
          callGetPhoneReportDetails(searchNumber.id || phoneReportId);
          if (
            result.type === "sendLocation/fulfilled" ||
            result.type === "createFirstReport/fulfilled"
          ) {
            Notify("success", t("NOTIFY_SEND_LOCATION"), "");
            setIsOpen(false);
            setMessage(t("SMS_DEFAULT_TEXT"));
            setReceiverNumber(countryCode);
            setIsPreviewOpen(false);
            window.onpopstate = null;
            document.body.style.overflow = "unset";
            setReceiverNumber(receiverCountryCode);
            setPhoneReportModalShow(true);
            setFinalFormattedNumber("");
            if (result.type === "createFirstReport/fulfilled") {
              const {payload} = result;
              const {data} = payload;
              const {_id} = data || {};
              setPhoneReportId(_id);
              const reportId = result.id;
              const currentUrl = window.location.href;
              const newUrl = currentUrl.includes("?")
                ? `${currentUrl}&id=${_id}`
                : `${currentUrl}?id=${reportId}`;

              window.history.pushState({path: newUrl}, "", newUrl);
              callGetPhoneReportDetails(_id || phoneReportId);
            }
          } else if (
            result.type === "sendLocation/rejected" ||
            result.type === "createFirstReport/rejected"
          ) {
            Notify("error", result.error.message, "");
            onClose();
            document.body.style.overflow = "unset";
          }
        }
      } else {
        setPopNumberError(t("ERROR_VALID_NUMBER_DASHBOARD"));
      }
    } else {
      setPopNumberError(t("ERROR_VALID_NUMBER_DASHBOARD"));
    }
  };
  const handlePreviewNavigate = () => {
    if (!receiverNumber) {
      setPopNumberError(t("ERROR_ENTER_NUMBER_DASHBOARD"));
      return;
    }
    const isValidNumber = isValidPhoneNumber(
      receiverNumber,
      countryCodeinput.toUpperCase()
    );
    if (!isValidNumber) {
      setPopNumberError(t("ERROR_VALID_NUMBER_DASHBOARD"));
      return;
    }
    const trimmedNumber = receiverNumber.replace(receiverDialCode, "").trim();
    if (trimmedNumber === "") {
      setReceiverError("valid phone number required");
      return;
    }
    if (message === "") {
      setMessageError(t("SMS_MESSAGE_ERROR"));
      return;
    }
    setIsOpen(false);
    setIsPreviewOpen(true);
  };
  const handleCloseModal = () => {
    setMessageError("");
    setPopNumberError("");
    setReceiverNumber("");
    setFinalFormattedNumber("");
    setMessage(t("SMS_DEFAULT_TEXT"));
    onClose();
  };
  return (
    <>
      <LocatingModalComponent
        className={""}
        isOpen={isOpen}
        onClose={handleCloseModal}
        setIsOpen={setIsOpen}
        countryShortName={countryShortName}
        formatedNumber={formatedNumber}
        receiverNumber={receiverNumber}
        handleOnReceiverNumberChange={handleOnReceiverNumberChange}
        handleOnReceivercountry={handleOnReceivercountry}
        popNumberError={popNumberError}
        receiverError={receiverError}
        handleChangeMessage={handleChangeMessage}
        message={message}
        messageError={messageError}
        handleSendMessage={handleSendMessage}
        handlePreviewNavigate={handlePreviewNavigate}
        setIsPreviewOpen={setIsPreviewOpen}
        isPreviewOpen={isPreviewOpen}
        inputRef={inputRef}
        finalFormattedNumber={finalFormattedNumber}
        countryCodeinput={countryCodeinput}
        buttonDisabled={buttonDisabled}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    sendLocation: state.sendLocation,
    sendDetailsData: state.sendDetailsData,
    getPhoneReportDetails: state.getPhoneReportDetails
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    callCreateFirstReport: (data) => dispatch(createFirstReport(data)),
    callSendLocation: (data) => dispatch(sendLocation(data)),
    callGetPhoneReportDetails: (data) => dispatch(getPhoneReportDetails(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LocatingModal);
