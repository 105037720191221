import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {getUserId} from "../../../utils/commonUtils";
import AxiosInstance from "../../../../utils/axios";
export const subscriptionList = createAsyncThunk(
  "subscriptionList",
  async () => {
    try {
      // Check if Admin Login with User Then include userId in query string
      const userId = getUserId();
      const url = userId
        ? `/subscription/solidgate/list?userId=${userId}`
        : `/subscription/solidgate/list`;
      const response = await AxiosInstance.get(url);
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const subscriptionListSlice = createSlice({
  name: "subscriptionList",
  initialState: {
    isLoading: false,
    subscriptionList: null,
    isError: false,
    errorMessage: ""
  },
  extraReducers: {
    [subscriptionList.pending]: (state) => {
      state.isLoading = true;
    },
    [subscriptionList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.subscriptionList = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [subscriptionList.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    }
  }
});

export default subscriptionListSlice.reducer;
