import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../../utils/axios";
import { getUserId } from "../../utils/commonUtils";

export const pdfSubscriptionStatus = createAsyncThunk(
  "pdfSubscriptionStatus",
  async (reportId) => {
    try {
      // Check if Admin Login with User Then include userId in query string
      const userId = getUserId();
      const url = userId
        ? `/phone_report/pdf_subscription_status?userId=${userId}`
        : `/phone_report/pdf_subscription_status`;
      const response = await AxiosInstance.post(url, {
        phone_report_id: reportId,
      });
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const pdfSubscriptionStatusSlice = createSlice({
  name: "pdfSubscriptionStatus",
  initialState: {
    isLoading: false,
    pdfSubscriptionStatus: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [pdfSubscriptionStatus.pending]: (state) => {
      state.isLoading = true;
    },
    [pdfSubscriptionStatus.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.pdfSubscriptionStatus = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [pdfSubscriptionStatus.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },
  },
});

export default pdfSubscriptionStatusSlice.reducer;
