import React from "react";
import { t } from "i18next";
import {
  accordionIcon,
  possibleEducation,
  nodataIcon,
} from "../../../assets/images";
import { Collapse } from "antd";
import UnlockReport from "../unlockReport/unlockReport";
import { renderValue } from "../../../utils/commonUtils";
import SectionAction from "../sectionAction/sectionAction";
import NoInfoFound from "../noInfoFound/noInfoFound";

const PossibleEducation = ({ isLocked, sectionName, reportDetail }) => {
  const { possible_educations } = reportDetail?.phoneReport || {};
  const { data, status } = possible_educations || {};
  const { phone_owner_info } = reportDetail?.phoneReport || {};
  const isData = phone_owner_info?.status === "found";
  return isLocked ? (
    <UnlockReport
      title={t("PR_PREMIUM_SECTION_TITLE_3")}
      image={possibleEducation}
      buttonText={t("PR_UNLOCK_THIS_SECTION").toUpperCase()}
      shouldKnowText={t("PR_UNLOCK_TITLE")}
      knownText={t("PR_EDUCTION_MESSAGE")}
      sectionName={sectionName}
      pricingSectionName={"possible_educations"}
      id={"Educational Background"}
    />
  ) : !isLocked && status === "not_found" ? (
    <NoInfoFound sectionName={sectionName} id="educationalBackground" />
  ) : (
    <div className="ds--unlock-jobs white-bg-wrap" id="educationalBackground">
      <div className="ds--unlock__title left-content main_title toggle_area">
        <h2>{t("PR_EDUCATIONAL_BACKGROUND")}</h2>
        <SectionAction />
      </div>
      {status === "found" && data?.length > 0 ? (
        <>
          <div className="ds--accordion-collapse">
            <Collapse
              items={[
                {
                  key: "1",
                  label: t("PR_LEARN_MORE"),
                  children: (
                    <p>
                      {isData && phone_owner_info.data.name
                        ? t("PR_EDUCATION_TITLE_1", {
                            phone_owner_full_name: isData
                              ? renderValue(phone_owner_info.data.name)
                              : "USER",
                          })
                        : t("PR_EDUCATION_TITLE_2", {
                            phoneNumber: isData
                              ? reportDetail?.phoneReport.phone_number_prefix +
                                reportDetail?.phoneReport.phone_number
                              : "USER",
                          })}
                    </p>
                  ),
                },
              ]}
              expandIconPosition="end"
              expandIcon={({ isActive }) => (
                <img
                  src={isActive ? accordionIcon : accordionIcon}
                  width="2em"
                  height="2em"
                  alt=""
                />
              )}
            />
          </div>
          <div className="ds--job-wrapper">
            {data.map((edu, index) => (
              <div className="ds--job-box" key={edu._id}>
                <h3>{`${t("PR_EDUCATION")} ${index + 1}`}</h3>
                <p>
                  <b>{t("PR_UNIVERSITY_NAME")}:</b>{" "}
                  {renderValue(edu.university_name)}
                </p>
                <p>
                  <b>{t("PR_DEGREE")}: </b> {renderValue(edu.degrees)}
                </p>
                <p>
                  <b> {t("PR_START_DATE")}:</b> {renderValue(edu.start_date)}
                </p>
                <p>
                  <b>{t("PR_END_DATE")}:</b> {renderValue(edu.end_date)}
                </p>
              </div>
            ))}
          </div>
        </>
      ) : (
        <p className="data_notfound">
          <img src={nodataIcon} alt="" /> {t("PR_RECORD_NOT_FOUND")}
        </p>
      )}
    </div>
  );
};

export default PossibleEducation;
