import React, { useEffect } from "react";
import "./cookiesPolicy.scss";
import { scrollToTop } from "../../utils/commonUtils";
import { t } from "i18next";

const CookiesPolicy = () => {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div className="main_root">
      <div className="Policy-header-section">
        <div className="main_container">
          <h1 className="Policy-title">Cookies Policy</h1>
        </div>
      </div>
      <div className="wpb_info_wrapper">
        <div className="main_container">
          <div className="cms__poolicy_wrapper">
            {!["en"].includes(window.location.pathname.split("/")[1]) && (
              <strong class="policy_desc mb-2">
                {t("PP_TERMS_PRIVACY_NOTE")}
              </strong>
            )}
            <div className="mb-1 wpb_summary_cms">
              <h2 className="policy_titleh2">Summary</h2>
              <ul className="wpb_list_wrapper">
                <li>
                  Cookies used : <b>Own and Third-Party</b>
                </li>
                <li>
                  Are they used for statistical purposes? : <b> Yes</b>
                </li>
                <li>
                  Are they used for advertising? : <b>No</b>
                </li>
                <li>
                  Third-party services that install cookies through this website
                  : <b>Google Analytics and Google Maps</b>
                </li>
              </ul>
            </div>
            <div class="web_docs_section mb-2 border-bottom">
              <h2 className="policy_titleh2">
                How to configure and disable cookies
              </h2>
              <p class="policy_desc mb-1">
                You can allow, block or delete cookies installed on your device
                from your web browser. If you do not allow cookies to be
                installed on your device, your browsing experience may be worse.
                Below are instructions on how to configure cookies on each
                browser:
              </p>
              <ul class="wpb_list_wrapper">
                <li>
                  <a href="#">Edge</a>
                </li>
                <li>
                  <a href="#">Google Chrome</a>
                </li>
                <li>
                  <a href="#">Google Android</a>
                </li>
                <li>
                  <a href="#">Internet Explorer 7 y 8</a>
                </li>
                <li>
                  <a href="#">Internet Explorer 9</a>
                </li>
                <li>
                  <a href="#">Internet Explorer 10</a>
                </li>
                <li>
                  <a href="#">Internet Explorer 11</a>
                </li>
                <li>
                  <a href="#">Mozilla Firefox</a>
                </li>
                <li>
                  <a href="#">Opera</a>
                </li>
                <li>
                  <a href="#">Safari</a>
                </li>
                <li>
                  <a href="#">Safari Mobile</a>
                </li>
              </ul>
            </div>
            <div class="web_docs_section mb-2 border-bottom">
              <h2 className="policy_titleh2">1. Types and uses</h2>
              <p class="policy_desc">
                The service uses its own and third-party cookies to improve
                browsing, to provide the service and to obtain statistics on the
                website’s use.
              </p>
            </div>
            <div class="web_docs_section mb-1 border-bottom">
              <h2 className="policy_titleh2">
                2. What is a cookie and what is it used for?
              </h2>
              <p class="policy_desc">
                A cookie is a text file that is sent to the web browser of your
                computer, mobile phone or tablet and is used to store and
                retrieve information on your browsing. For example, to remember
                your username and password or your profile preferences.
              </p>
            </div>
            <div class="web_docs_section">
              <h2 className="policy_titleh2">
                3. What types of cookies are there?
              </h2>
              <p class="policy_desc mb-2">
                <b>According to who manages them:</b>
              </p>
              <ul class="wpb_list_wrapper mb-2">
                <li>
                  <a href="#">
                    <b>Own cookies;</b> are sent to your browser from our
                    computers or web domains.
                  </a>
                </li>
                <li>
                  <a href="#">
                    <b>Third-party cookies;</b> are sent to your browser from a
                    computer or web domain that we do not directly manage, but a
                    third party such as for example Google.
                  </a>
                </li>
              </ul>
              <p class="policy_desc mb-2">
                <b>According to the time they are active for, there are:</b>
              </p>
              <ul class="wpb_list_wrapper mb-2">
                <li>
                  <a href="#">
                    <b>Session cookies; </b> remain in the cookie file of your
                    browser until you leave the website, so none are left on
                    your device.
                  </a>
                </li>
                <li>
                  <a href="#">
                    <b>Persistent cookies;</b> remain on your device and our
                    website reads them every time you visit. These cookies stop
                    working after a specific date
                  </a>
                </li>
              </ul>
              <p class="policy_desc mb-2">
                <b>Finally, and according to their purpose, we have:</b>
              </p>
              <ul class="wpb_list_wrapper">
                <li>
                  <a href="#">
                    <b>Technical cookies;</b> improve browsing and ensure the
                    website works properly.
                  </a>
                </li>
                <li>
                  <a href="#">
                    <b>Personalisation cookies;</b> enable access to the service
                    with predefined characteristics based on a series of
                    criteria.
                  </a>
                </li>
                <li>
                  <a href="#">
                    <b>Analysis cookies;</b> make it possible to measure and
                    statistically analyse how the service provided is used.
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookiesPolicy;
