import React, { useEffect, useState } from "react";
import LocationComponent from "./locationTrack";
import { connect } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Cookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { geoLocation, validLocationKey } from "../../redux/slice";
import { googleAPIKey } from "../../../environment";
import ModalComponent from "../../components/common/Modal/modal";
import { enableLocationServicesIcon } from "../../assets/images";

const LocationTrack = (props) => {
  const { callGeoLocation, callValidLocationKey } = props;
  const [location, setLocation] = useState(null);
  const [address, setAddress] = useState("");
  const [keyMessage, setKeyMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [key] = useSearchParams();
  const [locationKey] = [...key];
  const time = new Date().getTime();
  const navigate = useNavigate();
  const cookies = new Cookies();
  const lang = cookies.get("lang");
  const { t } = useTranslation();

  useEffect(() => {
    if (locationKey) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setLocation({ latitude, longitude });
            getAddress(latitude, longitude);
            setLoading(false);
          },
          () => {
            setModalVisible(true);
            setLoading(false);
            setLocation(null);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
        setLocation(null);
      }
    }
    // eslint-disable-next-line
  }, []);

  const handleCallGeo = async (
    mapData,
    latitude,
    longitude,
    formattedAddress
  ) => {
    if (mapData) {
      const cityObject = mapData.results[0].address_components.find((item) =>
        item.types.includes("locality")
      );
      const regionObject = mapData.results[0].address_components.find((item) =>
        item.types.includes("administrative_area_level_1")
      );
      const postalCodeObject = mapData.results[0].address_components.find(
        (item) => item.types.includes("postal_code")
      );
      const countryObject = mapData.results[0].address_components.find((item) =>
        item.types.includes("country")
      );

      const data = {
        location_id: locationKey[1],
        geo: {
          lat: latitude,
          long: longitude,
          formatted: formattedAddress,
          city: cityObject?.long_name,
          region: regionObject?.long_name,
          country: countryObject?.long_name,
          zip: postalCodeObject?.long_name,
          time: time
        }
      };
      // eslint-disable-next-line
      const res = await callGeoLocation(data);
      if (res.type === "geoLocation/fulfilled") {
        navigate(`/${lang}/location`);
      }
    }
  };

  const getAddress = async (latitude, longitude) => {
    try {
      const validKeyResult = await callValidLocationKey(locationKey[1]);
      if (validKeyResult.type === "validLocationKey/fulfilled") {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${googleAPIKey}`
        );
        if (response.ok) {
          const mapData = await response.json();
          if (mapData.results.length > 0) {
            const formattedAddress = mapData.results[0].formatted_address;
            setAddress(formattedAddress);
            handleCallGeo(mapData, latitude, longitude, formattedAddress);
          }
        } else {
          console.error("Failed to retrieve address from Geocoding API.");
          setAddress("");
        }
      } else {
        setKeyMessage(t("ALREDY_SHARED_LOCATIONS"));
        navigate(`/${lang}/location`);
      }
    } catch (error) {
      console.error("Error retrieving address:", error);
      setAddress("");
    }
  };
  return (
    <>
      <LocationComponent
        loading={loading}
        location={location}
        address={address}
        locationKey={locationKey}
        keyMessage={keyMessage}
      />

      <ModalComponent
        isOpen={modalVisible}
        onClose={() => setModalVisible(false)}
        closable={true}
        maskClosable={true}
        className="new-modal-close"
      >
        <div className="monitoring__report_modal location__services_wrap">
          <div className="monitoring__report_img">
            <img src={enableLocationServicesIcon} alt="" />
          </div>
          <div className="monitoring__report_info">
            <h2 className="modal__popup_title">{t("PR_LOCATION_TRACK_TITLE")}</h2>
            <p className="modal__popup_desc modal_popup_point">
            {t("PR_LOCATION_TRACK_MODAL_FEATURE")}
              <ul>
                <li>{t("PR_LOCATION_TRACK_FEATURE_PT_1")}</li>
                <li>{t("PR_LOCATION_TRACK_FEATURE_PT_2")}</li>
                <li>{t("PR_LOCATION_TRACK_FEATURE_PT_3")}</li>
              </ul>
            </p>
          </div>
        </div>
      </ModalComponent>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    callGeoLocation: (data) => dispatch(geoLocation(data)),
    callValidLocationKey: (id) => dispatch(validLocationKey(id))
  };
};
export default connect(null, mapDispatchToProps)(LocationTrack);
