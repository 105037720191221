import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import AxiosInstance from "../../../../utils/axios";
export const createIntent = createAsyncThunk("createIntent", async (data) => {
  try {
    const response = await AxiosInstance.post(
      `/phone_report/solidgate/create_intent`,
      data
    );
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw error;
    }
  }
});

const createIntentSlice = createSlice({
  name: "createIntent",
  initialState: {
    isLoading: false,
    createIntent: null,
    isError: false,
    errorMessage: ""
  },
  extraReducers: {
    [createIntent.pending]: (state) => {
      state.isLoading = true;
    },
    [createIntent.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.createIntent = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [createIntent.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    }
  }
});

export default createIntentSlice.reducer;
