import React, { useEffect } from "react";
import { Table, Button, Tag } from "antd";
import { connect } from "react-redux";
import "./dashBoard.scss";
import CustomPagination from "../../components/common/pagination/pagination";
import {
  userList,
  setPage,
  setPageSize,
  setSearchValue,
  resetState,
} from "../../../phoneReport/redux/slice/admin/getUserListSlice";
import Notify from "../../../components/common/Notify/notify";
import { Cookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

const AdminDashBoard = ({
  userListData,
  page,
  pageSize,
  searchValue,
  getUserList,
  resetState,
  setPage,
  setPageSize,
  isLoading,
  totalRecords,
  isError,
  errorMessage,
}) => {
  const navigate = useNavigate();
  const cookie = new Cookies();
  const lang = cookie.get("lang");

  // Get User List
  useEffect(() => {
    getUserList({ page, recordsPerPage: pageSize, searchValue });
  }, []);

  useEffect(() => {
    if (isError && errorMessage) {
      Notify("error", errorMessage, "");
    }
  }, [isError, errorMessage]);

  const handlePageChange = (newPage) => {
    if(newPage !== page){
      setPage(newPage);
      getUserList({ page : newPage, recordsPerPage: pageSize, searchValue });
    }
  };

  const handlePageSizeChange = (current, newPageSize) => {
    setPageSize(newPageSize);
    setPage(1);
    getUserList({ page : 1, recordsPerPage: newPageSize, searchValue });
  };

  const handleAdminLoginUser = (userId) => {
    cookie.set("userId", userId, { path: "/" });
    if (cookie.get("userId")) {
      resetState();
      setTimeout(() => {
        navigate(`/${lang}/dashboard`, { path: "/" });
      }, 100);
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "number",
      key: "number",
      width: 70, 
      render: (text, record, index) => {
        const number = (page - 1) * pageSize + index + 1;
        return String(number).padStart(2, '0');
      },
    },
    {
      title: "User Name",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      minWidth : 170,
      render: (text) => (
        <span
          style={{
            display: "inline-block",
            maxWidth: "200px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          title={text} 
        >
          {text}
        </span>
      ),
    },
    {
      title: "Email address",
      dataIndex: "email",
      key: "email",
      width: 340,
      render: (email) => (
        <span
          style={{
            display: "inline-block",
            maxWidth: "340px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          title={email} 
        >
          {email}
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Tag color={status ? "green" : "red"} style={{ borderRadius: "10px" }}>
          {status ? "Active" : "Inactive"}
        </Tag>
      ),
    },
    {
      title: "",
      key: "action",
      render: (item) => (
        <Button
          type="primary"
          shape="round"
          onClick={() => {
            handleAdminLoginUser(item?._id);
          }}
        >
          Login
        </Button>
      ),
    },
  ];

  return (
    <div className="main_container wpb__admin_container">
      <h2 className="admin__title">User List</h2>
      <div className="white-bg-wrap wpb__admin_dashboard">
        <Table
          dataSource={userListData || []}
          columns={columns}
          loading={isLoading}
          pagination={false}
          bordered={false}
          style={{ background: "#fff" }}
        />
      </div>
      {userListData?.length > 0 && (
        <>
          <div className="white-bg-wrap pagination__wrapper">
            <CustomPagination
              current={page}
              pageSize={pageSize}
              total={totalRecords}
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
            />
          </div>
        </>
      )}
    </div>
  );
};  

const mapStateToProps = (state) => ({
  userListData: state.getUserList.userList,
  page: state.getUserList.page,
  pageSize: state.getUserList.pageSize,
  searchValue: state.getUserList.searchValue,
  isLoading: state.getUserList.isLoading,
  totalRecords: state.getUserList.totalRecords,
  isError: state.getUserList.isError,
  errorMessage: state.getUserList.errorMessage,
});

const mapDispatchToProps = (dispatch) => ({
  getUserList: ({ page, recordsPerPage, searchValue }) =>
    dispatch(userList({ page, recordsPerPage, searchValue })),
  setPage: (page) => dispatch(setPage(page)),
  setPageSize: (pageSize) => dispatch(setPageSize(pageSize)),
  setSearchValue: (value) => dispatch(setSearchValue(value)),
  resetState: (value) => dispatch(resetState(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminDashBoard);
