import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUserId } from "../../../utils/commonUtils";
import AxiosInstance from "../../../../utils/axios";
import { trackEvent } from "../../../../utils/eventTracker";

export const pdfSubscriptionCreate = createAsyncThunk(
  "pdfSubscriptionCreate",
  async (data) => {
    try {
      // Check if Admin Login with User Then include userId in query string
      const userId = getUserId();
      const url = userId
        ? `/report_subscription/solidgate/pdf_download/create?userId=${userId}`
        : `/report_subscription/solidgate/pdf_download/create`;
      const response = await AxiosInstance.post(url, data);
      /*  event parameters start */
      const { order_id, amount, currency, email, user_id } =
        response?.data?.data?.orderInfo || {};

      const commonEvent = {
        transaction_id: order_id,
        value: amount,
        currency: currency?.toUpperCase(),
        email: email,
        user_id: user_id
      };
      if (
        (!response.data.data.required_3ds &&
          response?.data?.status &&
          data.type === "create") ||
        data.type === "transaction_decline" ||
        data.type === "3ds_confirm"
      ) {
        trackEvent("pdf_activation", commonEvent);
        trackEvent("zaraz_pdf_activation", commonEvent);
      }

      /*  event parameters end */
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const pdfSubscriptionCreateSlice = createSlice({
  name: "pdfSubscriptionCreate",
  initialState: {
    isLoading: false,
    pdfSubscription: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [pdfSubscriptionCreate.pending]: (state) => {
      state.isLoading = true;
    },
    [pdfSubscriptionCreate.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.pdfSubscription = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [pdfSubscriptionCreate.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },
  },
});

export default pdfSubscriptionCreateSlice.reducer;
