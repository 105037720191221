import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../../utils/axios";
import { getUserId } from "../../utils/commonUtils";
export const readAllNotification = createAsyncThunk(
  "readAllNotification",
  async () => {
    try {
      // Check if Admin Login with User Then include userId in query string
      const userId = getUserId();
      const url = userId
        ? `/notification/mark_as_all_read?userId=${userId}`
        : `/notification/mark_as_all_read`;
      const response = await AxiosInstance.post(url);
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const readAllNotificationSlice = createSlice({
  name: "readAllNotification",
  initialState: {
    isLoading: false,
    readAllNotification: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [readAllNotification.pending]: (state) => {
      state.isLoading = true;
    },
    [readAllNotification.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.readAllNotification = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [readAllNotification.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },
  },
});

export default readAllNotificationSlice.reducer;
