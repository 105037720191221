import React from "react";
import { t } from "i18next";
import {
  accordionIcon,
  possiblePhotosImg,
  nodataIcon,
  noImage,
} from "../../../assets/images";
import {
  carouselFullWidth,
  renderValue,
  toCamelCase,
} from "../../../utils/commonUtils";
import { Collapse } from "antd";
import Slider from "react-slick";
import UnlockReport from "../unlockReport/unlockReport";
import SectionAction from "../sectionAction/sectionAction";
import NoInfoFound from "../noInfoFound/noInfoFound";

const PossiblePhotos = ({ isLocked, sectionName, reportDetail, number }) => {
  const { possible_photos, phone_owner_info } = reportDetail?.phoneReport || {};
  const { data = [], status } = possible_photos || {};
  const { data: phoneOwnerData = {}, status: phoneOwnerStatus } =
    phone_owner_info || {};
  const dataFound = phoneOwnerStatus === "found";
  const verb = data.length === 1 ? "was" : "were";
  return isLocked ? (
    <UnlockReport
      title={t("PR_POSSIBLE_PHOTOS")}
      image={possiblePhotosImg}
      buttonText={t("PR_UNLOCK_THIS_SECTION").toUpperCase()}
      shouldKnowText={t("PR_UNLOCK_TITLE")}
      knownText={t("PR_POSSIBLE_PHOTOS_MESSAGE")}
      sectionName={sectionName}
      pricingSectionName={"possible_photos"}
      id={"Possible Photos"}
    />
  ) : !isLocked && status === "not_found" ? (
    <NoInfoFound
      sectionName={sectionName}
      id={toCamelCase("Possible Photos")}
    />
  ) : (
    <div
      className="ds--unlock-photos white-bg-wrap"
      id={toCamelCase("Possible Photos")}
    >
      <div className="ds--unlock__title left-content main_title toggle_area">
        <h2>{t("PR_POSSIBLE_PHOTOS")}</h2>
        <SectionAction />
      </div>
      {status === "found" && data.length > 0 ? (
        <>
          <div className="ds--accordion-collapse">
            <Collapse
              items={[
                {
                  key: "1",
                  label: t("PR_LEARN_MORE"),
                  children: (
                    <>
                      {" "}
                      <p
                        dangerouslySetInnerHTML={{
                          __html:
                            data.length +
                            " " +
                            t("PR_LEARN_MORE_DESCRIPTION_2", {
                              phone_number: phoneOwnerData?.name
                                ? phoneOwnerData?.name
                                : `<p class="mobile_number">${number}</p>`,
                              "was/were": verb,
                            }),
                        }}
                      ></p>
                      {dataFound && phoneOwnerData.name && (
                        <p>
                          {phoneOwnerData?.name
                            ? t("PR_POSSIBLE_PHOTOS_SUB_DESCRIPTION", {
                                phone_owner_full_name: dataFound
                                  ? renderValue(phoneOwnerData.name)
                                  : "USER",
                              })
                            : t("PR_POSSIBLE_PHOTOS_LEARN_MORE_1", {
                                phone_number: dataFound
                                  ? reportDetail?.phoneReport
                                      .phone_number_prefix +
                                    reportDetail?.phoneReport.phone_number
                                  : "USER",
                              })}
                        </p>
                      )}
                    </>
                  ),
                },
              ]}
              expandIconPosition="end"
              expandIcon={({ isActive }) => (
                <img
                  src={isActive ? accordionIcon : accordionIcon}
                  width="2em"
                  height="2em"
                  alt=""
                />
              )}
            />
          </div>
          <div className="ds-phone-slider">
            <Slider {...carouselFullWidth}>
              {data.map((item, index) => (
                <div key={index}>
                  <img
                    src={item.url}
                    alt={`${index + 1}`}
                    onError={(e) => {
                      e.target.src = noImage;
                    }}
                  />
                </div>
              ))}
            </Slider>
          </div>
          <div className="ds-phone-grid">
            {data?.slice(0, 10)?.map((item, index) => (
              <div key={index} className="grid-item">
                <img
                  src={item.url}
                  alt={`${index + 1}`}
                  onError={(e) => {
                    e.target.src = noImage;
                  }}
                />
              </div>
            ))}
          </div>
        </>
      ) : (
        <p className="data_notfound">
          <img src={nodataIcon} alt="" /> {t("PR_RECORD_NOT_FOUND")}
        </p>
      )}
    </div>
  );
};

export default PossiblePhotos;
