import { Form, Input } from "antd";
import { facebook_id } from "../../../environment";
import FacebookLogin from "react-facebook-login";
import { t } from "i18next";
import { Cookies } from "react-cookie";
import { googleOauth2 } from "../../../redux/slice/googleOauth2Slice";
import { useDispatch } from "react-redux";
import { useGoogleLogin } from "@react-oauth/google";
import { validEmail } from "../../../utils/commonUtils";
import { isTrackPage } from "../../../utils/helpers";

const RegisterPage = ({
  setError,
  isLoading,
  sawAnimation,
  setData,
  data,
  handleRegister,
  error
}) => {
  const disPatch = useDispatch();
  const cookie = new Cookies();
  const lang = cookie.get("lang");
  const signUpTerms = t("SIGNUP_TERM_2");
  var modifiedString = signUpTerms.replace(/\.(?=<\/a>)/g, "");
  const modifiedLink = modifiedString.replace(
    /<a href="https:\/\/reversly\.com\//,
    '<a href="https://reversly.com/' + lang + "/"
  );
  let emailString = t("ENTER_VALID_EMAIL");
  let modifiedEmailString = emailString.replace(
    /^./,
    emailString[0].toUpperCase()
  );

  const handleChange = ({ target: { name, value } }) => {
    setError({
      email: "",
      google: "",
      facebook: ""
    });
    setData((prev) => ({ ...prev, [name]: value.toLowerCase() }));
  };

  const handleRegisterFirst = async () => {
    setError((prev) => ({ ...prev, email: "" }));
    if (!validEmail.test(data.email)) {
      if (!data.email) {
        setError((prev) => ({
          ...prev,
          email: t("ENTER_EMAIL_RESET_PASSWORD")
        }));
      } else {
        setError((prev) => ({
          ...prev,
          email: t(modifiedEmailString)
        }));
      }
      document.getElementById("input").focus();
      return;
    }
    handleRegister(data, "email");
  };

  const responseFacebook = async (response) => {
    if (!response || response.status === "unknown" || response.error) return;
    if (!response.email) {
      setError((prev) => ({ ...prev, google: t("FACEBOOK_EMAIL_PERMISSION") }));
    } else {
      let facebookData = {
        currency: data.currency,
        language: data.language,
        email: response.email,
        symbol: data.symbol,
        external_login_type: "facebook",
        external_login_id: response.id
      };
      setData((prev) => ({
        ...prev,
        email: response.email
      }));
      await handleRegister(facebookData, "google");
    }
  };

  const googleRegister = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      let result = await disPatch(googleOauth2(codeResponse.access_token));
      if (result.type === "googleOauth2/fulfilled") {
        let googleData = {
          currency: data.currency,
          language: data.language,
          email: result.payload.email?.toLowerCase(),
          symbol: data.symbol,
          external_login_type: "google",
          external_login_id: result.payload.id
        };
        setData((prev) => ({
          ...prev,
          email: result.payload.email?.toLowerCase()
        }));
        await handleRegister(googleData, "google");
      }
    },
    onError: (error) => console.log("Login Failed:", error)
  });

  return (
    <Form>
      <div className="body-modal">
        <div className="body-modal-ceate-account">
          {sawAnimation ? (
            <p>{t("SIGN_UP_EXACT_LOCATION")}</p>
          ) : (
            <p>{t("CREATE_ACCOUNT_TITLE")}</p>
          )}
        </div>
        <div className="google-button-login space-gpay">
          <button
            className="login-with-google-btn"
            type="button"
            onClick={() => {
              googleRegister();
              setError({
                email: "",
                google: "",
                facebook: ""
              });
            }}
            style={{ marginBottom: 10 }}
          >
            {t("CONTINUE_WITH_GOOGLE")}
          </button>
          <FacebookLogin
            appId={facebook_id}
            fields="name, email"
            scope="email"
            callback={responseFacebook}
            cssClass="login-with-google-btn login-with-facebook-btn "
            textButton={t("CONTINUE_WITH_FACEBOOK")}
            isMobile="false"
            disableMobileRedirect={true}
          />
        </div>
        <div className="auth-divider">
          <span className="line left"></span>
          <span className="or-text">{t("OR_SIGNUP_WITH_EMAIL")}</span>
          <span className="line right"></span>
        </div>
        <div className="modal-login-form">
          <Input
            name="email"
            onChange={handleChange}
            id="input"
            placeholder="hello@mail.com"
            className={`input ${error.email ? "errorInput" : ""}`}
          />
          {error.email && <div style={{ color: "#ff4853" }}>{error.email}</div>}
          {error.google && (
            <div style={{ color: "#ff4853", margin: 5 }}>{error.google}</div>
          )}
          {error.facebook && (
            <div style={{ color: "#ff4853" }}>{error.facebook}</div>
          )}
        </div>
        <div className="submit-btn-modal" onClick={handleRegisterFirst}>
          <button className=" on-mobile" type="submit" disabled={isLoading}>
            {t("SIGN_UP_MODAL_SUBMIT")}
          </button>
          <button
            className="hl_cta_wrap on-desk"
            type="submit"
            disabled={isLoading}
          >
            {t("SIGN_UP_MODAL_SUBMIT")}
          </button>
        </div>
        <div className="term-checkbox">
          {!isTrackPage() && (
            <span
              dangerouslySetInnerHTML={{
                __html: modifiedLink
              }}
            ></span>
          )}
        </div>
      </div>
    </Form>
  );
};

export default RegisterPage;
