import React, { useCallback, useState } from "react";
import "./transactionDeclined.scss";
import {
  transactionsDeclinedIcon,
  visaPaymentCardImg
} from "../../assets/images";
import ModalComponent from "../../components/common/Modal/modal";
import { useTranslation } from "react-i18next";
import { Cookies } from "react-cookie";
import SolidGate from "../../../components/common/solidGate/solidGate";
import { isSupportedLang } from "../../../utils/commonUtils";
import Loader from "../../components/common/loader/loader";
import DummyLayout from "../../../components/signUpSolidGate/module/dummyLayout/dummyLayout";
import { createIntent } from "../../../phoneReport/redux/slice/solidGate/createIntentSlice";
import { useDispatch } from "react-redux";
import { is3Ds } from "../../utils/commonUtils";

const TransactionDeclinedModal = ({
  isOpen,
  onClose,
  closable,
  orderDetails,
  cardLoadIntent,
  handleSubmit,
  setCardLoadIntent
}) => {
  const { t } = useTranslation();
  const cookies = new Cookies();
  const lang = cookies.get("lang");
  const disPatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [cardType, setCardType] = useState(null);
  const [isMounted, setIsMounted] = useState(false);
  const [form, setForm] = useState(null);
  const signUpTerms = t("PR_UNLOCK_POPUP_TERM");
  const modifiedLink = signUpTerms.replaceAll(
    '<a href="https://reversly.com/',
    '<a href="https://reversly.com/' + lang + "/"
  );

  const ErrorMessage = {
    0.01: t("GENERAL_DECLINE_0.01"),
    0.02: t("ORDER_EXPIRED_0.02"),
    0.03: t("ILLEGAL_OPERATION_0.03"),
    2.06: t("INVALID_CVV2_CODE_2.06"),
    2.08: t("INVALID_CARD_NUMBER_2.08"),
    2.09: t("INVALID_EXPIRATION_DATE_2.09"),
    2.1: t("INVALID_3DS_FLOW_ON_MERCHANT_SIDE_2.1"),
    3.01: t("CARD_BLOCKED_3.01"),
    3.02: t("INSUFFICIENT_FUNDS_3.02"),
    3.03: t("PAYMENT_AMOUNT_LIMIT_EXCESS_3.03"),
    3.04: t("TRANSACTION_DECLINED_BY_ISSUER_3.04"),
    3.05: t("CALL_YOUR_BANK_3.05"),
    3.06: t("DEBIT_CARD_NOT_SUPPORTED_3.06"),
    3.07: t("CARD_BRAND_NOT_SUPPORTED_3.07"),
    3.08: t("DO_NOT_HONOR_3.08"),
    3.1: t("SUSPECTED_FRAUD_3.1"),
    4.02: t("STOLEN_CARD_4.02"),
    4.04: t("LOST_CARD_4.04"),
    4.05: t("PSP_ANTIFRAUD_4.05"),
    4.07: t("TRUSTED_ANTIFRAUD_SYSTEM_4.07"),
    5.01: t("THREE_D_SECURE_VERIFICATION_FAILED_5.01"),
    5.02: t("INVALID_CARD_TOKEN_5.02"),
    5.03: t("APPLICATION_ERROR_5.03"),
    5.04: t("MERCHANT_NOT_CONFIGURED_CORRECTLY_5.04"),
    5.08: t("INVALID_TRANSACTION_5.08"),
    6.02: t("CONNECTION_ERROR_6.02"),
    7.01: t("CARD_TOKEN_NOT_FOUND_7.01")
  };

  const handlePaymentFail = async (e) => {
    try {
      setIsLoading(true);
      const intentCreateData = {
        type: orderDetails?.orderType, // [ subscription, one_time_payment ]
        name: orderDetails?.orderTypeName,
        force3ds: is3Ds(), // for testing 3ds scenarios
        // [ phone_tracking, phone_report, premium_report, pdf_download, single_premium_report, single_download_report, single_premium_section ]
      };
      const intentResponse = await disPatch(createIntent(intentCreateData));
      if (intentResponse.type === "createIntent/fulfilled") {
        setCardLoadIntent(intentResponse?.payload?.data);
        const error = isSupportedLang(lang)
          ? ErrorMessage[e.code] || e.message
          : e.message;
        setErrorMessage(error || t("PR_SOMETHING_WENT_WRONG"));
      }
    } catch (error) {
      console.log("error from handlePaymentFail", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePaymentSuccess = (e) => {
    try {
      if (e.type === "success") {
        setIsLoading(false);
        handleSubmit(e.order.order_id);
      }
    } catch (error) {
      console.log("error from handlePaymentSuccess", error);
    }
  };

  const handleFormMounted = useCallback(() => {
    setIsMounted(true);
  }, []);

  const handleOnReadyPaymentInstance = useCallback((form) => {
    setForm(form);
  }, []);

  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={onClose}
      closable={closable}
      className="transaction-declined-modal"
      destroyOnClose={true}
    >
      {isLoading && <Loader />}
      <div className="transaction__popup">
        <div className="main_container">
          <div className="transaction_bg_wrap">
            <div className="transaction__title">
              <div className="transaction__img">
                <img
                  src={transactionsDeclinedIcon}
                  alt="Transaction Declined"
                />
              </div>
              <h2>{t("PR_TRANSACTION_DECLINED")}</h2>
              <p>{t("PR_TRANSACTION_DESCRIPTION")}</p>
            </div>
            <div className="transaction_form">
              <form action="/">
                <div className="form-container">
                  <div style={{ display: isLoading && "none" }}>
                    <SolidGate
                      merchantData={cardLoadIntent}
                      onPaymentFail={handlePaymentFail}
                      onPaymentSuccess={(e) => {
                        setIsLoading(true);
                        handlePaymentSuccess(e);
                      }}
                      onSubmitForm={() => {
                        setErrorMessage("");
                        setIsLoading(true);
                      }}
                      onGetCardDetails={(details) =>
                        setCardType(details?.card?.brand)
                      }
                      handlePaymentStatus={(e) =>
                        console.log("handlePaymentStatus", e)
                      }
                      onFormMounted={handleFormMounted}
                      onReadyPaymentInstance={handleOnReadyPaymentInstance}
                    />
                  </div>
                  {isLoading && <DummyLayout cardType={cardType} />}
                </div>
                <div className="order__field">
                  <span>{t("PR_ORDER_DETAILS")}:</span>
                  {orderDetails && (
                    <div className="order__details">
                      <span>{orderDetails?.orderName || "Order"}</span>
                      {orderDetails?.orderPrice && (
                        <strong>{orderDetails?.orderPrice || "€39.50"}</strong>
                      )}
                    </div>
                  )}
                </div>
                {errorMessage && (
                  <p className="solidGate_retry_error">{errorMessage}</p>
                )}
                {isMounted &&
                  <div className="submit_button">
                    <button
                      className="payment__Submit"
                      type="button"
                      onClick={() => {
                        form?.submit();
                      }}
                    >
                      {t("PR_SUBMIT_ORDER")}
                    </button>
                  </div>
                }
                <div className="terms__Privacy">
                  <label
                    htmlFor="Privacy-terms"
                    dangerouslySetInnerHTML={{
                      __html: modifiedLink
                    }}
                  ></label>
                </div>
                <div className="visa_card">
                  <img src={visaPaymentCardImg} alt="Visa" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </ModalComponent>
  );
};

export default TransactionDeclinedModal;
