import React, { Suspense, useState } from "react";
import ownersDetailsIcon from "../../../assets/newhome/ownersDetailsIcon.svg";
import relationshipsAssociatesIcon from "../../../assets/newhome/relationshipsAssociatesIcon.svg";
import contactInfoIcon from "../../../assets/newhome/contactInfoIcon.svg";
import socialMediaIcon from "../../../assets/newhome/socialMediaIcon.svg";
import locationIcon from "../../../assets/newhome/locationIcon.svg";
import possiblePhotosIcon from "../../../assets/newhome/possiblePhotosIcon.svg";
import educationBackgroundIcon from "../../../assets/newhome/educationBackgroundIcon.svg";
import otherInformationIcon from "../../../assets/newhome/otherInformationIcon.svg";
import ownersDetailsImagePng from "../../../assets/optimized-images/ownersDetailsImage-png.png";
import ownersDetailsImageWebp from "../../../assets/optimized-images/ownersDetailsImage-web.webp";
import ownersDetailsPhone from "../../../assets/newhome/ownersDetailsPhone.png";
import stolenDevicesIcon from "../../../assets/newhome/stolenDevicesIcon.svg";
import texCallerIcon from "../../../assets/newhome/texCallerIcon.svg";
import CallersIcon from "../../../assets/newhome/CallersIcon.svg";
import phoneScamsIcon from "../../../assets/newhome/phoneScamsIcon.svg";
import keepSafeIcon from "../../../assets/newhome/keepSafeIcon.svg";
import verifyIdentityIcon from "../../../assets/newhome/verifyIdentityIcon.svg";
import upIcon from "../../../assets/newhome/upIcon.svg";
import downIcon from "../../../assets/newhome/downIcon.svg";
import jobTitleIcon from "../../../assets/newhome/jobTitleIcon.svg";
import dataBreachesIcon from "../../../assets/newhome/dataBreachesIcon.svg";
import businessIcon from "../../../assets/newhome/businessIcon.svg";
import companyIBMIcon from "../../../assets/newhome/companyIBMIcon.svg";
import avatarProfileImag from "../../../assets/newhome/avatarProfileImag.svg";
import mileyProfileIcon from "../../../assets/newhome/mileyProfileIcon.svg";
import mileyAddreshIcon from "../../../assets/newhome/mileyAddreshIcon.svg";
import ctaMarkIcon from "../../../assets/newhome/ctaMarkIcon.svg";
import mainInstagramIcon from "../../../assets/newhome/mainInstagramIcon.svg";
import mainLinkedInIcon from "../../../assets/newhome/mainLinkedInIcon.svg";
import mainFacebookIcon from "../../../assets/newhome/mainFacebookIcon.svg";
import checkEmailIcon from "../../../assets/newhome/checkEmailIcon.svg";
import checkIcon from "../../../assets/newhome/checkIcon.svg";
import numberInformationIcon from "../../../assets/newhome/numberInformationIcon.svg";
import securityInvestigationsIcon from "../../../assets/newhome/securityInvestigationsIcon.svg";
import emailAddressesIcon from "../../../assets/newhome/emailAddressesIcon.svg";
import familySafetyIcon from "../../../assets/newhome/familySafetyIcon.svg";
import businessSecurityIcon from "../../../assets/newhome/businessSecurityIcon.svg";
import ctaPatternImg1 from "../../../assets/newhome/ctaPatternImg1.svg";
import ctaPatternImg2 from "../../../assets/newhome/ctaPatternImg2.svg";
import ctaPatternImg3 from "../../../assets/newhome/ctaPatternImg3.svg";
import ctaPatternImg4 from "../../../assets/newhome/ctaPatternImg4.svg";
import effortlessTimeSavingIcon from "../../../assets/newhome/effortlessTimeSavingIcon.svg";
import fastEfficientIcon from "../../../assets/newhome/fastEfficientIcon.svg";
import advancedFiltersIcon from "../../../assets/newhome/advancedFiltersIcon.svg";
import accordionIconBr1 from "../../../assets/newhome/accordionIconBr1.svg";
import accordionIconBr2 from "../../../assets/newhome/accordionIconBr2.svg";
import accordionIconBr3 from "../../../assets/newhome/accordionIconBr3.svg";
import accordionIconBr4 from "../../../assets/newhome/accordionIconBr4.svg";
import accordionIconBr5 from "../../../assets/newhome/accordionIconBr5.svg";
import accordionIconBr6 from "../../../assets/newhome/accordionIconBr6.svg";
import accordionIconBr7 from "../../../assets/newhome/accordionIconBr7.svg";
import accordionIconBr8 from "../../../assets/newhome/accordionIconBr8.svg";
import photosLinkedImage1 from "../../../assets/newhome/photosLinkedImage1.png";
import photosLinkedImage2 from "../../../assets/newhome/photosLinkedImage2.png";
import photosLinkedImage3 from "../../../assets/newhome/photosLinkedImage3.png";
import photosLinkedImage4 from "../../../assets/newhome/photosLinkedImage4.png";
import secReverslyReportImagePng from "../../../assets/optimized-images/secReverslyReportImage-png.png";
import secReverslyReportImageWebp from "../../../assets/optimized-images/secReverslyReportImage-webp.webp";
import PhoneInputComponent from "../../common/phoneInputComponent/phoneInputComponent";
import { t } from "i18next";
import {
  possiblePhotosBoxImg1,
  possiblePhotosBoxImg2,
  possiblePhotosBoxImg3,
  possiblePhotosBoxImg4,
} from "../../../phoneReport/assets/images";
import { useLocation } from "react-router-dom";

const NewSlickSlider = React.lazy(() =>
  import("../../newslickslider/newSlickSlider")
);

const HomePageSection = () => {
  const [collapseTab, setCollapseTab] = useState(1);
  const [accordion, setAccordion] = useState(6);
  const [FaqsTab, setFaqsTab] = useState(0);
  const { state } = useLocation();
  return (
    <>
      {/*****************************************************/}
      <div className="Sec_reversly__wrapper padding_wrap pb-0 bg-white">
        <div className="NewContainer">
          <div
            className="section__title mb-60"
            dangerouslySetInnerHTML={{
              __html: t("PR_TITLE_CAN_PROVIDE"),
            }}
          ></div>
          <div className="reversly__box_wrapper d__desktop_block">
            <div className="reversly__box_info">
              <div className="reversly__box1 wpb_box_wrapper">
                <div className="box__bg_wrapper">
                  <div className="box__reversly_icon">
                    <img
                      src={ownersDetailsIcon}
                      alt="Owners Details"
                      width={48}
                      height={48}
                    />
                  </div>
                  <div className="box__reversly_content">
                    <h3>{t("PR_OWNER_DETAILS")}</h3>
                    <ul>
                      <li>{t("PR_FULL_NAME")}</li>
                      <li>{t("PR_POSSIBLE_ASSOCIATES_AGE")}</li>
                      <li>{t("PR_GENDER")}</li>
                    </ul>
                  </div>
                  <div className="box__reversly_image">
                    <picture>
                      <source
                        srcSet={ownersDetailsImageWebp}
                        type="image/webp"
                        width={240}
                        height={105}
                      />
                      <img
                        loading="lazy"
                        src={ownersDetailsImagePng}
                        alt={t("PR_BANNER_PHONE_IMAGE_ALT")}
                        width={190}
                        height={96}
                      />
                    </picture>
                  </div>
                </div>
              </div>
              <div className="reversly__box2 wpb_box_wrapper">
                <div className="box__bg_wrapper">
                  <div className="box__reversly_icon">
                    <img
                      src={relationshipsAssociatesIcon}
                      alt="Relationships"
                      width={48}
                      height={48}
                    />
                  </div>
                  <div className="box__reversly_content">
                    <h3>{t("PR_PREMIUM_SECTION_TITLE_4")}</h3>
                    <ul>
                      <li>{t("PR_FAMILY")}</li>
                      <li>{t("PR_COLLEAGUE")}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="reversly__box_info">
              <div className="reversly__box3 wpb_box_wrapper">
                <div className="box__bg_wrapper">
                  <div className="box__reversly_icon">
                    <img
                      src={contactInfoIcon}
                      alt="Contact Info"
                      width={48}
                      height={48}
                    />
                  </div>
                  <div className="box__reversly_content">
                    <h3>{t("PR_CONTACT_INFO")}</h3>
                    <ul>
                      <li>{t("PR_PHONE_NO")}</li>
                      <li>{t("PR_EMAIL_ADDRESS")}</li>
                      <li>{t("PR_SOCIAL_PROFILES")}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="reversly__box4 wpb_box_wrapper">
                <div className="box__bg_wrapper">
                  <div className="box__reversly_icon">
                    <img
                      src={socialMediaIcon}
                      alt="Social Media"
                      width={48}
                      height={48}
                    />
                  </div>
                  <div className="box__reversly_content">
                    <h3>{t("PR_SOCIAL_MEDIA")}</h3>
                    <ul>
                      <li>{t("PR_SOCIAL_ACCOUNTS")}</li>
                      <li>{t("PR_DATING_PROFILES")}</li>
                      <li>{t("PR_PHOTOS")}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="reversly__box_info">
              <div className="reversly__box5 wpb_box_wrapper">
                <div className="box__bg_wrapper">
                  <div className="box__reversly_icon">
                    <img
                      src={locationIcon}
                      alt="Location"
                      width={48}
                      height={48}
                    />
                  </div>
                  <div className="box__reversly_content">
                    <h3>{t("PR_LOCATION_LOWER")}</h3>
                    <ul>
                      <li>{t("PR_CURRENT_ADDRESS")}</li>
                      <li>{t("PR_PAST_ADDRESS")}</li>
                      <li>{t("PR_CO_RESIDENTS")}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="reversly__box6 wpb_box_wrapper">
                <div className="box__bg_wrapper">
                  <div className="box__reversly_icon">
                    <img
                      src={possiblePhotosIcon}
                      alt="Possible Photos"
                      width={48}
                      height={48}
                    />
                  </div>
                  <div className="box__reversly_content">
                    <h3>{t("PR_POSSIBLE_PHOTOS")}</h3>
                  </div>
                  <div className="box__reversly_image wpb__reversly_image">
                    <img
                      loading="lazy"
                      src={possiblePhotosBoxImg1}
                      alt="Possible Photos"
                      width={100}
                      height={100}
                    />
                    <img
                      loading="lazy"
                      src={possiblePhotosBoxImg2}
                      alt="Possible Photos"
                      width={100}
                      height={100}
                    />
                    <img
                      loading="lazy"
                      src={possiblePhotosBoxImg3}
                      alt="Possible Photos"
                      width={100}
                      height={100}
                    />
                    <img
                      loading="lazy"
                      src={possiblePhotosBoxImg4}
                      alt="Possible Photos"
                      width={100}
                      height={100}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="reversly__box_info">
              <div className="reversly__box7 wpb_box_wrapper">
                <div className="box__bg_wrapper">
                  <div className="box__reversly_icon">
                    <img
                      src={educationBackgroundIcon}
                      alt="Education Background"
                      width={48}
                      height={48}
                    />
                  </div>
                  <div className="box__reversly_content">
                    <h3>{t("PR_EDUCATION_BG")}</h3>
                    <ul>
                      <li>{t("PR_EDUCATION_DETAILS")}</li>
                      <li>{t("PR_NAME_OF_COLLEGE")}</li>
                      <li>{t("PR_PREMIUM_SECTION_TITLE_2")}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="reversly__box8 wpb_box_wrapper">
                <div className="box__bg_wrapper">
                  <div className="box__reversly_icon">
                    <img
                      src={otherInformationIcon}
                      alt="Other Information"
                      width={48}
                      height={48}
                    />
                  </div>
                  <div className="box__reversly_content">
                    <h3>{t("PR_OTHER_INFORMATION")}</h3>
                    <ul>
                      <li>{t("PR_PREMIUM_SECTION_TEXT")}</li>
                      <li>{t("PR_PREMIUM_SECTION_TITLE_5")}</li>
                      <li>{t("PR_MORE_CAP")}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="reversly__box_wrapper phone_box_wrapper d__desktop_none">
            <div class="reversly__box_info">
              <div className="reversly__box1 wpb_box_wrapper">
                <div className="box__bg_wrapper">
                  <div className="wpb__box_inner">
                    <div className="box__reversly_icon">
                      <img
                        src={ownersDetailsIcon}
                        alt="Owners Details"
                        width={48}
                        height={48}
                      />
                    </div>
                    <div className="box__reversly_content">
                      <h3>{t("PR_OWNER_DETAILS")}</h3>
                      <ul>
                        <li>{t("PR_FULL_NAME")}</li>
                        <li>{t("PR_POSSIBLE_ASSOCIATES_AGE")}</li>
                        <li>{t("PR_GENDER")}</li>
                      </ul>
                    </div>
                  </div>
                  <div className="box__reversly_image">
                    <img
                      loading="lazy"
                      src={ownersDetailsPhone}
                      alt="Owners Details"
                      width={151}
                      height={160}
                    />
                  </div>
                </div>
              </div>
              <div className="reversly__box2 wpb_box_wrapper">
                <div className="box__bg_wrapper">
                  <div className="box__reversly_icon">
                    <img
                      src={relationshipsAssociatesIcon}
                      alt="Relationships Associates"
                      width={48}
                      height={48}
                    />
                  </div>
                  <div className="box__reversly_content">
                    <h3>{t("PR_PREMIUM_SECTION_TITLE_4")}</h3>
                    <ul>
                      <li>{t("PR_FAMILY")}</li>
                      <li>{t("PR_COLLEAGUE")}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="reversly__box3 wpb_box_wrapper">
                <div className="box__bg_wrapper">
                  <div className="box__reversly_icon">
                    <img
                      src={contactInfoIcon}
                      alt="Contact Info"
                      width={48}
                      height={48}
                    />
                  </div>
                  <div className="box__reversly_content">
                    <h3>{t("PR_CONTACT_INFO")}</h3>
                    <ul>
                      <li>{t("PR_PHONE_NO")}</li>
                      <li>{t("PR_EMAIL_ADDRESS")}</li>
                      <li>{t("PR_SOCIAL_PROFILES")}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="reversly__box6 wpb_box_wrapper">
                <div className="box__bg_wrapper">
                  <div className="box__reversly_icon">
                    <img
                      src={possiblePhotosIcon}
                      alt="Possible Photos"
                      width={48}
                      height={48}
                    />
                    <div className="box__reversly_content">
                      <h3>{t("PR_POSSIBLE_PHOTOS")}</h3>
                    </div>
                  </div>
                  <div className="box__reversly_image wpb__reversly_image">
                    <img
                      loading="lazy"
                      src={possiblePhotosBoxImg1}
                      alt="Possible Photos"
                      width={100}
                      height={100}
                    />
                    <img
                      loading="lazy"
                      src={possiblePhotosBoxImg2}
                      alt="Possible Photos"
                      width={100}
                      height={100}
                    />
                    <img
                      loading="lazy"
                      src={possiblePhotosBoxImg3}
                      alt="Possible Photos"
                      width={100}
                      height={100}
                    />
                    <img
                      loading="lazy"
                      src={possiblePhotosBoxImg4}
                      alt="Possible Photos"
                      width={100}
                      height={100}
                    />
                  </div>
                </div>
              </div>
              <div className="reversly__box4 wpb_box_wrapper">
                <div className="box__bg_wrapper">
                  <div className="box__reversly_icon">
                    <img
                      src={socialMediaIcon}
                      alt="Social Media"
                      width={48}
                      height={48}
                    />
                  </div>
                  <div className="box__reversly_content">
                    <h3>{t("PR_SOCIAL_MEDIA")}</h3>
                    <ul>
                      <li>{t("PR_SOCIAL_ACCOUNTS")}</li>
                      <li>{t("PR_DATING_PROFILES")}</li>
                      <li>{t("PR_PHOTOS")}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="reversly__box5 wpb_box_wrapper">
                <div className="box__bg_wrapper">
                  <div className="box__reversly_icon">
                    <img
                      src={locationIcon}
                      alt="Location"
                      width={48}
                      height={48}
                    />
                  </div>
                  <div className="box__reversly_content">
                    <h3>{t("PR_LOCATION_LOWER")}</h3>
                    <ul>
                      <li>{t("PR_CURRENT_ADDRESS")}</li>
                      <li>{t("PR_PAST_ADDRESS")}</li>
                      <li>{t("PR_CO_RESIDENTS")}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="reversly__box7 wpb_box_wrapper">
                <div className="box__bg_wrapper">
                  <div className="box__reversly_icon">
                    <img
                      src={educationBackgroundIcon}
                      alt="Education Background"
                      width={48}
                      height={48}
                    />
                  </div>
                  <div className="box__reversly_content">
                    <h3>{t("PR_EDUCATION_BG")}</h3>
                    <ul>
                      <li>{t("PR_EDUCATION_DETAILS")}</li>
                      <li>{t("PR_NAME_OF_COLLEGE")}</li>
                      <li>{t("PR_PREMIUM_SECTION_TITLE_2")}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="reversly__box8 wpb_box_wrapper">
                <div className="box__bg_wrapper">
                  <div className="box__reversly_icon">
                    <img
                      src={otherInformationIcon}
                      alt="Other Information"
                      width={48}
                      height={48}
                    />
                  </div>
                  <div className="box__reversly_content">
                    <h3>{t("PR_OTHER_INFORMATION")}</h3>
                    <ul>
                      <li>{t("PR_PREMIUM_SECTION_TEXT")}</li>
                      <li>{t("PR_PREMIUM_SECTION_TITLE_5")}</li>
                      <li>{t("PR_MORE_CAP")}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*****************************************************/}
      <div className="section__faqs_wrapper padding_wrap bg-white">
        <div className="NewContainer">
          <div className="section__title mb-60">
            <div
              className="section__title_wrapper"
              dangerouslySetInnerHTML={{
                __html: t("PR_TITLE_HOW_TO_USE_LOCKUP"),
              }}
            ></div>
          </div>
          <div className="ReverslyLookupHelp__area">
            <div
              className={`ReverslyLookupHelp__img ${
                window.location.pathname.split("/")[1] === "fil"
                  ? "filipino_language"
                  : ""
              }`}
            >
              <div class="full-report-box">
                <div class="bg-pattern">
                  <div class="line"></div>
                  <div class="pattern-box h-small"></div>
                  <div class="pattern-box"></div>
                  <div class="pattern-box mt-0"></div>
                </div>
                <div class="box-full-report">
                  <h3>{t("PR_FULL_REPORT")}</h3>
                  <div class="inner-check-list">
                    <ul class="report-check-list-box mt-small d-none-row">
                      <div class="list-item">
                        <li>
                          <a href="">{t("PR_OWNER_INFO")}</a>
                        </li>
                        <div class="check-box">
                          <img
                            src={checkIcon}
                            alt="check-icon"
                            width={16}
                            height={11}
                          />
                        </div>
                      </div>
                      <div class="list-item">
                        <li>
                          <a href="">{t("PR_COMPANIES_JOBS")}</a>
                        </li>
                        <div class="check-box">
                          <img
                            src={checkIcon}
                            alt="check-icon"
                            width={16}
                            height={11}
                          />
                        </div>
                      </div>
                      <div class="list-item">
                        <li>
                          <a href="">{t("PR_ASSOCIATES")}</a>
                        </li>
                        <div class="check-box">
                          <img
                            src={checkIcon}
                            alt="check-icon"
                            width={16}
                            height={11}
                          />
                        </div>
                      </div>
                      <div class="list-item">
                        <li>
                          <a href="">{t("PR_ADDRESS_HISTORY")}</a>
                        </li>
                        <div class="check-box">
                          <img
                            src={checkIcon}
                            alt="check-icon"
                            width={16}
                            height={11}
                          />
                        </div>
                      </div>
                      <div class="list-item">
                        <li>
                          <a href="">{t("PR_POSSIBLE_PHOTOS")}</a>
                        </li>
                        <div class="check-box">
                          <img
                            src={checkIcon}
                            alt="check-icon"
                            width={16}
                            height={11}
                          />
                        </div>
                      </div>
                      <div class="list-item h">
                        <li>
                          <a href="">{t("PR_OTHER_NUMBERS")}</a>
                        </li>
                        <div class="check-box">
                          <img
                            src={checkIcon}
                            alt="check-icon"
                            width={16}
                            height={11}
                          />
                        </div>
                      </div>
                    </ul>
                    <ul class="report-check-list-box mt-small">
                      <div class="list-item">
                        <li>
                          <a href="">{t("PR_SOCIAL_PROFILES")}</a>
                        </li>
                        <div class="check-box">
                          <img
                            src={checkIcon}
                            alt="check-icon"
                            width={16}
                            height={11}
                          />
                        </div>
                      </div>
                      <div class="list-item">
                        <li>
                          <a href="">{t("PR_ADDRESS_HISTORY")}</a>
                        </li>
                        <div class="check-box">
                          <img
                            src={checkIcon}
                            alt="check-icon"
                            width={16}
                            height={11}
                          />
                        </div>
                      </div>
                      <div class="list-item">
                        <li>
                          <a href="">{t("LOGIN_EMAIL_LABEL")}</a>
                        </li>
                        <div class="check-box">
                          <img
                            src={checkIcon}
                            alt="check-icon"
                            width={16}
                            height={11}
                          />
                        </div>
                      </div>
                      <div class="list-item m-h">
                        <li>
                          <a href="">{t("PR_MORE_CAP")}</a>
                        </li>
                        <div class="check-box">
                          <img
                            src={checkIcon}
                            alt="check-icon"
                            width={16}
                            height={11}
                          />
                        </div>
                      </div>
                      <div class="list-item h">
                        <li>
                          <a href="">{t("PR_RELATIONSHIPS")}</a>
                        </li>
                        <div class="check-box">
                          <img
                            src={checkIcon}
                            alt="check-icon"
                            width={16}
                            height={11}
                          />
                        </div>
                      </div>
                      <div class="list-item h">
                        <li>
                          <a href="">{t("PR_DATA_LEAK")}</a>
                        </li>
                        <div class="check-box">
                          <img
                            src={checkIcon}
                            alt="check-icon"
                            width={16}
                            height={11}
                          />
                        </div>
                      </div>
                      <div class="list-item h mw-h">
                        <li>
                          <a href="">{t("PR_MORE_CAP")}</a>
                        </li>
                        <div class="check-box">
                          <img
                            src={checkIcon}
                            alt="check-icon"
                            width={16}
                            height={11}
                          />
                        </div>
                      </div>
                    </ul>
                  </div>
                </div>
                <div class="email-box">
                  <h4>{t("LOGIN_EMAIL_LABEL")}</h4>
                  <div class="email-inner-box">
                    <div class="email-match">
                      <img
                        src={checkEmailIcon}
                        alt="Email Check Icon"
                        width={10}
                        height={10}
                      />
                      <h5>{t("PR_MATCH_EMAIL")}</h5>
                    </div>
                    <div>
                      <h3 class="h">sabrine_mont1254@gmail.com</h3>
                      <h4>sabrine_mont1254@....</h4>
                    </div>
                    <div class="email-type-box">
                      <h3 class="e-type">
                        <p class="h">{t("PR_EMAIL_TYPE")}:</p>
                      </h3>
                      <p>{t("PR_PERSONAL")}</p>
                    </div>
                  </div>
                </div>
                <div class="owner-photo-box">
                  <h3>{t("PR_PHOTOS_LINKED")}</h3>
                  <div class="inner-photos-box">
                    <div class="photo-box">
                      <img
                        loading="lazy"
                        src={photosLinkedImage1}
                        alt="p-1"
                        width={50}
                        height={50}
                      />
                    </div>
                    <div class="photo-box">
                      <img
                        loading="lazy"
                        src={photosLinkedImage2}
                        alt="p-1"
                        width={50}
                        height={50}
                      />
                    </div>
                    <div class="photo-box h">
                      <img
                        loading="lazy"
                        src={photosLinkedImage3}
                        alt="p-1"
                        width={50}
                        height={50}
                      />
                    </div>
                    <div class="photo-box h">
                      <img
                        loading="lazy"
                        src={photosLinkedImage4}
                        alt="p-1"
                        width={50}
                        height={50}
                      />
                    </div>
                  </div>
                </div>
                <div class="social-profile-box">
                  <h4>{t("PR_SOCIAL_MEDIA_PROFILES")}</h4>
                  <ul class="social-media">
                    <li class="media-box">
                      <div class="icon">
                        <img
                          src={mainFacebookIcon}
                          alt="Facebook Icon"
                          width={16}
                          height={16}
                        />
                      </div>
                      <a href="#">Sabrina Montier</a>
                    </li>
                    <li class="media-box">
                      <div class="icon">
                        <img
                          src={mainLinkedInIcon}
                          alt="LinkedIn Icon"
                          width={16}
                          height={16}
                        />
                      </div>
                      <a href="#">Sabrina J. Montier</a>
                    </li>
                    <li class="media-box">
                      <div class="icon">
                        <img
                          loading="lazy"
                          src={mainInstagramIcon}
                          alt="Instagram Icon"
                          width={16}
                          height={16}
                        />
                      </div>
                      <a href="#">@Sabi_4598</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="accordion__wrapper">
              <div className="accordion__item">
                <div
                  class={`accordion-body ${
                    collapseTab === 1 && "accordion-body-open"
                  }`}
                  onClick={() => setCollapseTab(collapseTab === 1 ? 0 : 1)}
                >
                  <div className="web__accordion_wrapper">
                    <div className="accordion__title">
                      <div className="accordion__icon">
                        {collapseTab === 1 ? (
                          <img
                            src={texCallerIcon}
                            alt="Text Callers"
                            width={40}
                            height={40}
                          />
                        ) : (
                          <img
                            src={accordionIconBr1}
                            alt="Text Callers"
                            width={40}
                            height={40}
                          />
                        )}
                      </div>
                      <h3>{t("PR_LOOKUP_HELP_TITLE_1")}</h3>
                    </div>
                    <div
                      className={`accordion__content ${
                        collapseTab === 1 ? "accordion__content_open" : ""
                      }`}
                    >
                      {collapseTab === 1 && (
                        <p>{t("PR_LOOKUP_HELP_DESCRIPTION_1")}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  class={`accordion-body ${
                    collapseTab === 2 && "accordion-body-open"
                  }`}
                  onClick={() => setCollapseTab(collapseTab === 2 ? 0 : 2)}
                >
                  <div className="web__accordion_wrapper">
                    <div className="accordion__title">
                      <div className="accordion__icon">
                        {collapseTab === 2 ? (
                          <img
                            src={accordionIconBr2}
                            alt="Spam Callers"
                            width={40}
                            height={40}
                          />
                        ) : (
                          <img
                            src={CallersIcon}
                            alt="Spam Callers"
                            width={40}
                            height={40}
                          />
                        )}
                      </div>
                      <h3>{t("PR_LOOKUP_HELP_TITLE_2")}</h3>
                    </div>

                    <div
                      className={`accordion__content ${
                        collapseTab === 2 ? "accordion__content_open" : ""
                      }`}
                    >
                      {collapseTab === 2 && (
                        <p>{t("PR_LOOKUP_HELP_DESCRIPTION_2")}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  class={`accordion-body ${
                    collapseTab === 3 && "accordion-body-open"
                  }`}
                  onClick={() => setCollapseTab(collapseTab === 3 ? 0 : 3)}
                >
                  <div className="web__accordion_wrapper">
                    <div className="accordion__title">
                      <div className="accordion__icon">
                        {collapseTab === 3 ? (
                          <img
                            src={accordionIconBr3}
                            alt="Scams Callers"
                            width={40}
                            height={40}
                          />
                        ) : (
                          <img
                            src={phoneScamsIcon}
                            alt="Scams Callers"
                            width={40}
                            height={40}
                          />
                        )}
                      </div>
                      <h3>{t("PR_LOOKUP_HELP_TITLE_3")}</h3>
                    </div>
                    <div
                      className={`accordion__content ${
                        collapseTab === 3 ? "accordion__content_open" : ""
                      }`}
                    >
                      {collapseTab === 3 && (
                        <p>{t("PR_LOOKUP_HELP_DESCRIPTION_3")}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  class={`accordion-body ${
                    collapseTab === 4 && "accordion-body-open"
                  }`}
                  onClick={() => setCollapseTab(collapseTab === 4 ? 0 : 4)}
                >
                  <div className="web__accordion_wrapper">
                    <div className="accordion__title">
                      <div className="accordion__icon">
                        {collapseTab === 4 ? (
                          <img
                            src={accordionIconBr4}
                            alt="Keep Safe"
                            width={40}
                            height={40}
                          />
                        ) : (
                          <img
                            src={keepSafeIcon}
                            alt="Keep Safe"
                            width={40}
                            height={40}
                          />
                        )}
                      </div>
                      <h3>{t("PR_LOOKUP_HELP_TITLE_4")}</h3>
                    </div>
                    <div
                      className={`accordion__content ${
                        collapseTab === 4 ? "accordion__content_open" : ""
                      }`}
                    >
                      {collapseTab === 4 && (
                        <p>{t("PR_LOOKUP_HELP_DESCRIPTION_4")}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  class={`accordion-body ${
                    collapseTab === 5 && "accordion-body-open"
                  }`}
                  onClick={() => setCollapseTab(collapseTab === 5 ? 0 : 5)}
                >
                  <div className="web__accordion_wrapper">
                    <div className="accordion__title">
                      <div className="accordion__icon">
                        {collapseTab === 5 ? (
                          <img
                            src={accordionIconBr5}
                            alt="Verify Identity"
                            width={40}
                            height={40}
                          />
                        ) : (
                          <img
                            src={verifyIdentityIcon}
                            alt="Verify Identity"
                            width={40}
                            height={40}
                          />
                        )}
                      </div>
                      <h3>{t("PR_LOOKUP_HELP_TITLE_5")}</h3>
                    </div>
                    <div
                      className={`accordion__content ${
                        collapseTab === 5 ? "accordion__content_open" : ""
                      }`}
                    >
                      {collapseTab === 5 && (
                        <p>{t("PR_LOOKUP_HELP_DESCRIPTION_5")}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*****************************************************/}
      <div className="ReverslyReport__section padding_wrap pb-0 bg-white">
        <div className="NewContainer">
          <div className="wpb__ReverslyReport">
            <div className="accordion__wrapper">
              <div className="section__title">
                <div
                  className="section__title_wrapper"
                  dangerouslySetInnerHTML={{
                    __html: t("PR_TITLE_HOW_US_SEARCH"),
                  }}
                ></div>
              </div>
              <div className="accordion__item">
                <div
                  class={`accordion-body ${
                    accordion === 6 && "accordion-body-open"
                  }`}
                  onClick={() => setAccordion(accordion === 6 ? 0 : 6)}
                >
                  <div className="web__accordion_wrapper">
                    <div className="accordion__title">
                      <div className="accordion__icon">
                        {accordion === 6 ? (
                          <img
                            src={effortlessTimeSavingIcon}
                            alt="Time Saving"
                            width={40}
                            height={40}
                          />
                        ) : (
                          <img
                            src={accordionIconBr8}
                            alt="Time Saving"
                            width={40}
                            height={40}
                          />
                        )}
                      </div>
                      <h3>{t("PR_ADVANTAGES_TITLE_1")}</h3>
                    </div>
                    <div
                      className={`accordion__content ${
                        accordion === 6 ? "accordion__content_open" : ""
                      }`}
                    >
                      {accordion === 6 && (
                        <p>{t("PR_ADVANTAGES_DESCRIPTION_1")}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  class={`accordion-body ${
                    accordion === 7 && "accordion-body-open"
                  }`}
                  onClick={() => setAccordion(accordion === 7 ? 0 : 7)}
                >
                  <div className="web__accordion_wrapper">
                    <div className="accordion__title">
                      <div className="accordion__icon">
                        {accordion === 7 ? (
                          <img
                            src={accordionIconBr7}
                            alt="Quick Efficient"
                            width={40}
                            height={40}
                          />
                        ) : (
                          <img
                            src={fastEfficientIcon}
                            alt="Quick Efficient"
                            width={40}
                            height={40}
                          />
                        )}
                      </div>
                      <h3>{t("PR_ADVANTAGES_TITLE_2")}</h3>
                    </div>
                    <div
                      className={`accordion__content ${
                        accordion === 7 ? "accordion__content_open" : ""
                      }`}
                    >
                      {accordion === 7 && (
                        <p>{t("PR_ADVANTAGES_DESCRIPTION_2")}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  class={`accordion-body ${
                    accordion === 8 && "accordion-body-open"
                  }`}
                  onClick={() => setAccordion(accordion === 8 ? 0 : 8)}
                >
                  <div className="web__accordion_wrapper">
                    <div className="accordion__title">
                      <div className="accordion__icon">
                        {accordion === 8 ? (
                          <img
                            src={accordionIconBr6}
                            alt="Advanced Filters"
                            width={40}
                            height={40}
                          />
                        ) : (
                          <img
                            src={advancedFiltersIcon}
                            alt="Advanced Filters"
                            width={40}
                            height={40}
                          />
                        )}
                      </div>
                      <h3>{t("PR_ADVANTAGES_TITLE_3")}</h3>
                    </div>
                    <div
                      className={`accordion__content ${
                        accordion === 8 ? "accordion__content_open" : ""
                      }`}
                    >
                      {accordion === 8 && (
                        <p>{t("PR_ADVANTAGES_DESCRIPTION_3")}</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ReverslyReport__img">
              <picture>
                <source srcSet={secReverslyReportImageWebp} type="image/webp" />
                <img
                  loading="lazy"
                  src={secReverslyReportImagePng}
                  width={556}
                  height={100}
                  alt=""
                />
              </picture>
            </div>
          </div>
        </div>
      </div>
      {/*****************************************************/}
      <div className="Sec_help__wrapper padding_wrap bg-white">
        <div className="NewContainer">
          <div className="section__title mb-60">
            <div
              className="section__title_wrapper"
              dangerouslySetInnerHTML={{
                __html: t("PR_TITLE_CAN_HELP_YOU"),
              }}
            ></div>
          </div>
          <div className="HelpSectionBoxWrpper">
            <div class="wplHelpSectionBox">
              <div className="HelpSectionBox1 wpb_inner_box_wrap">
                <div className="HelpSectionIcon">
                  <img
                    src={stolenDevicesIcon}
                    alt="Stolen Devices"
                    width={60}
                    height={60}
                  />
                  <h3 className="HelpSectionTitle">{t("PR_HELP_TITLE_1")}</h3>
                </div>
                <div className="HelpSectionDescription">
                  <p>{t("PR_HELP_DESCRIPTION_1")}</p>
                </div>
              </div>
            </div>
            <div class="wplHelpSectionBox">
              <div className="HelpSectionBox2 wpb_inner_box_wrap">
                <div className="HelpSectionIcon">
                  <img
                    src={numberInformationIcon}
                    alt="Number InformationIcon"
                    width={60}
                    height={60}
                  />
                  <h3 className="HelpSectionTitle">{t("PR_HELP_TITLE_2")}</h3>
                </div>
                <div className="HelpSectionDescription">
                  <p>{t("PR_HELP_DESCRIPTION_2")}</p>
                </div>
              </div>
            </div>
            <div class="wplHelpSectionBox">
              <div className="HelpSectionBox3 wpb_inner_box_wrap">
                <div className="HelpSectionIcon">
                  <img
                    src={securityInvestigationsIcon}
                    alt="Security Investigations"
                    width={60}
                    height={60}
                  />
                  <h3 className="HelpSectionTitle">{t("PR_HELP_TITLE_3")}</h3>
                </div>
                <div className="HelpSectionDescription">
                  <p>{t("PR_HELP_DESCRIPTION_3")}</p>
                </div>
              </div>
            </div>
            <div class="wplHelpSectionBox">
              <div className="HelpSectionBox4 wpb_inner_box_wrap">
                <div className="HelpSectionIcon">
                  <img
                    src={emailAddressesIcon}
                    alt="Email Addresses"
                    width={60}
                    height={60}
                    loading="lazy"
                  />
                  <h3 className="HelpSectionTitle">{t("PR_HELP_TITLE_4")}</h3>
                </div>
                <div className="HelpSectionDescription">
                  <p>{t("PR_HELP_DESCRIPTION_4")}</p>
                </div>
              </div>
            </div>
            <div class="wplHelpSectionBox">
              <div className="HelpSectionBox5 wpb_inner_box_wrap">
                <div className="HelpSectionIcon">
                  <img
                    loading="lazy"
                    src={familySafetyIcon}
                    alt="Family Safety"
                    width={60}
                    height={60}
                  />
                  <h3 className="HelpSectionTitle">{t("PR_HELP_TITLE_5")}</h3>
                </div>
                <div className="HelpSectionDescription">
                  <p>{t("PR_HELP_DESCRIPTION_5")}</p>
                </div>
              </div>
            </div>
            <div class="wplHelpSectionBox">
              <div className="HelpSectionBox6 wpb_inner_box_wrap">
                <div className="HelpSectionIcon">
                  <img
                    src={businessSecurityIcon}
                    alt="Business Security"
                    width={60}
                    height={60}
                    loading="lazy"
                  />
                  <h3 className="HelpSectionTitle">{t("PR_HELP_TITLE_6")}</h3>
                </div>
                <div className="HelpSectionDescription">
                  <p>{t("PR_HELP_DESCRIPTION_6")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*****************************************************/}
      <div className="SectionSliderWrapper padding_wrap bg-white">
        <div className="NewContainer">
          <div className="section__title mb-60">
            <h2
              className="SectionMainTitle text-left"
              dangerouslySetInnerHTML={{ __html: t("PR_REAL_REVIEWS") }}
            />
          </div>
        </div>
        <div className="SlickSliderWrapper">
          <Suspense fallback={<div>Loading...</div>}>
            <NewSlickSlider />
          </Suspense>
        </div>
      </div>
      {/*****************************************************/}
      <div className="sec__faqs__wrapper padding_wrap bg-white">
        <div className="NewContainer">
          <div className="section__title mb-60">
            <div
              className="section__title_wrapper"
              dangerouslySetInnerHTML={{
                __html: t("PR_TITLE_YOU_NEED_TO_KNOW"),
              }}
            ></div>
          </div>
          <div className="faqs_accordion">
            <div className="faqs_accordion_area">
              <div
                class={`faqs_accordion-body ${
                  FaqsTab === 1 && "faqs_accordion-body-open"
                }`}
                onClick={() => setFaqsTab(FaqsTab === 1 ? 0 : 1)}
              >
                <div className="faqs__accordion_info">
                  <div className="faqs_accordion__title">
                    <h3>{t("PR_KNOW_TITLE_1")}</h3>
                    <div className="faqs_accordion_arrow">
                      {FaqsTab === 1 ? (
                        <div className="faqs_accordion_up">
                          <img
                            src={upIcon}
                            alt="Minus"
                            width={24}
                            height={24}
                          />
                        </div>
                      ) : (
                        <div className="faqs_accordion_down">
                          <img
                            src={downIcon}
                            alt="Plus"
                            width={24}
                            height={24}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className={`accordion__content ${
                      FaqsTab === 1 ? "accordion__content_open" : ""
                    }`}
                  >
                    {FaqsTab === 1 && <p>{t("PR_KNOW_DESCRIPTION_1")}</p>}
                  </div>
                </div>
              </div>
              <div
                class={`faqs_accordion-body ${
                  FaqsTab === 2 && "faqs_accordion-body-open"
                }`}
                onClick={() => setFaqsTab(FaqsTab === 2 ? 0 : 2)}
              >
                <div className="faqs__accordion_info">
                  <div className="faqs_accordion__title">
                    <h3>{t("PR_KNOW_TITLE_2")}</h3>
                    <div className="faqs_accordion_arrow">
                      {FaqsTab === 2 ? (
                        <div className="faqs_accordion_up">
                          <img
                            src={upIcon}
                            alt="Minus"
                            width={24}
                            height={24}
                          />
                        </div>
                      ) : (
                        <div className="faqs_accordion_down">
                          <img
                            src={downIcon}
                            alt="Plus"
                            width={24}
                            height={24}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className={`accordion__content ${
                      FaqsTab === 2 ? "accordion__content_open" : ""
                    }`}
                  >
                    {FaqsTab === 2 && <p>{t("PR_KNOW_DESCRIPTION_2")}</p>}
                  </div>
                </div>
              </div>
              <div
                class={`faqs_accordion-body ${
                  FaqsTab === 3 && "faqs_accordion-body-open"
                }`}
                onClick={() => setFaqsTab(FaqsTab === 3 ? 0 : 3)}
              >
                <div className="faqs__accordion_info">
                  <div className="faqs_accordion__title">
                    <h3>{t("PR_KNOW_TITLE_3")}</h3>
                    <div className="faqs_accordion_arrow">
                      {FaqsTab === 3 ? (
                        <div className="faqs_accordion_up">
                          <img
                            src={upIcon}
                            alt="Minus"
                            width={24}
                            height={24}
                          />
                        </div>
                      ) : (
                        <div className="faqs_accordion_down">
                          <img
                            src={downIcon}
                            alt="Plus"
                            width={24}
                            height={24}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className={`accordion__content ${
                      FaqsTab === 3 ? "accordion__content_open" : ""
                    }`}
                  >
                    {FaqsTab === 3 && <p>{t("PR_KNOW_DESCRIPTION_3")}</p>}
                  </div>
                </div>
              </div>
              <div
                class={`faqs_accordion-body ${
                  FaqsTab === 4 && "faqs_accordion-body-open"
                }`}
                onClick={() => setFaqsTab(FaqsTab === 4 ? 0 : 4)}
              >
                <div className="faqs__accordion_info">
                  <div className="faqs_accordion__title">
                    <h3>{t("PR_KNOW_TITLE_4")}</h3>
                    <div className="faqs_accordion_arrow">
                      {FaqsTab === 4 ? (
                        <div className="faqs_accordion_up">
                          <img
                            src={upIcon}
                            alt="Minus"
                            width={24}
                            height={24}
                          />
                        </div>
                      ) : (
                        <div className="faqs_accordion_down">
                          <img
                            src={downIcon}
                            alt="Plus"
                            width={24}
                            height={24}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className={`accordion__content ${
                      FaqsTab === 4 ? "accordion__content_open" : ""
                    }`}
                  >
                    {FaqsTab === 4 && <p>{t("PR_KNOW_DESCRIPTION_4")}</p>}
                  </div>
                </div>
              </div>
              <div
                class={`faqs_accordion-body ${
                  FaqsTab === 5 && "faqs_accordion-body-open"
                }`}
                onClick={() => setFaqsTab(FaqsTab === 5 ? 0 : 5)}
              >
                <div className="faqs__accordion_info">
                  <div className="faqs_accordion__title">
                    <h3>{t("PR_KNOW_TITLE_5")}</h3>
                    <div className="faqs_accordion_arrow">
                      {FaqsTab === 5 ? (
                        <div className="faqs_accordion_up">
                          <img
                            src={upIcon}
                            alt="Minus"
                            width={24}
                            height={24}
                          />
                        </div>
                      ) : (
                        <div className="faqs_accordion_down">
                          <img
                            src={downIcon}
                            alt="Plus"
                            width={24}
                            height={24}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className={`accordion__content ${
                      FaqsTab === 5 ? "accordion__content_open" : ""
                    }`}
                  >
                    {FaqsTab === 5 && <p>{t("PR_KNOW_DESCRIPTION_5")}</p>}
                  </div>
                </div>
              </div>
              <div
                class={`faqs_accordion-body ${
                  FaqsTab === 6 && "faqs_accordion-body-open"
                }`}
                onClick={() => setFaqsTab(FaqsTab === 6 ? 0 : 6)}
              >
                <div className="faqs__accordion_info">
                  <div className="faqs_accordion__title">
                    <h3>{t("PR_KNOW_TITLE_6")}</h3>
                    <div className="faqs_accordion_arrow">
                      {FaqsTab === 6 ? (
                        <div className="faqs_accordion_up">
                          <img
                            src={upIcon}
                            alt="Minus"
                            width={24}
                            height={24}
                          />
                        </div>
                      ) : (
                        <div className="faqs_accordion_down">
                          <img
                            src={downIcon}
                            alt="Plus"
                            width={24}
                            height={24}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className={`accordion__content ${
                      FaqsTab === 6 ? "accordion__content_open" : ""
                    }`}
                  >
                    {FaqsTab === 6 && <p>{t("PR_KNOW_DESCRIPTION_6")}</p>}
                  </div>
                </div>
              </div>
              <div
                class={`faqs_accordion-body ${
                  FaqsTab === 7 && "faqs_accordion-body-open"
                }`}
                onClick={() => setFaqsTab(FaqsTab === 7 ? 0 : 7)}
              >
                <div className="faqs__accordion_info">
                  <div className="faqs_accordion__title">
                    <h3>{t("PR_KNOW_TITLE_7")}</h3>
                    <div className="faqs_accordion_arrow">
                      {FaqsTab === 7 ? (
                        <div className="faqs_accordion_up">
                          <img
                            src={upIcon}
                            alt="Minus"
                            width={24}
                            height={24}
                          />
                        </div>
                      ) : (
                        <div className="faqs_accordion_down">
                          <img
                            src={downIcon}
                            alt="Plus"
                            width={24}
                            height={24}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className={`accordion__content ${
                      FaqsTab === 7 ? "accordion__content_open" : ""
                    }`}
                  >
                    {FaqsTab === 7 && <p>{t("PR_KNOW_DESCRIPTION_7")}</p>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*****************************************************/}
      <div className="CallToAction__wrapper bg-white">
        <div className="NewContainer">
          <div className="CallToAction__Content">
            <div className="section__title">
              <h2 className="SectionMainTitle">{t("PR_HOW_TO_SEARCH")}</h2>
              <p className="TitleDescription">{t("PR_AVAILABLE_INFO")}</p>
              <Suspense fallback={<div>Loading...</div>}>
                <PhoneInputComponent />
              </Suspense>
            </div>
            <div className="cta__wpb_content_element">
              <div class="cta__content_element">
                <div class="wpb__details_info">
                  <div class="wpb__user_image">
                    <img
                      loading="lazy"
                      src={avatarProfileImag}
                      alt="Avatar Profile"
                      width={50}
                      height={50}
                    />
                  </div>
                  <div class="wpb__presonal_text">
                    <h4 class="wpb__name_details">
                      s.miley@ibm.com{" "}
                      <img
                        loading="lazy"
                        src={ctaMarkIcon}
                        alt="Verified"
                        width={24}
                        height={22}
                      />
                    </h4>
                    <div class="wpb__address__info">
                      <ul>
                        <li>
                          <img
                            src={mileyProfileIcon}
                            alt="Profile"
                            width={16}
                            height={16}
                          />{" "}
                          Miley Smith
                        </li>
                        <li>
                          <img
                            loading="lazy"
                            src={mileyAddreshIcon}
                            alt="Address"
                            width={16}
                            height={16}
                          />{" "}
                          Atlanta, US
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="wpb__presonal_info">
                  <div class="wpb__inner_info">
                    <div class="vc_custom_width wpb__inner_list">
                      {t("PR_PHONE_LOWER")}
                    </div>
                    <div class="vc_custom_width wpb__inner_activity">
                      212-587-8898
                    </div>
                  </div>
                  <div class="wpb__inner_info">
                    <div class="vc_custom_width wpb__inner_list">
                      {t("PR_STEP_3_STATUS")}
                    </div>
                    <div class="vc_custom_width wpb__inner_activity wpb__inner_status">
                      • {t("PR_ACTIVE")}
                    </div>
                  </div>
                  <div class="wpb__inner_info">
                    <div class="vc_custom_width wpb__inner_list">
                      {t("PR_EMAIL_TYPE_LOWER")}
                    </div>
                    <div class="vc_custom_width wpb__inner_activity">
                      <img
                        src={businessIcon}
                        alt="Business Logo"
                        width={20}
                        height={20}
                      />{" "}
                      {t("PR_BUSINESS")}
                    </div>
                  </div>
                  <div class="wpb__inner_info">
                    <div class="vc_custom_width wpb__inner_list">
                      {t("PR_DATA_BREACHES")}
                    </div>
                    <div class="vc_custom_width wpb__inner_activity">
                      <img
                        src={dataBreachesIcon}
                        alt="Data Breaches Logo"
                        width={20}
                        height={20}
                      />{" "}
                      5
                    </div>
                  </div>
                  <div class="wpb__inner_info">
                    <div class="vc_custom_width wpb__inner_list">
                      {t("PR_COMPANY")}
                    </div>
                    <div class="vc_custom_width wpb__inner_activity">
                      <img
                        src={companyIBMIcon}
                        alt="Company IBM Logo"
                        width={20}
                        height={20}
                      />{" "}
                      IBM
                    </div>
                  </div>
                  <div class="wpb__inner_info">
                    <div class="vc_custom_width wpb__inner_list">
                      {t("PR_JOB_TITLE_LOWER")}
                    </div>
                    <div class="vc_custom_width wpb__inner_activity">
                      <img
                        src={jobTitleIcon}
                        alt="jobTitleIcon"
                        width={20}
                        height={20}
                      />{" "}
                      {t("PR_SENIOR")}
                    </div>
                  </div>
                  <div class="wpb__inner_info">
                    <div class="vc_custom_width">
                      <img
                        src={ctaPatternImg1}
                        alt="Pattern Image"
                        width={63}
                        height={8}
                      />
                    </div>
                    <div class="vc_custom_width">
                      <img
                        src={ctaPatternImg2}
                        alt="Pattern Image"
                        width={105}
                        height={7}
                      />
                    </div>
                  </div>
                  <div class="wpb__inner_info">
                    <div class="vc_custom_width">
                      <img
                        src={ctaPatternImg3}
                        alt="Pattern Image"
                        width={49}
                        height={7}
                      />
                    </div>
                    <div class="vc_custom_width">
                      <img
                        src={ctaPatternImg4}
                        alt="Pattern Image"
                        width={88}
                        height={7}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePageSection;