import Payment from "@solidgate/react-sdk";
import { useRef } from "react";

const SolidGate = (props) => {
  const {
    merchantData = {},
    width = "100%",
    showApplePay = false,
    showGooglePay = false,
    onPaymentFail = () => {},
    onPaymentSuccess = () => {},
    onSubmitForm = () => {},
    onGetCardDetails = () => {},
    handlePaymentStatus = () => {},
    onFormMounted = () => { },
    onReadyPaymentInstance = () => { },
  } = props;

  const appleContainerRef = useRef(null);
  const googleContainerRef = useRef(null);

  const customStyles = {
    card_cvv: {
      span: {
        display: "none",
      }
    }
  };

  return (
    <Payment
      googlePayButtonParams={{
        allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
        enabled: showGooglePay,
        color: "black",
        type: "pay",
      }}
      applePayButtonParams={{
        enabled: showApplePay,
        integrationType: "js",
        color: "black",
        type: "check-out",
      }}
      googlePayContainerRef={googleContainerRef}
      applePayContainerRef={appleContainerRef}
      merchantData={merchantData}
      formParams={{
        allowSubmit: false,
      }}
      styles={customStyles}
      width={width}
      onFail={onPaymentFail}
      onOrderStatus={handlePaymentStatus}
      onSuccess={onPaymentSuccess}
      onSubmit={onSubmitForm}
      onCard={onGetCardDetails}
      onMounted={onFormMounted}
      onReadyPaymentInstance={onReadyPaymentInstance}
      onPaymentDetails={(e) => console.log("onPaymentDetails", e)}
      {...props}
    />
  );
};

export default SolidGate;
