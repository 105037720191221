import React, { useEffect } from "react";
import "./phoneGpsComponent.scss";
import { useDispatch } from "react-redux";
import {
  animationPendingIcon,
  confidentialSearchIcon,
  reportAvailableIcon,
  trackableIcon,
} from "../../../assets/images";
import { useTranslation } from "react-i18next";
import { formatDate, mapOptions } from "../../../utils/commonUtils";
import { GoogleMap, InfoWindowF, MarkerF } from "@react-google-maps/api";
import { connect } from "react-redux";
import { updateLocationStatus } from "../../../redux/slice/getPhoneReportDetailsSlice";
import { connectSocket } from "../../../socket";

const PhoneGpsComponent = ({
  number,
  phoneReportDetails,
  center,
  latLong,
  isLoaded,
  infoModalAddress,
  setInfoModalAddress,
  handleChangeMap,
  handleCheckSubscription,
  isAccessReportPage,
}) => {
  const { t } = useTranslation();
  const { locations } = phoneReportDetails?.phoneReport || {};
  const dispatch = useDispatch();
  useEffect(() => {
    const socket = connectSocket();
    if (locations) {
      locations.forEach((location) => {
        if (!location.status) {
          socket.on(location.id, (updatedData) => {
            dispatch(
              updateLocationStatus({ locationId: location.id, updatedData })
            );
          });
        }
      });

      // Cleanup listeners on unmount
      return () => {
        locations.forEach((location) => {
          socket.off(location.id);
        });
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations, dispatch]);
  const getUniqueKey = (marker, index) =>
    `${marker.latlng.lat}-${marker.latlng.lng}-${index}`;
  return (
    <>
      <div className="location-info-wrap white-bg-wrap" id={"phoneGpsLocation"}>
        {isAccessReportPage && (
          <div>
            <div className="wpb_element__wrapper">
              <div className="wpb__content_wrapper">
                  <div className="vc__title_element">
                      <span>{t("PR_STEP_3_TITLE_3")}</span>
                  </div>
                  <div className="vc__phone_wrapper">
                      <p><a href={`tel:${number}`}>{number}</a></p>
                      <div class="vc__reportBox__status"><img src={trackableIcon} width={12} height={12} alt={t("PR_TRACKABLE")} /><span> {t("PR_TRACKABLE")} </span></div>
                  </div>
                  <div className="vc__list_element">
                      <ul>
                          <li><img src={reportAvailableIcon} width={15} height={15} alt="Report Available Icon" />{t("PR_REPORT_AVAILABLE")}</li>
                          <li><img src={confidentialSearchIcon} width={15} height={15} alt="Confidential Search Icon" />{t("PR_CONFIDENTIAL_SEARCH")}</li>
                      </ul>
                  </div>
              </div>
            </div>
          </div>
        )}
        <div className="location_table_info">
          {!locations || locations.length <= 0 ? (
            <div
              className="defult_track_btn send_message_btn"
              onClick={() => handleCheckSubscription()}
            >
              <p>
                <a className="track_button" href="javascript:void(0)">
                  <span>
                    {t("PR_ACCESS_REPORT_TEXT_1")} {"  "}
                    <p className="send_message_btn_number">
                      {number ? number : " +1 (819) 674-0000"}
                    </p>
                  </span>{" "}
                </a>
              </p>
            </div>
          ) : (
            <table>
              <thead>
                <tr>
                  <th className="border-right">
                    {t("PR_STEP_3_DATE").toUpperCase()}
                  </th>
                  <th>{t("PR_STEP_3_STATUS").toUpperCase()}</th>
                </tr>
              </thead>
              {locations &&
                locations.map((item) => {
                  return (
                    <tbody
                      onClick={() =>
                        item.geo &&
                        handleChangeMap(
                          item.geo.lat,
                          item.geo.long,
                          item.geo.formatted
                        )
                      }
                    >
                      <tr>
                        <td className="border-right">
                          {formatDate(item.updatedAt)}
                        </td>
                        <td>
                          {item.status ? (
                            <span className="address_found">
                              {item?.geo?.formatted}
                            </span>
                          ) : (
                            <span>
                              {t("SMS_STATUS_PENDING")}...{" "}
                              <img src={animationPendingIcon} width={16} height={16} alt="Animation Pending Icon" />
                            </span>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  );
                })}
            </table>
          )}
        </div>
        <div className="location_map">
          {isLoaded && (
            <div className="dashboard-map dashboard-box">
              <div
                className="map-container"
                style={{
                  height: "500px",
                  borderRadius: "15px",
                  overflow: "hidden",
                }}
              >
                {isLoaded && (
                  <GoogleMap
                    zoom={latLong?.length > 1 ? 15 : 10}
                    center={center}
                    mapContainerStyle={{ height: "100%", width: "100%" }}
                    options={mapOptions}
                  >
                    {latLong?.map((marker, index) => {
                      const uniqueKey = getUniqueKey(marker, index);
                      return (
                        <MarkerF
                          key={index}
                          position={marker.latlng}
                          onClick={() => setInfoModalAddress(uniqueKey)}
                        >
                          {infoModalAddress === uniqueKey && (
                            <InfoWindowF
                              onCloseClick={() => setInfoModalAddress(null)}
                            >
                              <p>{marker.address}</p>
                            </InfoWindowF>
                          )}
                        </MarkerF>
                      );
                    })}
                  </GoogleMap>
                )}
              </div>
            </div>
          )}
        </div>
        {!locations ||
          (locations.length > 0 && (
            <div
              className="defult_track_btn send_message_btn"
              onClick={() => handleCheckSubscription()}
            >
              <p>
                <a className="track_button" href="javascript:void(0)">
                  <span>
                    {t("PR_ACCESS_REPORT_TEXT_1")} {"  "}
                    <p className="send_message_btn_number">
                      {number ? number : " +1 (819) 674-0000"}
                    </p>
                  </span>{" "}
                </a>
              </p>
            </div>
          ))}
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    sendLocation: state.sendLocation,
    sendDetailsData: state.sendDetailsData,
    getPhoneReportDetails: state.getPhoneReportDetails,
    callCreateFirstReport: state.callCreateFirstReport,
  };
};

export default connect(mapStateToProps)(PhoneGpsComponent);
