import { Switch } from "antd";
import React, { useEffect, useState } from "react";
import MonitoringReportModal from "../Modals/MonitoringReportModal/MonitoringReportModal";
import { useDispatch, useSelector } from "react-redux";
import Notify from "../../../../components/common/Notify/notify";
import Loader from "../../common/loader/loader";
import { getPhoneReportDetails } from "../../../redux/slice";
import { updateMonitoring } from "../../../redux/slice/updateMonitoringSlice";
import PhoneReportPayModal from "../Modals/phoneReportPayModal/phoneReportPayModal";
import { t } from "i18next";
import { CONSTANTS } from "../../../utils/constant";
import {
  checkAdminLogin,
  getTranslatedSectionName,
} from "../../../utils/commonUtils";
import { Link } from "react-router-dom";

const MonitorSwitch = ({ from }) => {
  const dispatch = useDispatch();
  const { is_monitor, phoneReport, subscription } =
    useSelector(
      (state) => state.getPhoneReportDetails?.getPhoneReportDetailsData?.data
    ) || {};
  // eslint-disable-next-line
  const pricingTitleList =
    useSelector(
      (state) => state.pricingTitleList?.priceTitleList?.data
    ) || {};

  const subscriptionData = {
    title: t("PR_UNLOCK_MONITORING_POPUP"),
    description: "PR_UNLOCK_POPUP_DESCRIPTION",
    label1: "PR_UNLOCK_POPUP_TEXT_1",
    label2: "PR_UNLOCK_POPUP_TEXT_2",
    reports: "PR_UNLOCK_POPUP_REPORTS",
  };
  const number = phoneReport?.phone_number_prefix + phoneReport?.phone_number;

  const [monitorModal, setMonitorModal] = useState(false);
  const [unlockMonitoringModal, setUnlockMonitoringModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [pricingData, setPricingData] = useState(false);
  useEffect(() => {
    if (pricingTitleList) {
      setPricingData({
        label_1:
          pricingTitleList?.sections?.[CONSTANTS.ONETIME.SINGLE_REPORT]?.amount || null,
        label_2:
          pricingTitleList?.subscriptions?.[
            CONSTANTS.SUBSCRIPTIONS.PREMIUM_REPORT
          ]?.amount || null,
        symbol:
          pricingTitleList?.subscriptions?.[
            CONSTANTS.SUBSCRIPTIONS.PREMIUM_REPORT
          ]?.symbol || "$",
      });
    }
  }, [pricingTitleList]);

  const handleMonitoringReport = async () => {
    if (isLoading) return;
    setMonitorModal(false);
    setLoading(true);
    const payload = {
      phone_report_id: phoneReport?._id,
      is_monitor: !is_monitor,
      // monitor_duration_minutes: 1 // For testing in local to run cron in given time
    };
    try {
      const res = await dispatch(updateMonitoring(payload));
      if (res.type === "updateMonitoring/fulfilled") {
        dispatch(getPhoneReportDetails(phoneReport?._id));
        Notify("success", res.payload.message, "");
      } else {
        Notify("error", res.error.message || "An error occurred", "");
      }
    } catch (error) {
      Notify("error", error.message || "Something went wrong", "");
    } finally {
      setLoading(false);
    }
  };
  const handleCheckFullReport = () => {
    if (
      phoneReport.status === "unlocked" ||
      subscription?.includes("premium_report")
    ) {
      setMonitorModal(true);
    } else {
      setUnlockMonitoringModal(true);
    }
  };
  const onChange = () => {
    if (isLoading || checkAdminLogin()) return;
    is_monitor
      ? handleMonitoringReport()
      : from === "section"
      ? handleCheckFullReport()
      : setMonitorModal(true);
  };

  return (
    <>
      {isLoading && <Loader />}
      {from === "section" ? (
        <div className="monitor_switch_btn complaints__btn" onClick={onChange}>
          <a href="javascript:void(0)" className="complaints__btn">
            {t("PR_MONITOR_SECTION_BUTTON")}
            <Switch checked={is_monitor} />
          </a>
        </div>
      ) : from === "notFound" ? (
        <div className="monitor_switch_btn complaints__btn" onClick={onChange}>
          <Link to="javascript:void(0)" className="complaints__btn">
            {t("PR_MONITOR_STATUS")}
            <Switch checked={is_monitor} />
          </Link>
        </div>
      ) : from === "reportMenu" ? (
        <Link
          to="javascript:void(0)"
          data-title="Report"
          className="menu_button"
          onClick={onChange}
        >
          <p className="monitor__switch_wrap">
            <Switch checked={is_monitor} />
          </p>
          {t("PR_MONITOR_HEADER")}
          <span>{t("PR_REPORT_HEADER")}</span>
        </Link>
      ) : null}
      {monitorModal && (
        <MonitoringReportModal
          isOpen={monitorModal}
          setMonitorModal={setMonitorModal}
          onChangeMonitor={handleMonitoringReport}
        />
      )}
      <PhoneReportPayModal
        isOpen={unlockMonitoringModal}
        onClose={() => setUnlockMonitoringModal(false)}
        subscriptionData={subscriptionData}
        sectionName={"Monitor"}
        mode={"full_report"}
        title={getTranslatedSectionName("sectionName")}
        pricingData={pricingData}
        number={number}
        monitoring={true}
      />
    </>
  );
};

export default MonitorSwitch;
