import React, {useEffect, useState, useCallback, useContext} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {connect, useDispatch} from "react-redux";

import AccessReportComponent from "./accessReport";
import {getPhoneReportDetails, sendLocation} from "../../redux/slice";
import {createFirstReport} from "../../redux/slice/createFirstReportSlice";
import PremiumPage from "../premiumPage";
import {getPricingTitleList} from "../../redux/slice/getPricingTitleListSlice";
import LocatingModal from "../../components/partials/Modals/locatingModal";
import {Cookies} from "react-cookie";
import Loader from "../../components/common/loader/loader";
import {subscriptionStatus} from "../../redux/slice/subscriptionStatusSlice";
import {cleanUpUrl} from "../../../utils/helpers";
import {
  getSubscriptionTName,
  getTranslatedSectionName,
  subscriptionType
} from "../../utils/commonUtils";
import Notify from "../../../components/common/Notify/notify";
import {addMoreReport} from "../../redux/slice/solidGate/addMoreReportSlice";
import {reactivateSubscription} from "../../redux/slice/solidGate/reactivateSubscriptionSlice";
import {createIntent} from "../../redux/slice/solidGate/createIntentSlice";
import {unlockPremiumSection} from "../../redux/slice/solidGate/unlockPremiumSectionSlice";
import {premiumSubscriptionCreate} from "../../redux/slice/solidGate/premiumSubscriptionCreateSlice";
import {unlockPremiumReport} from "../../redux/slice/solidGate/unlockPremiumReportSlice";
import {downLoadSinglePdf} from "../../redux/slice/solidGate/downLoadSinglePdfSlice";
import {pdfSubscriptionCreate} from "../../redux/slice/solidGate/pdfSubscriptionCreateSlice";
import TransactionDeclinedModal from "../transactionDeclined/transactionDeclined";
import {useProductPriceByKey} from "../../../components/signUpSolidGate/module/helpers";
import {ProcessModalContext} from "../../context/processModalProvider";
import PhoneNumberAttributeDetails from "../../components/partials/Modals/phoneNumberAttributeDetails/phoneNumberAttributeDetails";
import {additionalInfoStatus} from "../../redux/slice/additionalInfoStatusSlice";

const AccessReport = ({
  callGetPhoneReportDetails,
  phoneReportDetails,
  callGetPricing,
  callCheckSubscriptionStatus,
  callAddMoreReport,
  callReactivateSubscription,
  callUnlockPremiumSection,
  callPremiumSubscriptionCreate,
  callUnlockPremiumReport,
  callDownloadSinglePdf,
  callPdfSubscriptionCreate,
  callAdditionalInfoStatus
}) => {
  const navigate = useNavigate();
  const [searchparams] = useSearchParams();
  const searchNumber = Object.fromEntries([...searchparams]);
  const cookies = new Cookies();
  const lang = cookies.get("lang");
  const step = cookies.get("step");
  const disPatch = useDispatch();
  const {openProcessModal} = useContext(ProcessModalContext);

  const redirect3dsURL = Object.fromEntries(
    new URLSearchParams(window.location.search)
  );
  const {
    type,
    sname: subscriptionName,
    aname: apiName,
    expire_at,
    order_id,
    status,
    id,
    section
  } = redirect3dsURL || {};
  const orderPrice = useProductPriceByKey(subscriptionName);

  const [isLoading, setIsLoading] = useState(true);
  const [isComponentDecided, setIsComponentDecided] = useState(false);
  const [openLocateModal, setOpenLocateModal] = useState(false);
  const [phoneReportModalShow, setPhoneReportModalShow] = useState(false);
  const [defaultAccess, setDefaultAccess] = useState(false);
  const [fullAccess, setFullAccess] = useState(false);
  const [number, setNumber] = useState("");
  const [countryCode, setCountryCode] = useState(+1);
  const [phoneReportId, setPhoneReportId] = useState(0);
  const [cardLoadIntent, setCardLoadIntent] = useState(null);
  const [retryModal, setRetryModal] = useState(false);
  const [infoModal, setInfoModal] = useState(false);
  const [retryOrderId, setRetryOrderId] = useState(null);
  const [processLoading, setProcessLoading] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, []);

  // Access Class
  useEffect(() => {
    const url = window.location.pathname;
    if (url.includes("access-report") && fullAccess) {
      const root = document.getElementById("root");
      root.classList.add("access_inner_wrapper_mrg_sec");
    } else {
      const root = document.getElementById("root");
      root.classList.remove("access_inner_wrapper_mrg_sec");
    }
    // eslint-disable-next-line
  }, [window.location.pathname, fullAccess]);

  const fetchPhoneReportDetails = useCallback(
    async (id) => {
      setIsLoading(true);
      const reportId = searchNumber.id || phoneReportId || id;
      if (reportId) {
        setPhoneReportId(reportId);
        try {
          const response = await callGetPhoneReportDetails(reportId);
          if (response.type === "getPhoneReportDetails/fulfilled") {
            const status = response?.payload?.data?.phoneReport?.status;
            const isPhoneReport = response?.payload?.data?.isPhoneReport;
            if (
              status === "unlocked" ||
              status === "partially" ||
              (status === "locked" && isPhoneReport)
            ) {
              setFullAccess(true);
              setDefaultAccess(false);
            } else if (status === "locked" && !isPhoneReport) {
              setDefaultAccess(true);
            } else {
              setDefaultAccess(true);
            }

            setIsComponentDecided(true);
          } else {
            navigate(`/${lang}/dashboard`);
          }
        } catch (error) {
          console.error("Error fetching phone report details:", error);
        } finally {
          setIsLoading(false);
        }
      }
    },
    // eslint-disable-next-line
    [searchNumber.id, phoneReportId, callGetPhoneReportDetails]
  );

  useEffect(() => {
    if (searchNumber.id) {
      fetchPhoneReportDetails(searchNumber.id);
    } else {
      setDefaultAccess(true);
      setIsLoading(false);
      setIsComponentDecided(true);
      if (!openLocateModal && step) {
        navigate(`/${lang}/dashboard`);
      }
    }
    // eslint-disable-next-line
  }, [searchNumber.id]);

  useEffect(() => {
    callGetPricing();
  }, [callGetPricing]);

  const closeLocatingModal = () => {
    setOpenLocateModal(false);
    if (!phoneReportId) {
      navigate(`/${lang}/dashboard`);
    }
  };

  const handleClearURl = () => {
    const cleanedUrl = cleanUpUrl(window.location.href);
    window.history.pushState(null, "", cleanedUrl);
  };

  const callActionAPI = {
    "reactivate-cancel": callReactivateSubscription,
    "single-premium-section": callUnlockPremiumSection,
    "single-premium-report": callUnlockPremiumReport,
    "premium-report": callPremiumSubscriptionCreate,
    "single-download-report": callDownloadSinglePdf,
    "pdf-download": callPdfSubscriptionCreate,
    "add-more-report": callAddMoreReport
  };

  const createPayload = (type, orderId) => ({
    type,
    order_id: orderId,
    subscription_name:
      apiName === "reactivate-cancel" ? subscriptionName : undefined,
    phone_report_id: apiName !== "reactivate-cancel" ? id : undefined,
    section_name: apiName === "single-premium-section" ? section : undefined
  });

  const handle3ds = async () => {
    try {
      setProcessLoading(true);
      const currentTime = Math.floor(Date.now() / 1000);
      if (currentTime > expire_at) {
        Notify("error", "The URL has expired. Please try again.", "");
        handleClearURl();
        setRetryModal(false);
        return;
      }
      if (type === "fail") {
        const intentResponse = await disPatch(
          createIntent({
            type: subscriptionType(subscriptionName),
            name: subscriptionName
          })
        );
        if (intentResponse.type === "createIntent/fulfilled") {
          setCardLoadIntent(intentResponse.payload?.data);
          setRetryModal(true);
        } else {
          Notify("error", intentResponse?.error?.message, "");
        }
        return;
      }
      const isAnyFieldEmpty = Object.values(
        phoneReportDetails?.getPhoneReportDetailsData?.data?.phoneReport
          ?.additional_details || {}
      ).some((value) => !value?.trim());
      const detailSection = [
        "single-premium-section",
        "single-premium-report",
        "premium-report"
      ];
      if (isAnyFieldEmpty && detailSection.includes(apiName)) {
        const payload3DS = createPayload("3ds_confirm", order_id);
        let payload = {
          phone_report_id: id,
          apiPayload: payload3DS,
          apiName: subscriptionName
        };
        callAdditionalInfoStatus(payload);
        setInfoModal(true);
      } else {
        const payload3DS = createPayload("3ds_confirm", order_id);
        const action = callActionAPI[apiName];
        const res = await action(payload3DS);
        if (res?.type?.endsWith("fulfilled")) {
          if (
            detailSection.includes(apiName) &&
            res?.payload?.data?.eventObj?.events
          ) {
            openProcessModal(id);
            handleClearURl();
          } else if (
            subscriptionName === "phone_report" &&
            apiName === "reactivate-cancel"
          ) {
            fetchPhoneReportDetails(id);
          } else {
            Notify("success", res?.payload?.message, "");
            await callGetPhoneReportDetails(id);
          }
        } else {
          Notify("error", res?.error?.message, "");
        }
        handleClearURl();
      }
    } catch (err) {
      console.error("Intent creation failed:", err);
    } finally {
      setProcessLoading(false);
    }
  };

  const onRetry = async (orderId) => {
    try {
      setRetryModal(false);
      setProcessLoading(true);
      const isAnyFieldEmpty = Object.values(
        phoneReportDetails?.getPhoneReportDetailsData?.data?.phoneReport
          ?.additional_details || {}
      ).some((value) => !value?.trim());
      const detailSection = [
        "single-premium-section",
        "single-premium-report",
        "premium-report"
      ];
      if (isAnyFieldEmpty && detailSection.includes(apiName)) {
        const retry3dsPayload = createPayload("transaction_decline", orderId);
        let payload = {
          phone_report_id: id,
          apiPayload: retry3dsPayload,
          apiName: subscriptionName
        };
        callAdditionalInfoStatus(payload);
        setInfoModal(true);
        setRetryOrderId(orderId);
      } else {
        const retry3dsPayload = createPayload("transaction_decline", orderId);
        const action = callActionAPI[apiName];
        const res = await action(retry3dsPayload);
        if (res?.type?.endsWith("fulfilled")) {
          if (
            detailSection.includes(apiName) &&
            res?.payload?.data?.eventObj?.events
          ) {
            openProcessModal(id);
            handleClearURl();
          } else if (
            subscriptionName === "phone_report" &&
            apiName === "reactivate-cancel"
          ) {
            fetchPhoneReportDetails(id);
          } else {
            Notify("success", res?.payload?.message, "");
            await callGetPhoneReportDetails(id);
          }
        } else {
          Notify("error", res?.error?.message, "");
        }
        handleClearURl();
      }
    } catch (error) {
      console.log("error from retry function", error);
    } finally {
      setProcessLoading(false);
    }
  };

  const onSubmitSubmitInfoAction = async () => {
    try {
      console.log("coming here");
      const OrderId = retryOrderId || order_id;
      const infoPayload = createPayload("3ds_confirm", OrderId);
      const action = callActionAPI[apiName];
      const res = await action(infoPayload);
      if (res?.type?.endsWith("fulfilled")) {
        if (
          [
            "single-premium-section",
            "single-premium-report",
            "premium-report"
          ].includes(apiName) &&
          res?.payload?.data?.eventObj?.events
        ) {
          openProcessModal(id);
        } else {
          Notify("success", res?.payload?.message, "");
          callGetPhoneReportDetails(id);
        }
      } else {
        Notify("error", res?.error?.message, "");
      }
    } catch (error) {
      console.log("error from onSubmitSubmitInfoAction 3ds", error);
    } finally {
      setRetryOrderId(null);
      handleClearURl();
    }
  };

  const orderDetails = {
    orderName: section
      ? getTranslatedSectionName(section)
      : getSubscriptionTName(subscriptionName),
    orderPrice: orderPrice || null,
    orderType: subscriptionType(subscriptionName),
    orderTypeName: subscriptionName || null
  };

  useEffect(() => {
    if (isComponentDecided) {
      if (Object.keys(redirect3dsURL).length > 4) {
        handle3ds();
      }
    }
  }, [isComponentDecided]);

  return (
    <>
      {processLoading && !phoneReportDetails.isLoading && <Loader />}
      {isLoading || !isComponentDecided ? (
        <Loader />
      ) : (
        (() => {
          const props = {
            setOpenLocateModal: setOpenLocateModal,
            senderNumber: number,
            phoneReportModalShow: phoneReportModalShow,
            searchNumber: searchNumber,
            fetchPhoneReportDetails: fetchPhoneReportDetails,
            phoneReportDetails:
              phoneReportDetails?.getPhoneReportDetailsData?.data,
            countryCode: countryCode,
            phoneReportId: phoneReportId,
            callCheckSubscriptionStatus: callCheckSubscriptionStatus,
            setPhoneReportModalShow: setPhoneReportModalShow
          };

          if (defaultAccess) {
            return <AccessReportComponent {...props} />;
          } else if (fullAccess) {
            return <PremiumPage {...props} />;
          } else {
            return <AccessReportComponent {...props} />;
          }
        })()
      )}
      <LocatingModal
        isOpen={openLocateModal}
        setIsOpen={setOpenLocateModal}
        onClose={closeLocatingModal}
        setNumber={setNumber}
        number={number}
        countryCode={countryCode}
        setCountryCode={setCountryCode}
        setPhoneReportId={setPhoneReportId}
        phoneReportId={phoneReportId}
        setPhoneReportModalShow={setPhoneReportModalShow}
      />
      {retryModal && (
        <TransactionDeclinedModal
          isOpen={retryModal}
          onClose={() => {
            setRetryModal(false);
            handleClearURl();
          }}
          closable={false}
          handleSubmit={onRetry}
          orderDetails={orderDetails}
          cardLoadIntent={cardLoadIntent}
          setCardLoadIntent={setCardLoadIntent}
        />
      )}
      {infoModal && (
        <PhoneNumberAttributeDetails
          isOpen={infoModal}
          onClose={() => {
            setInfoModal(false);
          }}
          handlePayment={onSubmitSubmitInfoAction}
          setIsLoading={setProcessLoading}
        />
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    sendLocation: state.sendLocation,
    sendDetailsData: state.sendDetailsData,
    phoneReportDetails: state.getPhoneReportDetails
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    callGetPhoneReportDetails: (data) => dispatch(getPhoneReportDetails(data)),
    callCreateFirstReport: (data) => dispatch(createFirstReport(data)),
    callGetPricing: (data) => dispatch(getPricingTitleList(data)),
    callSendLocation: (data) => dispatch(sendLocation(data)),
    callCheckSubscriptionStatus: (data) => dispatch(subscriptionStatus(data)),
    callAddMoreReport: (data) => dispatch(addMoreReport(data)),
    callReactivateSubscription: (data) =>
      dispatch(reactivateSubscription(data)),
    callUnlockPremiumSection: (data) => dispatch(unlockPremiumSection(data)),
    callPremiumSubscriptionCreate: (data) =>
      dispatch(premiumSubscriptionCreate(data)),
    callUnlockPremiumReport: (data) => dispatch(unlockPremiumReport(data)),
    callDownloadSinglePdf: (data) => dispatch(downLoadSinglePdf(data)),
    callPdfSubscriptionCreate: (data) => dispatch(pdfSubscriptionCreate(data)),
    callAdditionalInfoStatus: (data) => dispatch(additionalInfoStatus(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessReport);
