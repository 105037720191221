import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../../../utils/axios";

export const userList = createAsyncThunk(
  "userList",
  async ({ page, recordsPerPage, searchValue }) => {
    const encodedSearchValue = encodeURIComponent(searchValue);
    try {
      const response = await AxiosInstance.get(
        `/admin/list_user?page=${page}&limit=${recordsPerPage}&search=${encodedSearchValue}`
      );
      return response.data; 
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const userListSlice = createSlice({
  name: "userList",
  initialState: {
    isLoading: false,
    userList: [], 
    isError: false,
    errorMessage: "",
    page: 1,
    pageSize: 10, 
    searchValue: "", 
    totalPages: 0,
    totalRecords: 0,
  },
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setPageSize: (state, action) => {
      state.pageSize = action.payload;
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    resetState: (state) => {
      state.page = 1;
      state.pageSize = 10;
      state.searchValue = "";
      state.userList = [];
      state.isLoading = false;
      state.isError = false;
      state.errorMessage = "";
      state.totalPages = 0;
      state.totalRecords = 0;
    },
  },
  extraReducers: {
    [userList.pending]: (state) => {
      state.isLoading = true;
    },
    [userList.fulfilled]: (state, action) => {
      const { users, pagination } = action.payload.data;
      state.isLoading = false;
      state.userList = users; 
      state.totalPages = pagination.totalPages; 
      state.totalRecords = pagination.totalRecords; 
      state.isError = false;
      state.errorMessage = "";
    },
    [userList.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },
  },
});

export const { setPage, setPageSize, setSearchValue, resetState} = userListSlice.actions;
export default userListSlice.reducer;
