import React, { lazy, Suspense, useEffect } from "react";
import {Route, useLocation, useNavigate} from "react-router-dom";
import {bigLanguages} from "./utils/commonUtils";
import { useDispatch } from "react-redux";
import { subscriptionList } from "./redux/slice/solidGate/subscriptionsListSlice.js";
import { Cookies } from "react-cookie";
const AccessReport = lazy(() => import("./pages/accessReport"));
const MyAccount = lazy(() => import("./pages/myAccount"));
const PhoneReportDashboard = lazy(() => import("./pages/PhoneReportDashboard"));
const ContactUs = lazy(() => import("./pages/contactUs"));
const FaqComponent = lazy(() => import("./pages/faq/faqComponent.jsx"));
const LocationTrack = lazy(() => import("./pages/locationTrack"));

const PhoneReportsRoutes = () => {
  const location = useLocation();
  const disPatch = useDispatch();
  const navigate = useNavigate();
  const cookies = new Cookies();

  const lang = cookies.get('lang')
  const token = cookies.get('token')

  useEffect(() => {
    const pathnameParts = location.pathname.split("/");
    const currentLang = pathnameParts[1];
    // Apply RTL direction and unique class if the language is "ar" or "he"
    const rootElementRoot = document.getElementById("root");
    if (bigLanguages.includes(currentLang)) {
      rootElementRoot.classList.add("high__lang_wrapper");
    } else {
      rootElementRoot.classList.remove("high__lang_wrapper");
    }
  }, [location.pathname]);
  const handleCheckSubscription = async () => {
    const subscriptionListData = await disPatch(subscriptionList());
    const data = subscriptionListData?.payload?.data
    const expiredSubscription = data?.find((subscription)=> subscription.status === 'redemption')
    if (expiredSubscription) {
      navigate(`/${lang}/my-account`)
    }
  }
  
  useEffect( ()=>{
    if (token) {
      handleCheckSubscription()
    }
  },[])

  return (
    <>
      <Route
        path="/:lang/access-report"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <AccessReport />
          </Suspense>
        }
      />
      <Route
        path="/:lang/my-account"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <MyAccount />
          </Suspense>
        }
      />
      <Route
        path="/:lang/dashboard"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <PhoneReportDashboard />
          </Suspense>
        }
      />
      <Route
        path="/:lang/contact-us"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <ContactUs />
          </Suspense>
        }
      />
      <Route
        path="/:lang/faqs"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <FaqComponent />
          </Suspense>
        }
      />
      <Route
        path="/:lang/location"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <LocationTrack />
          </Suspense>
        }
      />
    </>
  );
};

export default PhoneReportsRoutes;
