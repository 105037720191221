import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Cookies } from "react-cookie";
import { t } from "i18next";
import { CloseOutlined as IoMdClose } from "@ant-design/icons";
import { connect } from "react-redux";

import ModalComponent from "../../../common/Modal/modal";
import Notify from "../../../../../components/common/Notify/notify";
import { accessReportsImage, checkMarkIcon } from "../../../../assets/images";
import "./phoneReportModal.scss";
import { reportSubscriptionCreate } from "../../../../redux/slice/solidGate/reportSubscriptionCreateSlice";
import { useUserIp } from "../../../../../utils/commonUtils";

const PhoneReportModal = ({
  isOpen,
  setPhoneReportModalOpen,
  number,
  callReportSubscriptionCreate,
  countryCode,
  setIsOpenAnimation,
  fetchPhoneReportDetails,
  phoneReportDetails,
  pricingTitleList,
  setPhoneReportModalShow,
}) => {
  const [showError, setShowError] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const price = pricingTitleList?.subscriptions.phone_report;
  const cookies = new Cookies();
  const lang = cookies.get("lang");
  const navigate = useNavigate();
  const [searchparams] = useSearchParams();
  const searchNumber = Object.fromEntries([...searchparams]);
  const phoneReportId =
    phoneReportDetails?.getPhoneReportDetailsData?.data.phoneReport?._id ||
    searchNumber.id;
  const userIp = useUserIp();

  const onClose = () => {
    setIsChecked(false);
    setPhoneReportModalOpen(false);
    setPhoneReportModalShow(false);
  };

  const handleOnClickOfSeeReport = async () => {
    if (isLoading) return;
    setIsLoading(true);
    if (!isChecked) {
      setShowError(true);
      setIsLoading(false);
      return;
    }
    try {
      onClose();
      setIsOpenAnimation(true);
      const data = { phone_report_id: phoneReportId, ip_address: userIp };
      const result = await callReportSubscriptionCreate(data);
      if (result.type === "reportSubscriptionCreate/fulfilled") {
        await new Promise((resolve) => setTimeout(resolve, 10000));
        await fetchPhoneReportDetails();
        setIsOpenAnimation(false);
        Notify("success", result.payload.message, "");
        const formattedCountryCode =
          countryCode === 1 ? "+1" : countryCode.replace(" ", "");
        navigate(
          `/${lang}/access-report?D=${formattedCountryCode}&n=${searchNumber.n}&id=${phoneReportId}`
        );
      } else {
        Notify("error", result.error.message || "An error occurred", "");
        setIsOpenAnimation(false);
      }
    } catch (error) {
      console.warn("error", JSON.stringify(error));
    } finally {
      setIsLoading(false);
      setIsOpenAnimation(false);
    }
  };

  return (
    <ModalComponent
      isOpen={isOpen}
      onClose={onClose}
      closable={false}
      className="location__modal_wrapper"
    >
      <div className="lp__report_popup">
        <div className="wpb_popup_body">
          <div className="number__location_wrapper">
            <p>
              {t("LOCATING")} <p>{number}</p> ...
            </p>
          </div>
          <div className="popup_body_description">
            <p>
              {t("PR_ACCESS_REPORT_MODAL_TITLE_1")}{" "}
              <p className="mobile_number">{number}</p>{" "}
              {t("PR_ACCESS_REPORT_POPUP_TITLE_2")}
            </p>
            {/* <p>{t("PR_REPORT_POPUP_DESCRIPTION_1")}</p> */}
          </div>
          <div className="popup_body_image">
            <img
              src={accessReportsImage}
              width={420}
              height={200}
              alt="Access Reports Image"
            />
            {/* <p className="dynemic__number_wrap">{number}</p> */}
          </div>
          <div className="popup_body_listing">
            <div className="popup__title">
              <h2>{t("PR_ACCESS_REPORT_TITLE_3")}</h2>
            </div>
            <div className="report--listing">
              <div className="report--listing-box">
                <img
                  src={checkMarkIcon}
                  width={20}
                  height={20}
                  alt="Access Reports Image"
                />
                <span>{t("PR_OWNER_INFORMATION")}</span>
              </div>
              <div className="report--listing-box">
                <img
                  src={checkMarkIcon}
                  width={20}
                  height={20}
                  alt="Access Reports Image"
                />
                <span>{t("PR_SOCIAL_MEDIA_PROFILES")}</span>
              </div>
              <div className="report--listing-box">
                <img
                  src={checkMarkIcon}
                  width={20}
                  height={20}
                  alt="Access Reports Image"
                />
                <span>{t("PR_PHOTO_VIDEO")}</span>
              </div>
              <div className="report--listing-box">
                <img
                  src={checkMarkIcon}
                  width={20}
                  height={20}
                  alt="Access Reports Image"
                />
                <span>{t("PR_DATING_PROFILES")}</span>
              </div>
              <div className="report--listing-box">
                <img
                  src={checkMarkIcon}
                  width={20}
                  height={20}
                  alt="Access Reports Image"
                />
                <span>{t("PR_ADDRESS_HISTORY")}</span>
              </div>
              <div className="report--listing-box">
                <img
                  src={checkMarkIcon}
                  width={20}
                  height={20}
                  alt="Access Reports Image"
                />
                <span>{t("PR_ONLINE_ACTIVITY")}</span>
              </div>
              <div className="report--listing-box">
                <img
                  src={checkMarkIcon}
                  width={20}
                  height={20}
                  alt="Access Reports Image"
                />
                <span>{t("PR_ASSOCIATES")}</span>
              </div>
              <div className="report--listing-box">
                <img
                  src={checkMarkIcon}
                  width={20}
                  height={20}
                  alt="Access Reports Image"
                />
                <span>{t("PR_EMAIL_ADDRESS")}</span>
              </div>
              <div className="report--listing-box">
                <img
                  src={checkMarkIcon}
                  width={20}
                  height={20}
                  alt="Access Reports Image"
                />
                <span>{t("PR_PERSONNAL_DATA_LEAKS")}</span>
              </div>
              <div className="report--listing-box">
                <img
                  src={checkMarkIcon}
                  width={20}
                  height={20}
                  alt="Access Reports Image"
                />
                <span>{t("PR_COMPANIES_JOBS")}</span>
              </div>
              <div className="report--listing-box">
                <img
                  src={checkMarkIcon}
                  width={20}
                  height={20}
                  alt="Access Reports Image"
                />
                <span>{t("PR_REPORT_POPUP_TEXT_6")}</span>
              </div>
              <div className="report--listing-box">
                <img
                  src={checkMarkIcon}
                  width={20}
                  height={20}
                  alt="Access Reports Image"
                />
                <span className="report__listing_blod">
                  {t("PR_MUCH_MORE")}
                </span>
              </div>
            </div>
            <div className="report-btn-wrap">
              <button
                onClick={() => handleOnClickOfSeeReport()}
                className={"access__btn_wrap"}
                disable={isLoading}
              >
                <p>{t("PR_ACCESS_VIEW_REPORT")}</p>
              </button>
            </div>
            <div className="thanks_btn">
              <a onClick={onClose} href="#">
                {t("PR_DENIED_BUTTON")}
              </a>
            </div>
            <div className="popup_checkbox popup_radio_btn">
              {showError && (
                <p className="invalid-number-error">
                  {t("PR_REPORT_POPUP_ERROR")}
                  <IoMdClose onClick={() => setShowError(false)} />{" "}
                </p>
              )}
              <input
                type="checkbox"
                id="popup_radio"
                name="Policy_terms"
                checked={isChecked}
                onChange={(e) => {
                  const checked = e.target.checked;
                  setIsChecked(checked);
                  setShowError(!checked);
                }}
                className={showError ? "checkbox-error" : ""}
              />
              <label htmlFor="popup_radio">
                {t("PR_TERM", {
                  price: price?.symbol + price?.amount,
                })}
              </label>
            </div>
          </div>
        </div>
      </div>
    </ModalComponent>
  );
};

const mapStateToProps = (state) => {
  return {
    phoneReportDetails: state.getPhoneReportDetails,
    pricingTitleList: state.pricingTitleList?.priceTitleList?.data,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    callReportSubscriptionCreate: (data) =>
      dispatch(reportSubscriptionCreate(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PhoneReportModal);
