import React, { useState } from "react";
import ResetPasswordComponent from "./resetPassword";
import { resetPassword } from "../../redux/slice/resetPasswordSlice";
import { connect } from "react-redux";
import Notify from "../common/Notify/notify";
import { useNavigate } from "react-router-dom";
import { trimValue, validEmail } from "../../utils/commonUtils";
import { useTranslation } from "react-i18next";
import { Cookies } from "react-cookie";

const ResetPassword = (props) => {
  const {callResetPassword, resetPasswordData} = props;
  const [formErrors, setFormErrors] = useState({});
  const [data, setData] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const cookies = new Cookies();
  const lang = cookies.get("");
  const {t} = useTranslation();
  let emailString = t("ENTER_VALID_EMAIL");
  let modifiedEmailString = emailString.replace(
    /^./,
    emailString[0].toUpperCase()
  );
  const handleReset = async () => {
    const errors = {};
    if (!validEmail.test(data)) {
      if (!data) {
        errors.email = t("ENTER_EMAIL_RESET_PASSWORD");
      } else {
        errors.email = modifiedEmailString;
      }
    }
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      const result = await callResetPassword(data);
      if (result.type === "resetPassword/rejected") {
        setError(result.error.message);
      }
      if (result.type === "resetPassword/fulfilled") {
        Notify("success", t("NOTIFY_RESET_PASSWORD"), "");
        navigate(`/${lang}/`);
      }
    }
  };
  const handleOnChange = (event) => {
    setFormErrors({});
    setData(trimValue(event.target.value).toLowerCase());
  };
  return (
    <ResetPasswordComponent
      handleReset={handleReset}
      handleOnChange={handleOnChange}
      data={data}
      formErrors={formErrors}
      error={error}
      isLoading={resetPasswordData?.isLoading}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    resetPasswordData: state.resetPasswordData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    callResetPassword: (data) => dispatch(resetPassword(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
