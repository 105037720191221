import ModalComponent from "../../../common/Modal/modal";
import React, { useEffect, useState } from "react";
import "../phoneReportPayModal/phoneReportPayModal.scss";
import { useTranslation } from "react-i18next";
import UnlockAnimation from "../../../../lottieAnimations/UnlockLottieAnimation";
import { connect } from "react-redux";
import downloadImage from "../../../../assets/images/downloadPDF.svg";
import { generatePdfBlob, is3Ds } from "../../../../utils/commonUtils";
import Notify from "../../../../../components/common/Notify/notify";
import { uploadPdf } from "../../../../redux/slice/uploadPdfSlice";
import TransactionDeclinedModal from "../../../../pages/transactionDeclined/transactionDeclined";
import { getPhoneReportDetails } from "../../../../redux/slice";
import { Cookies } from "react-cookie";
import { useUserIp } from "../../../../../utils/commonUtils";
import { downLoadSinglePdf } from "../../../../redux/slice/solidGate/downLoadSinglePdfSlice";
import { pdfSubscriptionCreate } from "../../../../redux/slice/solidGate/pdfSubscriptionCreateSlice";
import { CONSTANTS } from "../../../../utils/constant";

const DownloadReportPayModal = (props) => {
  const {
    isOpen,
    onClose,
    // API
    phoneReportDetails,
    callUploadPdf,
    pricingData,
    callGetPhoneReportDetails,
    callDownloadSinglePdf,
    callPdfSubscriptionCreate
  } = props;

  const { t } = useTranslation();
  const { phoneReport } = phoneReportDetails || {};
  const cookies = new Cookies();
  const lang = cookies.get("lang");
  const signUpTerms = t("PR_UNLOCK_POPUP_TERM");
  const modifiedLink = signUpTerms.replaceAll(
    '<a href="https://reversly.com/',
    '<a href="https://reversly.com/' + lang + "/"
  );
  const userIp = useUserIp();

  const [selectedOption, setSelectedOption] = useState(
    "download_unlimited_report"
  );
  const [amount, setAmount] = useState(1785);
  const [retryModal, setRetryModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [termStatus, setTermStatus] = useState(false);
  const [termError, setTermError] = useState("");
  const [cardLoadIntent, setCardLoadIntent] = useState(null);

  const singlePdfSelected = selectedOption === "download_this_report";
  const pdfSubscriptionSelected =
    selectedOption === "download_unlimited_report";

  useEffect(() => {
    if (pricingData && pricingData.label_2) {
      setAmount(pricingData.label_2);
    }
  }, [pricingData]);

  const handleClose = () => {
    onClose();
    setTermError("");
    setTermStatus(false);
  };

  const handleChange = (e, amount) => {
    setSelectedOption(e.target.value);
    setAmount(amount);
  };

  const handleChangeTerm = (event) => {
    setTermStatus(event.target.checked);
    setTermError("");
  };

  const handlePayForPdf = async () => {
    if (!termStatus) {
      setTermError(t("PR_ACCEPT_TERM"));
      return;
    }
    setIsLoading(true);
    handleClose();
    try {
      const downloadPayload = {
        phone_report_id: phoneReport?._id,
        ip_address: userIp,
        type: "create",
        force3ds: is3Ds(), // For check 3ds scenarios
      };
      const downloadRes = singlePdfSelected
        ? await callDownloadSinglePdf(downloadPayload)
        : await callPdfSubscriptionCreate(downloadPayload);
      console.log('downloadRes', downloadRes)
      if (downloadRes.type.includes("fulfilled")) {
        if (downloadRes.payload.data.required_3ds) {
          const verifyUrl = downloadRes.payload.data.verify_url;
          window.location.href = verifyUrl;
        } else if (downloadRes?.payload?.status === false) {
          setCardLoadIntent(downloadRes?.payload?.data?.intent);
          setRetryModal(true);
        } else {
          if (singlePdfSelected) {
            const pdfBlob = await generatePdfBlob("main_root");
            const formData = new FormData();
            formData.append("pdfFile", pdfBlob, "report.pdf");
            formData.append("phone_report_id", phoneReport?._id);
            const uploadRes = await callUploadPdf(formData);
            if (uploadRes.type === "uploadPdf/fulfilled") {
              Notify("success", uploadRes.payload.message, "");
              const { url } = uploadRes.payload.data || {};
              if (url) {
                window.location.href = url;
              }
            } else {
              Notify("error", uploadRes.error.message, "");
            }
          } else {
            callGetPhoneReportDetails(phoneReport?._id);
            Notify("success", downloadRes.payload.message, "");
          }
        }
      } else {
        Notify("error", downloadRes.error.message, "");
      }
    } catch (error) {
      console.warn("error From handlePayForPdf function", error);
    } finally {
      setIsLoading(false);
    }
  };

  const onRetry = async (orderId) => {
    setIsLoading(true);
    setRetryModal(false);
    try {
      const downloadPayload = {
        phone_report_id: phoneReport?._id,
        ip_address: userIp,
        type: "transaction_decline",
        order_id: orderId
      };
      const downloadRes = singlePdfSelected
        ? await callDownloadSinglePdf(downloadPayload)
        : await callPdfSubscriptionCreate(downloadPayload);
      if (downloadRes.type.includes("fulfilled")) {
        if (singlePdfSelected) {
          const pdfBlob = await generatePdfBlob("main_root");
          const formData = new FormData();
          formData.append("pdfFile", pdfBlob, "report.pdf");
          formData.append("phone_report_id", phoneReport?._id);
          const uploadRes = await callUploadPdf(formData);
          if (uploadRes.type === "uploadPdf/fulfilled") {
            Notify("success", uploadRes.payload.message, "");
            const { url } = uploadRes.payload.data || {};
            if (url) {
              window.location.href = url;
            }
          } else {
            Notify("error", uploadRes.error.message, "");
          }
        } else {
          callGetPhoneReportDetails(phoneReport?._id);
          Notify("success", downloadRes.payload.message, "");
        }
      } else {
        Notify("error", downloadRes.error.message, "");
      }
    } catch (error) {
      console.warn("error From retry PDF download function", error);
    } finally {
      setIsLoading(false);
    }
  };

  const orderDetails = {
    orderName: t("PR_PDF_DOWNLOAD"),
    orderPrice: pricingData?.symbol + amount,
    orderType: singlePdfSelected ? "one_time_payment" : "subscription",
    orderTypeName: singlePdfSelected
      ? CONSTANTS.ONETIME.SINGLE_PDF
      : pdfSubscriptionSelected
        ? "pdf_download"
        : null
  };
  return (
    <>
      {isLoading && <UnlockAnimation />}
      {isOpen && (
        <ModalComponent isOpen={isOpen} onClose={handleClose} closable={false}>
          <div className="multiple_popup__wrap">
            <div className="wpb_report_popup fixed-size">
              <img src={downloadImage} alt="" height={100} width={100} />
              <div className="mlt_popup__text">
                <h2>{t("PR_DOWNLOAD_REPORT_2")}</h2>
                <p>
                  {pdfSubscriptionSelected
                    ? t("PR_DOWNLOAD_POPUP_DESCRIPTION_2")
                    : t("PR_DOWNLOAD_POPUP_DESCRIPTION")}
                </p>
              </div>
              <div className="mlt_popup__payment">
                <form action="/">
                  <label htmlFor="unlock_report" className="dsl_report">
                    <div className="dsl_report_field">
                      <input
                        type="radio"
                        id="unlock_report"
                        name="report_option"
                        value="download_this_report"
                        checked={selectedOption === "download_this_report"}
                        onChange={(e) =>
                          handleChange(
                            e,
                            isOpen && pricingData.label_1 && pricingData.label_1
                          )
                        }
                      />
                      <span className="report_radio_wrap">
                        {t("PR_DOWNLOAD_REPORT")}
                      </span>
                    </div>
                    <div className="dsl_report_payment">
                      <span>
                        {isOpen &&
                          pricingData.label_1 &&
                          pricingData.symbol + pricingData.label_1}
                      </span>
                    </div>
                  </label>
                  <label htmlFor="due_now" className="dsl_report">
                    <div className="dsl_report_field">
                      <input
                        type="radio"
                        id="due_now"
                        name="report_option"
                        value="download_unlimited_report"
                        checked={selectedOption === "download_unlimited_report"}
                        onChange={(e) =>
                          handleChange(
                            e,
                            isOpen && pricingData.label_2 && pricingData.label_2
                          )
                        }
                      />
                      <span className="report_radio_wrap">
                        {t("PR_DOWNLOAD_REPORT_SUB_TITLE")}
                      </span>
                    </div>
                    <div className="dsl_report_payment">
                      <span>
                        {isOpen &&
                          pricingData.label_2 &&
                          pricingData.symbol + pricingData.label_2}
                        {t("PR_UNLOCK_POPUP_TEXT_3")}
                      </span>
                      <small>{t("PR_UNLIMITED")}</small>
                    </div>
                  </label>
                </form>
              </div>
              <div className="mlt_popup__subscribe">
                <div className="policy__content_wrap">
                  <input
                    type="checkbox"
                    id="tracelo_Privacy"
                    name="Privacy Content"
                    onChange={handleChangeTerm}
                  />
                  <label
                    htmlFor="tracelo_Privacy"
                    dangerouslySetInnerHTML={{
                      __html: modifiedLink
                    }}
                  ></label>
                </div>
                <div className="error">
                  <p>{termError}</p>
                </div>
                <div className="report__popup_pay_btn">
                  <button type="submit" onClick={handlePayForPdf}>
                    {t("PR_PAY")} {pricingData.label_1 && pricingData.symbol}{" "}
                    {amount}
                  </button>
                  <p>
                    <span onClick={handleClose}>{t("PR_CANCEL")}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </ModalComponent>
      )}
      {retryModal && (
        <TransactionDeclinedModal
          isOpen={retryModal}
          onClose={() => setRetryModal(false)}
          closable={false}
          handleSubmit={onRetry}
          orderDetails={orderDetails}
          cardLoadIntent={cardLoadIntent}
          setCardLoadIntent={setCardLoadIntent}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    phoneReportDetails:
      state.getPhoneReportDetails?.getPhoneReportDetailsData?.data
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    callUploadPdf: (data) => dispatch(uploadPdf(data)),
    callGetPhoneReportDetails: (data) => dispatch(getPhoneReportDetails(data)),
    callDownloadSinglePdf: (data) => dispatch(downLoadSinglePdf(data)),
    callPdfSubscriptionCreate: (data) => dispatch(pdfSubscriptionCreate(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DownloadReportPayModal);
