import React, { useState, useEffect } from "react";
import { Modal, Spin } from "antd";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Cookies } from "react-cookie";
import parsePhoneNumber from "libphonenumber-js";
import { useDispatch } from "react-redux";

import HomePage from "../home";
import CheckoutComponent from "./module/checkout";
import Header from "../header";
import Notify from "../common/Notify/notify";
import useScrollToTop from "../customHook/useScrollToTop";
import { register } from "../../redux/slice/registerSlice";
import { getClassName, useProductPrices } from "./module/helpers";
import "./signUpSolidGate.scss";
import { solidGateIntentCreate } from "../../redux/slice/solidGate/solidGateIntentCreateSlice";
import RegisterPage from "./module/registerPage";
import TrackPage from "./module/trackPage";
import { isTrackPage } from "../../utils/helpers";
import { trackEvent } from "../../utils/eventTracker";
import { is3Ds } from "../../phoneReport/utils/commonUtils";

const SignUpSolidGateComponent = (props) => {
  const { data, setData } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchparams] = useSearchParams();
  const cookie = new Cookies();
  const lang = cookie.get("lang");
  const cur = cookie.get("currency");
  const params = cookie.get("param");
  const location = useLocation();
  const disPatch = useDispatch();
  const { trialProduct, subscriptionProduct, currencySymbol } =
    useProductPrices();
  const { phoneNumber: formateFullNumber } = location.state || {};
  const pageVersion = isTrackPage() ? "b" : "w";
  const searchNumber = Object.fromEntries([...searchparams]);

  const [isModalOpen, setIsModalOpen] = useState(true);
  const [step, setStep] = useState(1);
  const [formateNumber, setFormateNumber] = useState("0");
  const [isLoading, setIsLoading] = useState(false);
  const [sawAnimation, setSawAnimation] = useState(false);
  const [intentCreateData, setIntentCreateData] = useState({});
  const [error, setError] = useState({
    google: "",
    facebook: "",
    email: "",
  });
  const [merchantData, setMerchantData] = useState({
    merchant: "",
    paymentIntent: "",
    signature: "",
  });

  useEffect(() => {
    if (isTrackPage()) {
      setSawAnimation(true);
      setStep(0);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (searchNumber.n && searchNumber.D) {
      let fullNumber = "+" + searchNumber.D + searchNumber.n;
      const phoneNumber = parsePhoneNumber(fullNumber);
      const mobileNumber = formateFullNumber
        ? formateFullNumber
        : searchNumber.n;
      let formateNumbers =
        "+" + phoneNumber.countryCallingCode + " " + mobileNumber;
      setFormateNumber(formateNumbers);
    } else {
      navigate(`/${lang}`);
      setStep(1);
    }
  }, [searchNumber.n, searchNumber.D]);

  useEffect(() => {
    if (currencySymbol) {
      setData((prev) => ({
        ...prev,
        symbol: currencySymbol,
      }));
    }
  }, [currencySymbol]);

  useEffect(() => {
    document.body.classList.toggle("no-scroll", isModalOpen);
    return () => document.body.classList.remove("no-scroll");
  }, [isModalOpen]);

  const handleCancel = () => {
    setIsModalOpen(false);
    if (isTrackPage()) {
      const urlPrefix = `/${lang}/track`;
      if (params) {
        cookie.remove("param");
        navigate(
          `${urlPrefix}?${Object.entries(params)
            .map(([key, value]) => {
              return value ? `${key}=${value}` : `${key}`;
            })
            .join("&")}`
        );
      } else {
        navigate(`/${lang}/track`);
      }
    } else {
      navigate(`/${lang}/`);
    }
    setStep(1);
  };

  useScrollToTop();

  const handleRegister = async (registerData, type) => {
    setIsLoading(true);
    try {
      const result = await disPatch(register(registerData));
      if (result.type === "register/fulfilled") {
        const { user_id, email } = result.payload.data || {};
        trackEvent("sign_up_email", { user_id, email });
        trackEvent("zaraz_sign_up_email", { user_id, email });
        const intentData = {
          currency: cur?.substr(0, 3),
          amount: Number(trialProduct?.sent_amount || 0),
          email: email?.toLowerCase(),
          userId: user_id,
          force3ds: is3Ds(), 
          language: lang?.substr(0, 2)
        };
        const intentResult = await disPatch(solidGateIntentCreate(intentData));
        if (intentResult.type === "solidGateIntentCreate/fulfilled") {
          setIntentCreateData(intentData);
          const { paymentIntent, merchant, signature } =
            intentResult.payload.data || {};
          setMerchantData({
            merchant: merchant || "",
            paymentIntent: paymentIntent || "",
            signature: signature || "",
          });
          setStep(2);
        } else {
          Notify("error", intentResult.error.message || "Payment failed", "");
        }
      } else {
        setError((prev) => ({
          ...prev,
          [type === "google" ? "google" : "email"]: result.error.message,
        }));
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error during registration: ", error);
      Notify("error", "Something went wrong", "");
    } finally {
      setIsLoading(false);
    }
  };

  const handleRegisterNew = async (payload, registerType) => {
    await handleRegister(payload, registerType);
  };

  return (
    <>
      {isTrackPage() ? (
        <>
          <Header />
          <HomePage />
        </>
      ) : (
        <HomePage />
      )}

      <Modal
        centered
        open={isModalOpen}
        onCancel={handleCancel}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        maskClosable={false}
        closable={!isLoading && step !== 0}
        className={getClassName(step)}
      >
        <div className="login-modal-section">
          {step !== 0 && (
            <div className="modal_number_info">
              <div
                className="login-modal-title"
                style={{ flexDirection: "column" }}
              >
                {sawAnimation ? (
                  <p>{t("NUMBER_LOCATED")}</p>
                ) : (
                  <p>{t("PR_SEARCHING")}</p>
                )}
                <div className="number display-inputs-rtl">
                  <p>{searchNumber.D ? formateNumber : `00 00 000 0000`}</p>
                </div>
              </div>
            </div>
          )}
          <div className="wrap-top-modal">
            {step === 0 ? (
              <TrackPage
                sawAnimation={sawAnimation}
                formateNumber={formateNumber}
                setStep={setStep}
              />
            ) : step === 1 ? (
              <Spin spinning={isLoading}>
                <RegisterPage
                  error={error}
                  setError={setError}
                  isLoading={isLoading}
                  sawAnimation={sawAnimation}
                  setData={setData}
                  data={data}
                  handleRegister={handleRegisterNew}
                  pageVersion={pageVersion}
                />
              </Spin>
            ) : (
              step === 2 &&
              Object.values(merchantData).every((value) => value) && (
                <Spin spinning={isLoading}>
                  <CheckoutComponent
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    data={data}
                    trialProduct={trialProduct}
                    subscriptionProduct={subscriptionProduct}
                    senderNumberCountry={searchNumber.D}
                    senderNumber={searchNumber.n}
                    merchantData={merchantData}
                    intentCreateData={intentCreateData}
                    setMerchantData={setMerchantData}
                    oncloseCheckout={handleCancel}
                  />
                </Spin>
              )
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};
export default SignUpSolidGateComponent;
