import React from "react";
import { t } from "i18next";
import {
  accordionIcon,
  nameAncestryImg,
  nodataIcon,
} from "../../../assets/images";
import { Collapse } from "antd";
import UnlockReport from "../unlockReport/unlockReport";
import { renderValue, toCamelCase } from "../../../utils/commonUtils";
import SectionAction from "../sectionAction/sectionAction";
import NoInfoFound from "../noInfoFound/noInfoFound";
const NameAndAncestry = ({ isLocked, sectionName, reportDetail }) => {
  const { name_and_ancestry } = reportDetail?.phoneReport || {};
  const { data, status } = name_and_ancestry || {};
  const { phone_owner_info } = reportDetail?.phoneReport || {};
  const isData = phone_owner_info?.status === "found";

  return isLocked ? (
    <UnlockReport
      title={t("PR_PREMIUM_SECTION_TEXT")}
      image={nameAncestryImg}
      buttonText={t("PR_UNLOCK_THIS_SECTION").toUpperCase()}
      shouldKnowText={t("PR_UNLOCK_TITLE")}
      knownText={t("PR_ANCESTRY_MESSAGE")}
      sectionName={sectionName}
      pricingSectionName={"name_and_ancestry"}
      id={"Name & Ancestry"}
    />
  ) : !isLocked && status === "not_found" ? (
    <NoInfoFound
      sectionName={sectionName}
      id={toCamelCase("Name & Ancestry")}
    />
  ) : (
    <div
      className="ds--unlock-jobs white-bg-wrap"
      id={toCamelCase("Name & Ancestry")}
    >
      <div className="ds--unlock__title left-content main_title toggle_area">
        <h2>{t("PR_PREMIUM_SECTION_TEXT")}</h2>
        <SectionAction />
      </div>
      {status === "found" ? (
        <>
          <div className="ds--accordion-collapse">
            <Collapse
              items={[
                {
                  key: "1",
                  label: t("PR_LEARN_MORE"),
                  children: (
                    <p>
                      {t("PR_LEARN_MORE_DESCRIPTION_3", {
                        phone_number_owner_first_name: isData
                          ? renderValue(phone_owner_info.data.name)
                          : "USER",
                      })}
                    </p>
                  ),
                },
              ]}
              expandIconPosition="end"
              expandIcon={({ isActive }) => (
                <img
                  src={isActive ? accordionIcon : accordionIcon}
                  width="2em"
                  height="2em"
                  alt=""
                />
              )}
            />
          </div>
          <div className="ds--job-wrapper wpb__inner_rtl">
            <div className="ds--job-box email--underline">
              <h3>{renderValue(data.name)}</h3>
              <p>
                <b>{t("PR_LANGUAGE")}</b>
                {renderValue(data.language_spoken)}
              </p>
              <p>
                <b>{t("PR_MEANING")}</b>
                {renderValue(data.meaning)}
              </p>
              <p>
                <b>{t("PR_ORIGIN")}</b>
                {renderValue(data.origin)}
              </p>
              <p>
                <b>{t("PR_POSSIBLE_ANCESTRY")}</b>
                {renderValue(data.possible_ancestry)}
              </p>
            </div>
          </div>
        </>
      ) : (
        <p className="data_notfound">
          <img src={nodataIcon} alt="" /> {t("PR_RECORD_NOT_FOUND")}
        </p>
      )}
    </div>
  );
};

export default NameAndAncestry;
