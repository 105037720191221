import React, { useState, useEffect } from "react";
import HomePage from "../home";
import "./login.scss";
import { Form, Input, Modal, Spin } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Cookies } from "react-cookie";
import FacebookLogin from "react-facebook-login";
import { facebook_id } from "../../environment";
import useScrollToTop from "../customHook/useScrollToTop";
import { renderRTLClass } from "../../utils/commonUtils";
import AppleLogin from '../AppleLogin/applelogin'

const LoginComponent = (props) => {
  const {
    handleLogin,
    loginError,
    emailError,
    loginData,
    handleValuesChange,
    googleLogin,
    googleErrors,
    isLoading,
    responseFacebook,
    handleLoginSuccess
  } = props;
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const cookies = new Cookies();
  const lang = cookies.get("lang");
  useEffect(() => {
    document.body.classList.toggle("no-scroll", isModalOpen);
    return () => document.body.classList.remove("no-scroll");
  }, [isModalOpen]);
  const handleCancel = () => {
    setIsModalOpen(false);
    navigate(`/${lang}/`);
  };
  useScrollToTop();
  return (
    <div>
      <HomePage />
      <Modal
        centered
        open={isModalOpen}
        onCancel={handleCancel}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        maskClosable={false}
        className={`${renderRTLClass()} login__locating_wrap login-info-wrap close__btn_rtl_wrapper`}
      >
        <div className="login-modal-section">
          <div className="login-modal-title">{t("LOGIN")}</div>
          <Spin spinning={isLoading}>
            <div className="wrap-top-modal">
              <div className="google-button-login">
                <button
                  className="login-with-google-btn"
                  onClick={googleLogin}
                  style={{ marginBottom: 10 }}
                >
                  {t("CONTINUE_WITH_GOOGLE")}
                </button>
                <AppleLogin onSuccess={handleLoginSuccess}/>
                <FacebookLogin
                  appId={facebook_id}
                  fields="name, email"
                  scope="email"
                  callback={responseFacebook}
                  cssClass="login-with-google-btn login-with-facebook-btn"
                  textButton={t("CONTINUE_WITH_FACEBOOK")}
                  isMobile="false"
                  disableMobileRedirect={true}
                />
              </div>
              <div className="auth-divider login-auth-divider">
                <span className="line left"></span>
                <span className="or-text">{t("OR_LOGIN_WITH_EMAIL")}</span>
                <span className="line right"></span>
              </div>
              <Form
                form={form}
                onFinish={handleLogin}
                initialValues={{ email: "", password: "" }}
                onValuesChange={handleValuesChange}
              >
                <div className="body-modal">
                  <div className="modal-login-form">
                    <div className="mb">
                      <Form.Item
                        // label={t("LOGIN_EMAIL_LABEL")}
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: t("ENTER_EMAIL_RESET_PASSWORD"),
                          },
                        ]}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                      >
                        <Input
                          className={`${
                            emailError || loginError ? "errorFelid" : ""
                          } "payment-inputs"`}
                          name="email"
                          placeholder="hello@mail.com "
                          onChange={(e) => {
                            const trimmedValue = e.target.value.replace(
                              /\s+/g,
                              ""
                            ); // Remove spaces
                            form.setFieldsValue({ email: trimmedValue }); // Update the form value
                          }}
                        />
                      </Form.Item>
                      {emailError && (
                        <p style={{ margin: "0", color: "#ff4d4f" }}>
                          {emailError}
                        </p>
                      )}
                      {!emailError && loginError && (
                        <p
                          style={{
                            margin: "0",
                            color: "#ff4d4f",
                            marginTop: 10,
                          }}
                        >
                          {t(loginError)}
                        </p>
                      )}
                    </div>
                    <Form.Item
                      //label={t("LOGIN_PASSWORD_LABEL")}
                      name="password"
                      rules={[
                        { required: true, message: t("PASSWORD_IS_REQUIRED") },
                      ]}
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Input
                        type="password"
                        name="password"
                        placeholder="*****"
                      />
                    </Form.Item>
                  </div>
                  {!loginError && googleErrors && (
                    <p style={{ margin: "0", color: "#ff4d4f" }}>
                      {googleErrors}
                    </p>
                  )}
                  <div className="submit-btn-modal">
                    <button
                      onClick={() => form.submit()}
                      className="hl_cta_wrap"
                      disabled={loginData.isLoading}
                    >
                      {t("SENT_MESSAGE_CONTINUE")}
                    </button>
                  </div>
                  <div className="forget-pass-modal">
                    <Link to={`/${lang}/reset-pass`}>
                      <p>{t("RESET_PASSWORD")}?</p>
                    </Link>
                  </div>
                </div>
              </Form>
            </div>
          </Spin>
        </div>
      </Modal>
    </div>
  );
};

export default LoginComponent;
