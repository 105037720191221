import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUserId } from "../../../utils/commonUtils";
import AxiosInstance from "../../../../utils/axios";
import { CONSTANTS } from "../../../utils/constant";
// import { useUserData } from "../../../../utils/commonUtils";
import { trackEvent } from "../../../../utils/eventTracker";

export const premiumSubscriptionCreate = createAsyncThunk(
  "premiumSubscriptionCreate",
  async (data) => {
    try {
      // Check if Admin Login with User Then include userId in query string
      const userId = getUserId();
      const url = userId
        ? `/report_subscription/solidgate/${CONSTANTS.SUBSCRIPTIONS.PREMIUM_REPORT}/create?userId=${userId}`
        : `/report_subscription/solidgate/${CONSTANTS.SUBSCRIPTIONS.PREMIUM_REPORT}/create`;
      const response = await AxiosInstance.post(url, data);

      /*  event parameters start */
      const { order_id, amount, currency, email, user_id } =
        response?.data?.data?.orderInfo || {};

      const commonEvent = {
        transaction_id: order_id,
        value: amount,
        currency: currency?.toUpperCase(),
        email: email,
        user_id: user_id,
      };
      if (
        (!response.data.data.required_3ds &&
          response?.data?.status &&
          data.type === "create") ||
        data.type === "transaction_decline" ||
        data.type === "3ds_confirm"
      ) {
        trackEvent("premium_activation", commonEvent);
        trackEvent("zaraz_premium_activation", commonEvent);
      }
      /*  event parameters end */

      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const premiumSubscriptionCreateSlice = createSlice({
  name: "premiumSubscriptionCreate",
  initialState: {
    isLoading: false,
    premiumSubscription: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: {
    [premiumSubscriptionCreate.pending]: (state) => {
      state.isLoading = true;
    },
    [premiumSubscriptionCreate.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.premiumSubscription = action.payload;
      state.isError = false;
      state.errorMessage = "";
    },
    [premiumSubscriptionCreate.rejected]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.error.message;
    },
  },
});

export default premiumSubscriptionCreateSlice.reducer;
